import React, { useState,useEffect, useRef, createRef } from 'react';
import fire from '../../Configs/firebase'
import {Modal,Button,Form,InputGroup,Dropdown,DropdownButton,Card,Accordion} from 'react-bootstrap'
import { InputGroupAddon, Input, InputGroupText, CardHeader, CardTitle, CardText } from 'reactstrap';
import FileUpload from 'react-firebase-file-uploader';
import { mergeFile, mergeBase64 } from 'canvas-merge-images';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faTimes, faUndo, faCircle, faTrash, faEdit, faPrint } from "@fortawesome/free-solid-svg-icons";
import Backdrop from '../backdrop/backdrop'
import { database } from 'firebase';

function MyVerticallyCenteredModal(props) {
  const defaultPayment = props.orderPaymentMode=="Internal_Credit"?"internalsuperkredit":"Select Payment Mode";
  let fileName = Math.floor(1000 + Math.random() * 9000); //random 4 digit number
  const [getCashAmount,setCashAmount] = useState()
  const [getCurrentSave,setCurrentSave] = useState(false)
  const [getCurrentPaymentMethod,setCurrentPaymentMethod] = useState(defaultPayment)
  const [getJSON,setJSON] = useState({})
  const [getCard,setCard] = useState()
  const [getCardTransactionId,setCardTransactionId] = useState()
  const [getTotal,setTotal] = useState(0)
  const [getrazorPay,setrazorPay] = useState()
  const [getpaytm,setpaytm] = useState()
  const [getCheque1,setCheque1] = useState()
  const [getCheque1Date,setCheque1Date] = useState()
  const [getCheque1Number,setCheque1Number] = useState()

  //SuperKredit
  const [getSuperKreditCheque1,setSuperKreditCheque1] = useState()
  const [getSuperKreditCheque1Date,setSuperKreditCheque1Date] = useState()
  const [getSuperKreditCheque1Number,setSuperKreditCheque1Number] = useState()
  const [getFinaleapAmount,setFinaleapAmount] = useState()

  const [getRupifiAmount, setRupifiAmount] = useState()
  const [getPendingAmount,setPendingAmount] = useState()
  const [getPendingReason,setPendingReason] = useState()
  const [getSuperKreditAmount, setSuperKreditAmount] = useState()
  const [getInternalSuperKreditAmount, setInternalSuperKreditAmount] = useState()
  const [getAdjustmentAmount,setAdjustmentAmount] = useState()
  const [getAdjustmentReason,setAdjustmentReason] = useState()
  const [getAdjustmentOrderNoErrorMsg,setAdjustmentOrderNoErrorMsg] = useState("")
  const [getCheque2,setCheque2] = useState()
  const [getCheque2Date,setCheque2Date] = useState()
  const [getCheque2Number,setCheque2Number] = useState()
  const [getChequeValue,setChequeValue] = useState();

  const [getSuperKreditCheque2,setSuperKreditCheque2] = useState()
  const [getSuperKreditCheque2Date,setSuperKreditCheque2Date] = useState()
  const [getSuperKreditCheque2Number,setSuperKreditCheque2Number] = useState()
  const [getSuperKreditChequeValue,setSuperKreditChequeValue] = useState();

  //card
  const [getCard2,setCard2] = useState()
  const [getCard2TransactionId,setCard2TransactionId] = useState()
  const [getCard2File,setCard2File] = useState()
  const [getCardTotalAmt,setCardTotalAmt] = useState(0)
  const [cardValue,setCardValue] = useState()

  const currentSaveButton = useRef()
  const [isUploading, setIsUploading] = useState(false)
  const [progressFile, setProgressFile] = useState(0)
  const [newFile, setNewFile] = useState("")
  const [chequeFile, setChequeFile] =useState("")
  const [getFinalChequeValue,setFinalChequeValue] = useState();

  //SuperKredit
  const [superKreditCheque1File, setSuperKreditCheque1File] =useState("")
  const [superKreditCheque2File, setSuperKreditCheque2File] =useState("")
  const [getFinalSuperKreditChequeValue,setFinalSuperKreditChequeValue] = useState();

  const [intialValueUpdate,setIntialValueUpdate] = useState(true);
  const [multiplePayments,setMultiplePayments]=useState(false);
  const [getExistingPaymentsTotal,setExistingPaymentsTotal]=useState(0);
  const [adjustmentOrderNumber,setAdjustmentOrderNumber]=useState();
  const [getCurrentPaymentsTotal,setCurrentPaymentsTotal]=useState({
    'cash':0,
    'card':0,
    'card2':0,
    'cheque':0,
    'cheque2':0,
    'paytm':0,
    'razorpay':0,
    'rupifi':0,
    'payment_pending':0,
    'adjustments':0,
    'superkredit':0,
    'internalsuperkredit':0,
    'internalsuperkredit2':0,
    'finaleap':0
  });

  const [razorpayFile,setRazorpayFile]=useState("");
  const [paytmFile,setPaytmFile]=useState("");
  const [paytmFileList,setPaytmFileList]=useState([]);
  const [razorPayFileList,setRazorpayFileList]=useState([]);
  const [cardFile,setCardFile]=useState("");

  const [paymentHashmap,setPaymentHashmap]=useState({
    'cash':true,
    'rupifi':true,
    'card':true,
    'cheque':true,
    'paytm':true,
    'razorpay':true,
    'payment_pending':true,
    'adjustments':true,
    'superkredit':true,
    'finaleap':true,
    'internalsuperkredit':true
  });

  let deliveryDate=new Date(sessionStorage.getItem(`dateModified`))

  const cashError = useRef()
  const finaleapError = useRef()
  const rupifiError = useRef()
  const pendingPaymentError = useRef()
  const pendingReasonError = useRef()
  const superKreditError = useRef()
  const superKreditAmountError = useRef()
  const internalSuperKreditAmountError = useRef()
  const internalSuperKreditlabel = useRef()
  const adjustmentsError = useRef()
  const adjustmentsReasonError = useRef()
  const adjustmentOrderNumberError = useRef()

  const cardError1 = useRef()
  const cardError2 = useRef()
  const cardFileError = useRef()

  const card2Error1 = useRef()
  const card2Error2 = useRef()
  const card2Error3 = useRef()
  const card2FileError = useRef()
  const cardTotalAmtError = useRef()
  const card2label1 = useRef()
  const card2label2 = useRef()
  const card2label3 = useRef()
  const card2FileLabel = useRef()
  const cardFileLabel = useRef()
  const cardTotalAmtLabel = useRef()


  const paytmError = useRef()
  const paytmFileError = useRef()
  const paytmAmountError = useRef()

  const razorpayError = useRef()
  const razorpayFileError = useRef()
  const razorpayAmountError = useRef()

  const cheque1Error1 = useRef()
  const cheque1Error2 = useRef()
  const cheque1Error3 = useRef()
  const cheque1Error4 = useRef()
  const cheque1ImgError = useRef()

  const cheque2Error1 = useRef()
  const cheque2Error2 = useRef()
  const cheque2Error3 = useRef()
  const cheque2ImgRef = useRef()
  const cheque2ImgError = useRef()

  const superKreditCheque1Error1 = useRef()
  const superKreditCheque1Error2 = useRef()
  const superKreditCheque1Error3 = useRef()
  const superKreditCheque1Error4 = useRef()
  const superKreditCheque1ImgError = useRef()

  const superKreditCheque2Error1 = useRef()
  const superKreditCheque2Error2 = useRef()
  const superKreditCheque2Error3 = useRef()
  const superKreditCheque2ImgRef = useRef()
  const superKreditCheque2ImgError = useRef()

  const cashlabel = useRef()
  const finaleapLabel = useRef()
  const pendingPaymentlabel = useRef()
  const pendingPaymentReasonlabel = useRef()
  const superKreditlabel = useRef()
  const adjustmentslabel = useRef()
  const adjustmentsreasonlabel = useRef()
  const adjustmentordernumberlabel = useRef()
  const cardlabel1 = useRef()
  const cardlabel2 = useRef()
  const paytmlabel = useRef()
  const razorpaylabel = useRef()
  const rupifilabel = useRef()


  const cheque1label1 = useRef()
  const cheque1label2 = useRef()
  const cheque1label3 = useRef()
  const cheque1label4 = useRef()
  const cheque1ImgLabel = useRef()

  const cheque2label1 = useRef()
  const cheque2label2 = useRef()
  const cheque2label3 = useRef()
  const cheque2ImgLabel = useRef()

  const superKreditCheque1label1 = useRef()
  const superKreditCheque1label2 = useRef()
  const superKreditCheque1label3 = useRef()
  const superKreditCheque1label4 = useRef()
  const superKreditCheque1ImgLabel = useRef()

  const superKreditCheque2label1 = useRef()
  const superKreditCheque2label2 = useRef()
  const superKreditCheque2label3 = useRef()
  const superKreditCheque2ImgLabel = useRef()


  const [getDidSubmit,setDidSubmit] = useState(false)
  const [getBackDrop,setBackDrop] = useState(false)



  const [getPrevTotal,setPrevTotal] = useState(0);

  useEffect(()=>{
    let existingPaymentDets=props.paymentDetails;

    let paymentKeys=Object.keys(existingPaymentDets);
    let paymentModeKeysObj={};

    paymentKeys.forEach((key) => {
      if(key!="total_amount_paid"){
        let currPayment=existingPaymentDets[key];
        let currPaymentMode=existingPaymentDets[key]['payment_mode'];

        if("cheque2_amount" in currPayment){
          setCurrentPaymentMethod("cheque2");
        }else{
          setCurrentPaymentMethod(currPaymentMode);
        }

        Object.keys(currPayment).forEach((paymodekey)=>{
          if(paymodekey!="payment_mode"){
            if(currPaymentMode=="cash"){
                if(paymodekey=="cash_amount"){
                  setCashAmount(currPayment[paymodekey]);
                }
                paymentModeKeysObj['cash']=false;
            }else if(currPaymentMode=="rupifi"){
                if(paymodekey=="rupifi_amount"){
                  setRupifiAmount(currPayment[paymodekey]);
                }
                paymentModeKeysObj['rupifi']=false;
            }else if(currPaymentMode=="finaleap"){
                if(paymodekey=="finaleap_amount"){
                  setFinaleapAmount(currPayment[paymodekey]);
                }
                paymentModeKeysObj['finaleap']=false;
            }else if(currPaymentMode=="card"){
                if(paymodekey=="card_amount"){
                  setCard(currPayment[paymodekey]);
                }else if(paymodekey=="card_transaction_id"){
                  setCardTransactionId(currPayment[paymodekey]);
                }else if(paymodekey=="card_image_url"){
                  setCardFile(currPayment[paymodekey]);
                }else if(paymodekey=="card2_amount"){
                  setCard2(currPayment[paymodekey]);
                }else if(paymodekey=="card2_transaction_id"){
                  setCard2TransactionId(currPayment[paymodekey]);
                }else if(paymodekey=="card2_image_url"){
                  setCard2File(currPayment[paymodekey]);
                }else if(paymodekey=="total_amount_paid"){
                  setCardTotalAmt(currPayment[paymodekey]);
                }
                paymentModeKeysObj['card']=false;
            }else if(currPaymentMode=="cheque"){
                if(paymodekey=="cheque1_amount"){
                  setCheque1(currPayment[paymodekey]);
                }else if(paymodekey=="cheque1_number"){
                  setCheque1Number(currPayment[paymodekey]);
                }else if(paymodekey=="cheque1_date"){
                  setCheque1Date(currPayment[paymodekey]);
                }else if(paymodekey=="cheque_image"){
                  setNewFile(currPayment[paymodekey]);
                }else if(paymodekey=="cheque1_image_url"){
                  setNewFile(currPayment[paymodekey]);
                }else if(paymodekey=="cheque2_amount"){
                  setCheque2(currPayment[paymodekey]);
                }else if(paymodekey=="cheque2_number"){
                  setCheque2Number(currPayment[paymodekey]);
                }else if(paymodekey=="cheque2_date"){
                  setCheque2Date(currPayment[paymodekey]);
                }else if(paymodekey=="cheque2_image_url"){
                  setChequeFile(currPayment[paymodekey]);
                }else if(paymodekey=="total_amount_paid"){
                  setChequeValue(currPayment[paymodekey]);
                  setFinalChequeValue(currPayment[paymodekey]);
                }
                paymentModeKeysObj['cheque']=false;
            }else if(currPaymentMode=="paytm"){
                if(paymodekey=="paytm_amount"){
                  setpaytm(currPayment[paymodekey]);
                }else if(paymodekey=="paytm_image_url"){
                  setPaytmFile(currPayment[paymodekey]);
                }
                paymentModeKeysObj['paytm']=false;
            }else if(currPaymentMode=="razorpay"){
                if(paymodekey=="razorpay_amount"){
                  setrazorPay(currPayment[paymodekey]);
                }else if(paymodekey=="razorpay_image_url"){
                  setRazorpayFile(currPayment[paymodekey]);
                }
                paymentModeKeysObj['razorpay']=false;
            }else if(currPaymentMode=="payment_pending"){
                if(paymodekey=="pending_amount"){
                  setPendingAmount(currPayment[paymodekey]);
                }else if(paymodekey=="pending_reason"){
                  setPendingReason(currPayment[paymodekey]);
                }
                paymentModeKeysObj['payment_pending']=false;
            }else if(currPaymentMode=="adjustments"){
                if(paymodekey=="adjustment_amount"){
                  setAdjustmentAmount(currPayment[paymodekey]);
                }else if(paymodekey=="adjustment_reason"){
                  setAdjustmentReason(currPayment[paymodekey]);
                }else if(paymodekey=="adjustment_order_number"){
                  setAdjustmentOrderNumber(currPayment[paymodekey]);
                }
                paymentModeKeysObj['adjustments']=false;
            }else if(currPaymentMode=="superkredit"){
                if(paymodekey=="superkredit_amount"){
                  setSuperKreditAmount(currPayment[paymodekey]);
                }
                paymentModeKeysObj['superkredit']=false;
            }else if(currPaymentMode=="internalsuperkredit"){
                paymentModeKeysObj['internalsuperkredit']=false;
            }

          }
        });

      }
    })

    let existingPaymentsTotalAmount=existingPaymentDets['total_amount_paid'];

    delete existingPaymentDets['total_amount_paid'];
    setCurrentCounter(Object.keys(existingPaymentDets).length);
    setJSON(existingPaymentDets);
    setTotal(getTotal=>getTotal+Number(existingPaymentsTotalAmount));
    setExistingPaymentsTotal(Number(existingPaymentsTotalAmount));

    if(Object.keys(existingPaymentDets).length>0){
      setMultiplePayments(true);

      Object.keys(paymentHashmap).forEach((payment)=>{
        if(!(payment in paymentModeKeysObj)){
          paymentModeKeysObj[payment]=true;
        }
      });
      setPaymentHashmap(paymentModeKeysObj);
      setCurrentPaymentMethod(defaultPayment);
    }
  },[])

  const clearExistingPayments = () => {
    setCashAmount();
    setFinaleapAmount();
    setCurrentSave(false);

    setCurrentPaymentMethod(defaultPayment);

    setPaymentHashmap({
      'cash':true,
      'rupifi':true,
      'card':true,
      'cheque':true,
      'paytm':true,
      'razorpay':true,
      'payment_pending':true,
      'adjustments':true,
      'superkredit':true,
      'internalsuperkredit':true,
      'finaleap':true
    });
    setJSON({});
    setExistingPaymentsTotal(0);
    setCurrentPaymentsTotal({
      'cash':0,
      'rupifi':0,
      'card':0,
      'card2':0,
      'cheque':0,
      'cheque2':0,
      'paytm':0,
      'razorpay':0,
      'payment_pending':0,
      'adjustments':0,
      'superkredit':0,
      'internalsuperkredit':0,
      'internalsuperkredit2':0,
      'finaleap':0
    });
    setCard();
    setCardTransactionId();
    setCard2();
    setCard2TransactionId();
    setCard2File();
    setCardTotalAmt(0);
    setTotal(0);
    setrazorPay();
    setpaytm();
    setCheque1();
    setCheque1Date();
    setCheque1Number();
    setPendingAmount();
    setPendingReason();
    setSuperKreditAmount();
    setAdjustmentAmount();
    setAdjustmentReason();
    setAdjustmentOrderNumber();
    setAdjustmentOrderNoErrorMsg("");
    setCheque2();
    setCheque2Date();
    setChequeValue();
    setIsUploading(false);
    setProgressFile(0);
    setNewFile("");
    setChequeFile("");
    setRazorpayFile("");
    setPaytmFile("");
    setPaytmFileList([]);
    setRazorpayFileList([]);
    setCardFile("");
    setFinalChequeValue();
    setIntialValueUpdate(true);
    setMultiplePayments(false);
    setDidSubmit(false);
    setBackDrop(false);
    setPrevTotal(0);
    setStatus();
    setHistory([]);
    setCurrentCounter(0);
  }



  const renderAccordion = () => {
    let temp = [];
    let event_id = -1;
    // console.log(getHistory.length)
    if(Object.keys(getJSON).length==0 || !getJSON){
      return null;
    }

    let label_map = {
      order_number: 'Order Number',
      order_date: 'Order Date',
      total_amt: 'Total Amount',
      revised_amt: 'Revised Amount',
      status: 'Status',
      shop_name: 'Shop Name',
      address1: 'Address1',
      address2: 'Address2',
      area: 'Area',
      phone: 'Phone',
      retailer_id: 'Retailer ID',
      latitude: 'Latitude',
      longitude: 'Longitude',
      cash_amount: 'Cash Amount',
      finaleap_amount: 'Finaleap Amount',
      adjustment_amount: 'Adjustment Amount',
      adjustment_reason: 'Adjustment Reason',
      pending_amount: 'Pending Amount',
      pending_reason: 'Pending Reason',
      superkredit_amount: 'SuperKredit Amount',
      payment_mode: 'Payment Mode',
      total_amount_paid: 'Total Amount Paid',
      'reason': 'Reason',
      card_amount: 'Card Amount',
      card_transaction_id: 'Card Transaction ID',
      card_image_url: 'Card Image URL',
      cheque1_amount: 'Cheque1 Amount',
      cheque1_number: 'Cheque1 Number',
      cheque1_image_url: 'Cheque1 Image URL',
      cheque2_image_url: 'Cheque2 Image URL',
      paytm_image_url: 'Paytm Image URL',
      paytm_image: 'Paytm Image URL',
      razorpay_image_url: 'Razorpay Image URL',
      razor_image: 'Razorpay Image URL',
      cheque1_date: 'Cheque1 Date',
      cheque2_amount: 'Cheque2 Amount',
      cheque2_number: 'Cheque2 Number',
      cheque2_date: 'Cheque2 Date',
      cheque_image: 'Cheque Image URL',
      adjustment_order_number: 'Adjustment OrderNumber',
      card2_amount: 'Card2 Amount',
      card2_transaction_id: 'Card2 Transaction ID',
      card2_image_url: 'Card2 Image URL'
    };

    console.log(getJSON);

    Object.keys(getJSON).forEach((key)=>{
      event_id++;
      let currPaymentMode=getJSON[key]['payment_mode'];
      let ctemp_2=[];

      Object.keys(getJSON[key]).forEach((key2) => {
        console.log('payment key:');
        console.log(key2);
        if (key2.toLowerCase().indexOf('total') != -1)
          return

        console.log(key2.toLowerCase().indexOf('payment_mode') != -1);

        if (key2.toLowerCase().indexOf('payment_mode') != -1) {
          ctemp_2.push(
            <Form.Group>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[key2]}
                </InputGroupAddon>
                <Input value={getJSON[key][key2]} readOnly/>
              </InputGroup>
            </Form.Group>
          )


        }else if ((key2.toLowerCase().includes('image') || key2.toLowerCase().includes('cheque')) && (!key2.toLowerCase().includes('amount'))) {
          ctemp_2.push(
            <Form.Group>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[key2]}
                </InputGroupAddon>
                <Input value={getJSON[key][key2]} readOnly />
              </InputGroup>
            </Form.Group>
          )
        } else if(!key2.toLowerCase().includes('amount')){
          ctemp_2.push(
            <Form.Group>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[key2]}
                </InputGroupAddon>
                <Input value={getJSON[key][key2]} readOnly />
              </InputGroup>
            </Form.Group>
          )
        } else if(key2.toLowerCase().includes('amount') && key2.toLowerCase().includes('cheque')) {
          ctemp_2.push(
            <Form.Group>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  {label_map[key2]}
                </InputGroupAddon>
                <Input value={'₹ ' + getJSON[key][key2]} readOnly />
              </InputGroup>
            </Form.Group>
          )
        } else {
          ctemp_2.push(
            <Form.Group>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  Amount
                </InputGroupAddon>
                <Input value={'₹ ' + getJSON[key][key2]} readOnly />
              </InputGroup>
            </Form.Group>
          )
        }
      });

      console.log("event_id",event_id);
      temp.push(
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={event_id+1}>
              Payment-{event_id+1}
              <IconButton size="small" name={event_id} onClick={(e) => {
                e.preventDefault(); handlePartialPaymentDeletion(e.currentTarget.name);
              }} style={{ color: '#e0421b' }}>
                <FontAwesomeIcon icon={faTrashAlt} size="sm" /></IconButton>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={event_id+1}>
            <Card.Body>
              {ctemp_2}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )
    });

    return <Accordion>{temp}</Accordion>
  }


  function handlePartialPaymentDeletion(paymentId) {
    console.log(paymentId);
    console.log(getJSON);

    let existingPaymentJSON = getJSON;

    if(existingPaymentJSON[paymentId]){
      let existingPaymentMode = existingPaymentJSON[paymentId].payment_mode;
      console.log(existingPaymentMode);
      clearStateForPaymentMode(existingPaymentMode);


      let existingPaymentsTotalAmount = existingPaymentJSON[paymentId].total_amount_paid;
      delete existingPaymentJSON[paymentId];
      console.log(existingPaymentJSON);

      let newPaymentJSON = {};
      let counter=0;
      Object.keys(existingPaymentJSON).map((key,index)=>{
        let currPaymentObj = existingPaymentJSON[key];
        newPaymentJSON[counter] = currPaymentObj;
        counter = counter+1;
      })

      delete newPaymentJSON['total_amt_paid'];
      console.log(newPaymentJSON);
      setCurrentCounter(counter);
      setJSON(newPaymentJSON);
      setTotal(getTotal=>getTotal-Number(existingPaymentsTotalAmount));
      setExistingPaymentsTotal(getExistingPaymentsTotal=>getExistingPaymentsTotal-Number(existingPaymentsTotalAmount));
      setPaymentHashmap({...paymentHashmap,[existingPaymentMode]:true})
      setCurrentPaymentMethod(defaultPayment);


      if(counter>0){
        setMultiplePayments(true);  // to show existing payments
      }
    }
  }

  function clearStateForPaymentMode(currPaymentMode){
    if(currPaymentMode=="cash"){
        setCashAmount();
    }else if(currPaymentMode=="rupifi"){
        setRupifiAmount();
    }else if(currPaymentMode=="finaleap"){
        setFinaleapAmount();
    }else if(currPaymentMode=="card"){
        setCard();
        setCardTransactionId();
        setCardFile();
        setCard2();
        setCard2TransactionId();
        setCard2File();
        setCardTotalAmt(0);
    }else if(currPaymentMode=="cheque"){
        setCheque1();
        setCheque1Number();
        setCheque1Date();
        setNewFile();
        setCheque2();
        setCheque2Number();
        setCheque2Date();
        setChequeFile();
        setChequeValue();
        setFinalChequeValue();
    }else if(currPaymentMode=="paytm"){
        setpaytm();
        setPaytmFile("");
    }else if(currPaymentMode=="razorpay"){
        setrazorPay();
        setRazorpayFile("");
    }else if(currPaymentMode=="payment_pending"){
        setPendingAmount();
        setPendingReason();
    }else if(currPaymentMode=="adjustments"){
        setAdjustmentAmount();
        setAdjustmentReason();
        setAdjustmentOrderNumber();
    }else if(currPaymentMode=="superkredit"){
        setSuperKreditAmount();
    }
  }


function generateJSON(current,value){
  console.log("Current is "+current)
  console.log("Current Value is "+value)
  console.log("Previous total is "+getTotal)

  let temp_json = getJSON
  let current_counter = getCurrentCounter
  setPrevTotal(getTotal)
  if(current=='cash')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'cash',
      'cash_amount' : value,
      'total_amount_paid' : value
    }
    setTotal(getTotal=>getTotal+Number(value));
    setPaymentHashmap({...paymentHashmap,"cash":false});
  }

  if(current=='rupifi')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'rupifi',
      'rupifi_amount' : value[0],
      'total_amount_paid' : value[0]
    }
    setTotal(getTotal=>getTotal+Number(value));
    setPaymentHashmap({...paymentHashmap,"rupifi":false});
  }

  if(current=='finaleap')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'finaleap',
      'finaleap_amount' : value[0],
      'total_amount_paid' : value[0]
    }
    setTotal(getTotal=>getTotal+Number(value));
    setPaymentHashmap({...paymentHashmap,"finaleap":false});
  }

  if(current=="Credit"){
    temp_json[current_counter] = {
      'payment_mode' : 'SuperKredit',
      'superkredit_amount' : value,
      'total_amount_paid' : value
    }
    setTotal(getTotal=>getTotal+Number(value));
  }

  if(current=='payment_pending')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'payment_pending',
      'pending_amount' : value[0],
      'pending_reason' : value[1]?value[1]:"",
      'total_amount_paid' : value[0]
    }
    setTotal(getTotal=>getTotal+Number(value));
    setPaymentHashmap({...paymentHashmap,"payment_pending":false});
  }

  if(current=='superkredit')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'superkredit',
      'superkredit_amount' : value,
      'total_amount_paid' : value
    }
    setTotal(getTotal=>getTotal+Number(value));
    setPaymentHashmap({...paymentHashmap,"superkredit":false});
  }

  if(current=='adjustments')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'adjustments',
      'adjustment_amount' : value[0],
      'adjustment_reason' : value[1],
      'adjustment_order_number': value[2]?value[2]:"",
      'total_amount_paid' : value[0]
    }
    setTotal(getTotal=>getTotal+Number(value));
    setPaymentHashmap({...paymentHashmap,"adjustments":false});
  }

  if(current=='card')
  {
    temp_json[current_counter] = {
      'payment_mode' : 'card',
      'card_amount' : value[0],
      'card_transaction_id' : value[1],
      'card_image_url' : value[2],
      'total_amount_paid' : value[0]
    }
    setTotal(getTotal=>getTotal+Number(value[0]));
    setPaymentHashmap({...paymentHashmap,"card":false});
  }

  if(current=='card2'){
    temp_json[current_counter] = {
      'payment_mode' : 'card',
      'card_amount' : value[0],
      'card_transaction_id' : value[1],
      'card_image_url' : value[2],
      'card2_amount' : value[3],
      'card2_transaction_id' : value[4],
      'card2_image_url' : value[5],
      'total_amount_paid' : ""+value[6],
    }
    setTotal(getTotal=>getTotal+Number(value[0]));
    setPaymentHashmap({...paymentHashmap,"card":false});
  }

  if(current=='razorpay'){
    temp_json[current_counter] = {
      'payment_mode' : 'razorpay',
      'razorpay_amount' : value[0],
      'total_amount_paid' : value[0],
      'razorpay_image_url' : value[1]
    }
    setTotal(getTotal=>getTotal+Number(value[0]));
    setPaymentHashmap({...paymentHashmap,"razorpay":false});
  }

  if(current=='paytm'){
    temp_json[current_counter] = {
      'payment_mode' : 'paytm',
      'paytm_amount' : value[0],
      'total_amount_paid' : value[0],
      'paytm_image_url' : value[1]
    }
    setTotal(getTotal=>getTotal+Number(value[0]));
    setPaymentHashmap({...paymentHashmap,"paytm":false});
  }

  if(current=='cheque'){
    temp_json[current_counter] = {
      'payment_mode' : 'cheque',
      'cheque1_amount' : value[2],
      'total_amount_paid' : value[3],
      'cheque1_number' : value[0],
      'cheque1_date' : value[1],
      'cheque1_image_url' : value[4]
    }
    setTotal(getTotal=>getTotal+Number(value[3]));
    setPaymentHashmap({...paymentHashmap,"cheque":false});
  }

  if(current=='cheque2'){
    temp_json[current_counter] = {
      'payment_mode' : 'cheque',
      'cheque1_amount' : value[2],
      'cheque1_number' : value[0],
      'cheque1_date' : value[1],
      'cheque1_image_url' : value[7],
      'cheque2_amount' : value[5],
      'cheque2_number' : value[3],
      'cheque2_date' : value[4],
      'cheque2_image_url' : value[8],
      'total_amount_paid' : ""+value[9]
    }
    setTotal(getTotal=>getTotal+Number(getFinalChequeValue));
    setPaymentHashmap({...paymentHashmap,"cheque":false});
  }

  if(current=='internalsuperkredit'){
    temp_json[current_counter] = {
      'payment_mode' : 'internalsuperkredit',
      'cheque1_amount' : value[0],
      'total_amount_paid' : value[3]?(""+value[3]):"0",
      'cheque1_number' : value[1],
      'cheque1_date' : value[2],
      'cheque1_image_url' : value[4]
    }
    setTotal(getTotal=>getTotal+Number(getFinalSuperKreditChequeValue));
    setPaymentHashmap({...paymentHashmap,"internalsuperkredit":false});
  }

  if(current=='internalsuperkredit2'){
    temp_json[current_counter] = {
      'payment_mode' : 'internalsuperkredit',
      'cheque1_amount' : value[0],
      'cheque1_number' : value[1],
      'cheque1_date' : value[2],
      'cheque1_image_url' : value[6],
      'cheque2_amount' : value[3],
      'cheque2_number' : value[4],
      'cheque2_date' : value[5],
      'cheque2_image_url' : value[7],
      'total_amount_paid' : ""+value[8]
    }
    setTotal(getTotal=>getTotal+Number(getFinalSuperKreditChequeValue));

    setPaymentHashmap({...paymentHashmap,"internalsuperkredit2":false});
    setPaymentHashmap({...paymentHashmap,"internalsuperkredit":false});
  }

  console.log(temp_json);
  setCurrentCounter(getCurrentCounter=>getCurrentCounter+1)
  setJSON(temp_json)

}

useEffect(()=>{
  if(getDidSubmit==true && getTotal!=0)
    submit_ref.current.click()
},[getTotal])

useEffect(()=>{
  if(getCurrentSave)
    {
      console.log ("Current payment method::",{getCurrentPaymentMethod});
      let save_data = null
      if(getCurrentPaymentMethod=='cash')
      {
        save_data = [
          <Form>
          <Form.Group>
          <InputGroup>
              <InputGroupAddon addonType="prepend">
              Payment Method
              </InputGroupAddon>
              <Input value={getCurrentPaymentMethod} readOnly/>
              </InputGroup>
          </Form.Group>

          <Form.Group>
          <InputGroup>
              <InputGroupAddon addonType="prepend">
              Cash Amount
              </InputGroupAddon>
              <Input value={getCashAmount} readOnly/>
              </InputGroup>
          </Form.Group>
          </Form>
       ]

       generateJSON(getCurrentPaymentMethod,getCashAmount)
       if(intialValueUpdate==false){
         setCashAmount()
       }

    }

    if(props.orderPaymentMode=="Credit"){
      generateJSON(props.orderPaymentMode,props.totalSuperKreditAmt)
    }

    if(getCurrentPaymentMethod=='internalsuperkredit'){
      generateJSON(getCurrentPaymentMethod,[getSuperKreditCheque1,getSuperKreditCheque1Number,getSuperKreditCheque1Date,getFinalSuperKreditChequeValue,superKreditCheque1File])

      if(intialValueUpdate==false){
        setSuperKreditCheque1()
        setSuperKreditCheque1Date()
        setSuperKreditCheque1Number()
        setSuperKreditChequeValue()
        setFinalSuperKreditChequeValue()
        setSuperKreditCheque1File()
      }
    }

    if(getCurrentPaymentMethod=='rupifi'){
      generateJSON(getCurrentPaymentMethod,[getRupifiAmount])

      if(intialValueUpdate==false){
        setRupifiAmount()
      }
    }

    if(getCurrentPaymentMethod=='finaleap'){
      generateJSON(getCurrentPaymentMethod,[getFinaleapAmount])

      if(intialValueUpdate==false){
        setFinaleapAmount()
      }
    }

    if(getCurrentPaymentMethod=='internalsuperkredit2'){
      generateJSON(getCurrentPaymentMethod,[
        getSuperKreditCheque1,getSuperKreditCheque1Number,getSuperKreditCheque1Date,
        getSuperKreditCheque2,getSuperKreditCheque2Number,getSuperKreditCheque2Date,
        superKreditCheque1File,superKreditCheque2File,getFinalSuperKreditChequeValue
      ])

      if(intialValueUpdate==false){
        setSuperKreditCheque2()
        setSuperKreditCheque2Date()
        setSuperKreditCheque2Number()
        setSuperKreditChequeValue()
        setFinalSuperKreditChequeValue()
        setSuperKreditCheque1()
        setSuperKreditCheque1Date()
        setSuperKreditCheque1Number()
        setSuperKreditCheque1File()
        setSuperKreditCheque2File()
      }
    }

    if(getCurrentPaymentMethod=='payment_pending')
    {
      save_data = [
        <Form>
        <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
            Payment Method
            </InputGroupAddon>
            <Input value={getCurrentPaymentMethod} readOnly/>
            </InputGroup>
        </Form.Group>

        <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
            Pending Amount
            </InputGroupAddon>
            <Input value={getPendingAmount} readOnly/>
            </InputGroup>
        </Form.Group>

        {getPendingReason?(<Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
            Pending Reason
            </InputGroupAddon>
            <Input value={getPendingReason} readOnly/>
            </InputGroup>
        </Form.Group>):null}
        </Form>
     ]

     generateJSON(getCurrentPaymentMethod,[getPendingAmount,getPendingReason])
     if(intialValueUpdate==false){
       setPendingAmount()
       setPendingReason()
     }

  }

  if(getCurrentPaymentMethod=='superkredit')
  {
    save_data = [
      <Form>
      <Form.Group>
      <InputGroup>
          <InputGroupAddon addonType="prepend">
          Payment Method
          </InputGroupAddon>
          <Input value={getCurrentPaymentMethod} readOnly/>
          </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
          <InputGroupAddon addonType="prepend">
          SuperKredit Amount
          </InputGroupAddon>
          <Input value={getSuperKreditAmount} readOnly/>
          </InputGroup>
          <label style={{color:'red',display:'none'}} ref={superKreditAmountError}>Please enter amount!</label>
      </Form.Group>
      </Form>
   ]

   generateJSON(getCurrentPaymentMethod,getSuperKreditAmount)
   if(intialValueUpdate==false){
     setSuperKreditAmount()
   }

}

  if(getCurrentPaymentMethod=='adjustments')
  {
    save_data = [
      <Form>
      <Form.Group>
      <InputGroup>
          <InputGroupAddon addonType="prepend">
          Payment Method
          </InputGroupAddon>
          <Input value={getCurrentPaymentMethod} readOnly/>
          </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
          <InputGroupAddon addonType="prepend">
          Reason
          </InputGroupAddon>
          <Input value={getAdjustmentReason} readOnly/>
          </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
          <InputGroupAddon addonType="prepend">
          Adjustment Amount
          </InputGroupAddon>
          <Input value={getAdjustmentAmount} readOnly/>
          </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
            OrderNumber
            </InputGroupAddon>
            <Input value={adjustmentOrderNumber} readOnly/>
        </InputGroup>
      </Form.Group>
      </Form>
   ]

   generateJSON(getCurrentPaymentMethod,[getAdjustmentAmount,getAdjustmentReason,adjustmentOrderNumber])
   if(intialValueUpdate==false){
     setAdjustmentAmount()
     setAdjustmentReason()
     setAdjustmentOrderNumber()
     setAdjustmentOrderNoErrorMsg("")
   }
}

    if(getCurrentPaymentMethod=='card')
    {
      save_data = [
        <Form>
        <Form.Group>
        <InputGroup>
              <InputGroupAddon addonType="prepend">
                Payment Method
              </InputGroupAddon>
              <Input value={getCurrentPaymentMethod} readOnly/>
              </InputGroup>
        </Form.Group>

        <Form.Group>
        <InputGroup>
              <InputGroupAddon addonType="prepend">
                Card Amount
              </InputGroupAddon>
              <Input value={getCard} readOnly/>
              </InputGroup>
        </Form.Group>

        <Form.Group>
        <InputGroup>
              <InputGroupAddon addonType="prepend">
                Card Transaction ID
              </InputGroupAddon>
              <Input value={getCardTransactionId} readOnly/>
              </InputGroup>
        </Form.Group>
        <Form.Group>
          <InputGroup>
              <InputGroupAddon addonType="prepend">
                Card1 Image URL
              </InputGroupAddon>
              <Input value={cardFile} readOnly/>
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <InputGroup>
              <InputGroupAddon addonType="prepend">
                Card Effective Amount
              </InputGroupAddon>
              <Input value={cardValue} readOnly/>
          </InputGroup>
        </Form.Group>
        </Form>
     ]

     generateJSON(getCurrentPaymentMethod,[getCard,getCardTransactionId,cardFile])
     if(intialValueUpdate==false){
       setCard();
       setCardTransactionId();
       setCardFile("");
       setCardValue();
     }

  }

  if(getCurrentPaymentMethod=='card2')
  {
    save_data = [
      <Form>
      <Form.Group>
      <InputGroup>
            <InputGroupAddon addonType="prepend">
              Payment Method
            </InputGroupAddon>
            <Input value={getCurrentPaymentMethod} readOnly/>
            </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card Amount
            </InputGroupAddon>
            <Input value={getCard} readOnly/>
            </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card Transaction ID
            </InputGroupAddon>
            <Input value={getCardTransactionId} readOnly/>
            </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card1 Image URL
            </InputGroupAddon>
            <Input value={cardFile} readOnly/>
        </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card2 Amount
            </InputGroupAddon>
            <Input value={getCard2} readOnly/>
            </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card2 Transaction ID
            </InputGroupAddon>
            <Input value={getCard2TransactionId} readOnly/>
            </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card2 Image URL
            </InputGroupAddon>
            <Input value={getCard2File} readOnly/>
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
              Card Effective Amount
            </InputGroupAddon>
            <Input value={getCardTotalAmt} readOnly/>
        </InputGroup>
      </Form.Group>
     </Form>
   ]

   generateJSON(getCurrentPaymentMethod,[getCard,getCardTransactionId,cardFile,getCard2,getCard2TransactionId,getCard2File,getCardTotalAmt])
   if(intialValueUpdate==false){
     setCard();
     setCardTransactionId();
     setCardFile("");
     setCardValue();
     setCard2();
     setCard2TransactionId();
     setCard2File();
     setCardTotalAmt(0);
   }

 }


  if(getCurrentPaymentMethod=='razorpay')
      {
        console.log(deliveryDate)
        save_data = [
          <Form>
          <Form.Group>
          <InputGroup>
              <InputGroupAddon addonType="prepend">
                Payment Method
              </InputGroupAddon>
              <Input value={getCurrentPaymentMethod} readOnly/>
              </InputGroup>
          </Form.Group>

          <Form.Group>
          <InputGroup>
              <InputGroupAddon addonType="prepend">
                RazorPay Amount
              </InputGroupAddon>
              <Input value={getrazorPay} readOnly/>
              </InputGroup>
              <label style={{color:'red',display:'none'}} ref={razorpayAmountError}>Please enter amount!</label>

          </Form.Group>
          <Form.Group>
      <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                Upload RazorPay File</Form.Label><br/>
                <a href={sessionStorage.getItem(`RazorPayFile`)} target="_blank">{sessionStorage.getItem(`RazorPayFile`)}</a>
              <FileUpload
            required
            accept="image/*"
            name="avatar"

            storageRef={fire.storage().ref(`disha-payments/RazorPay_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
            onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true);console.log(deliveryDate)}}
            onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
            onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);setBackDrop(false);fire
              .storage()
              .ref(`disha-payments/RazorPay_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
              .child(fileName)
              .getDownloadURL()
              .then(url => {setRazorpayFile(url);sessionStorage.setItem(`RazorPayFile`,url);console.log(url)})}}
            onProgress={(progress)=>{setProgressFile(progress)}}
          />
          <label style={{color:'red',display:'none'}} ref={razorpayFileError}>Please enter a file!</label>
      </Form.Group>
          </Form>
      ]
      generateJSON(getCurrentPaymentMethod,[getrazorPay,razorpayFile])
      if(intialValueUpdate==false){
        setrazorPay();
        setRazorpayFile("");
        setRazorpayFileList([]);
      }

    }

    if(getCurrentPaymentMethod=='paytm')
    {
      save_data = [
        <Form>
        <Form.Group>
        <InputGroup>
              <InputGroupAddon addonType="prepend">
                Payment Amount
              </InputGroupAddon>
              <Input value={getCurrentPaymentMethod} readOnly/>
              </InputGroup>
        </Form.Group>

        <Form.Group>
        <InputGroup>
              <InputGroupAddon addonType="prepend">
                Paytm Amount
              </InputGroupAddon>
              <Input value={getpaytm} readOnly/>
              </InputGroup>
              <label style={{color:'red',display:'none'}} ref={paytmAmountError}>Please enter amount!</label>
        </Form.Group>
        <Form.Group>
      <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                Upload Paytm File</Form.Label><br/>
                <a href={sessionStorage.getItem(`PaytmUploadFile`)} target="_blank">{sessionStorage.getItem(`PaytmUploadFile`)}</a>
              <FileUpload
            required
            accept="image/*"
            name="avatar"

            storageRef={fire.storage().ref(`disha-payments/Paytm_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
            onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
            onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
            onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);setBackDrop(false);fire
              .storage()
              .ref(`disha-payments/Paytm_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
              .child(fileName)
              .getDownloadURL()
              .then(url => {setPaytmFile(url);sessionStorage.setItem(`PaytmUploadFile`,url);console.log(url)})}}
            onProgress={(progress)=>{setProgressFile(progress)}}
          />
          <label style={{color:'red',display:'none'}} ref={paytmFileError}>Please enter a file!</label>
      </Form.Group>
        </Form>
     ]

     generateJSON(getCurrentPaymentMethod,[getpaytm,paytmFile])
     if(intialValueUpdate==false){
       setpaytm();
       setPaytmFile("");
       setPaytmFileList([]);
     }
  }

  if(getCurrentPaymentMethod=='cheque')
  {
    save_data = [
      <Form>
      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Payment Amount
              </InputGroupAddon>
              <Input value={getCurrentPaymentMethod} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
               Cheque1 Amount
              </InputGroupAddon>
              <Input value={getCheque1} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Cheque1 Number
              </InputGroupAddon>
              <Input value={getCheque1Number}  readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Cheque1 Date
              </InputGroupAddon>
              <Input value={getCheque1Date} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
              Cheque1 Image URL
            </InputGroupAddon>
            <Input value={newFile} readOnly/>
        </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Effective Amount
              </InputGroupAddon>
              <Input value={getChequeValue} readOnly/>
      </InputGroup>
      </Form.Group>

      </Form>
   ]

   generateJSON(getCurrentPaymentMethod,[getCheque1Number,getCheque1Date,getCheque1,getChequeValue,newFile])

   if(intialValueUpdate==false){
     setCheque1()
     setCheque1Date()
     setCheque1Number()
     setChequeValue()
     setNewFile("")
     setFinalChequeValue()
   }

}


if(getCurrentPaymentMethod=='cheque2')
  {
    save_data = [
      <Form>
      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Payment Amount
              </InputGroupAddon>
              <Input value={getCurrentPaymentMethod} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
               Cheque1 Amount
              </InputGroupAddon>
              <Input value={getCheque1} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Cheque1 Number
              </InputGroupAddon>
              <Input value={getCheque1Number}  readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
              Cheque1 Image URL
            </InputGroupAddon>
            <Input value={newFile} readOnly/>
        </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Cheque1 Date
              </InputGroupAddon>
              <Input value={getCheque1Date} readOnly/>
              </InputGroup>
      </Form.Group>

      {/* division */}

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
               Cheque2 Amount
              </InputGroupAddon>
              <Input value={getCheque2} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Cheque2 Number
              </InputGroupAddon>
              <Input value={getCheque2Number}  readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Cheque2 Date
              </InputGroupAddon>
              <Input value={getCheque2Date} readOnly/>
              </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup>
            <InputGroupAddon addonType="prepend">
              Cheque2 Image URL
            </InputGroupAddon>
            <Input value={chequeFile} readOnly/>
        </InputGroup>
      </Form.Group>

      <Form.Group>
      <InputGroup>
              <InputGroupAddon addonType="prepend">
                Effective Amount
              </InputGroupAddon>
              <Input value={getFinalChequeValue} readOnly/>
      </InputGroup>
      </Form.Group>


      </Form>
   ]

   generateJSON(getCurrentPaymentMethod,[getCheque1Number,getCheque1Date,getCheque1,getCheque2Number,getCheque2Date,getCheque2,getChequeValue,newFile,chequeFile,getFinalChequeValue])

   if(intialValueUpdate==false){
     setCheque1()
     setCheque1Date()
     setCheque1Number()
     setCheque2()
     setCheque2Date()
     setCheque2Number()
     setChequeValue()
     setNewFile("")
     setChequeFile("")
     setFinalChequeValue()
   }

}



      setHistory(getHistory=>getHistory.concat([[save_data]]));
      setCurrentPaymentMethod(defaultPayment)
      setCurrentSave(false)
    }
},[getCurrentSave])


const handleFormSubmit = (event)=>{
  const form = event.currentTarget;
  console.log(form);

    if (form.checkValidity() === false && getCurrentPaymentMethod!='adjustments') {
      setDidSubmit(false)
      event.preventDefault();
      event.stopPropagation();

      if(cashError.current)
      {
        cashError.current.style.display = "block"
      }

      if(finaleapError.current)
      {
        finaleapError.current.style.display = "block"
      }

      if(pendingPaymentlabel.current){
        if(pendingPaymentlabel.current.value===""){
          pendingPaymentError.current.style.display = "block"
        }

        if(pendingPaymentlabel.current.value!==""){
          pendingPaymentError.current.style.display = "none"
        }
      }

      if(pendingPaymentReasonlabel.current){
        if(pendingPaymentReasonlabel.current.value===""){
          pendingReasonError.current.style.display = "block"
        }

        if(pendingPaymentReasonlabel.current.value!==""){
          pendingReasonError.current.style.display = "none"
        }
      }

      if(rupifiError.current) {
        rupifiError.current.style.display = "block"
      }

      //getCurrentPaymentMethod is coming as undefined
      if(paytmlabel.current){
        if(paytmlabel.current.value===""){
          paytmAmountError.current.style.display = "block"
        }

        if(paytmlabel.current.value!==""){
          paytmAmountError.current.style.display = "none"
        }
      }

      if(paytmFileError.current)
      {
        paytmFileError.current.style.display = "block"
      }

      if(superKreditAmountError.current){
        superKreditAmountError.current.style.display = "block"
      }

      if(razorpaylabel.current){
        if(razorpaylabel.current.value==""){
          razorpayAmountError.current.style.display = "block"
        }

        if(razorpaylabel.current.value!==""){
          razorpayAmountError.current.style.display = "none"
        }
      }

      if(razorpayFileError.current)
      {
        razorpayFileError.current.style.display = "block"
      }

      if(cardFileError.current){
        cardFileError.current.style.display = "block"
      }

      if(cheque1Error1.current){
        if(!cheque1label1.current.value)
          cheque1Error1.current.style.display = "block"
        else
          cheque1Error1.current.style.display = "none"

          if(!cheque1label2.current.value)
          cheque1Error2.current.style.display = "block"
        else
          cheque1Error2.current.style.display = "none"

          if(!cheque1label3.current.value)
          cheque1Error3.current.style.display = "block"
        else
          cheque1Error3.current.style.display = "none"

          if(!cheque1label4.current.value)
          cheque1Error4.current.style.display = "block"
        else
          cheque1Error4.current.style.display = "none"

          //block

        if(cheque2Error1.current){
          if(!cheque2label1.current.value)
          cheque2Error1.current.style.display = "block"
        else
          cheque2Error1.current.style.display = "none"

          if(!cheque2label2.current.value)
          cheque2Error2.current.style.display = "block"
        else
          cheque2Error2.current.style.display = "none"

          if(!cheque2label3.current.value)
          cheque2Error3.current.style.display = "block"
        else
          cheque2Error3.current.style.display = "none"

          if(!cheque2ImgLabel.current.value)
            cheque2ImgError.current.style.display = "block"
          else
            cheque2ImgError.current.style.display = "none"

          if(!cheque1ImgLabel.current.value)
            cheque1ImgError.current.style.display = "block"
          else
            cheque1ImgError.current.style.display = "none"
        }

      }


      //internal superkredit validations
      if(superKreditCheque1Error1.current){
        if(!superKreditCheque1label1.current.value)
          superKreditCheque1Error1.current.style.display = "block"
        else
          superKreditCheque1Error1.current.style.display = "none"

          if(!superKreditCheque1label2.current.value)
          superKreditCheque1Error2.current.style.display = "block"
        else
          superKreditCheque1Error2.current.style.display = "none"

          if(!superKreditCheque1label3.current.value)
          superKreditCheque1Error3.current.style.display = "block"
        else
          superKreditCheque1Error3.current.style.display = "none"

          if(!superKreditCheque1label4.current.value)
          superKreditCheque1Error4.current.style.display = "block"
        else
          superKreditCheque1Error4.current.style.display = "none"

          if(!superKreditCheque1ImgLabel.current.value)
            superKreditCheque1ImgError.current.style.display = "block"
          else
            superKreditCheque1ImgError.current.style.display = "none"

          //block

        if(superKreditCheque2Error1.current){

          if(!superKreditCheque2label1.current.value)
          superKreditCheque2Error1.current.style.display = "block"
        else
          superKreditCheque2Error1.current.style.display = "none"

          if(!superKreditCheque2label2.current.value)
          superKreditCheque2Error2.current.style.display = "block"
        else
          superKreditCheque2Error2.current.style.display = "none"

          if(!superKreditCheque2label3.current.value)
          superKreditCheque2Error3.current.style.display = "block"
        else
          superKreditCheque2Error3.current.style.display = "none"

          if(!superKreditCheque2ImgLabel.current.value)
            superKreditCheque2ImgError.current.style.display = "block"
          else
            superKreditCheque2ImgError.current.style.display = "none"

          if(!superKreditCheque1ImgLabel.current.value)
            superKreditCheque1ImgError.current.style.display = "block"
          else
            superKreditCheque1ImgError.current.style.display = "none"
        }

      }else{
        if(superKreditCheque2Error1.current){

          if(!superKreditCheque2label1.current.value)
          superKreditCheque2Error1.current.style.display = "block"
        else
          superKreditCheque2Error1.current.style.display = "none"

          if(!superKreditCheque2label2.current.value)
          superKreditCheque2Error2.current.style.display = "block"
        else
          superKreditCheque2Error2.current.style.display = "none"

          if(!superKreditCheque2label3.current.value)
          superKreditCheque2Error3.current.style.display = "block"
        else
          superKreditCheque2Error3.current.style.display = "none"

          if(!superKreditCheque2ImgLabel.current.value)
            superKreditCheque2ImgError.current.style.display = "block"
          else
            superKreditCheque2ImgError.current.style.display = "none"

          if(!superKreditCheque1ImgLabel.current.value)
            superKreditCheque1ImgError.current.style.display = "block"
          else
            superKreditCheque1ImgError.current.style.display = "none"
        }
      }



      if(cardError1.current)
      {
        if(!cardlabel1.current.value)
          cardError1.current.style.display = "block"
        else
        cardError1.current.style.display = "none"

        if(!cardlabel2.current.value)
          cardError2.current.style.display = "block"
        else
          cardError2.current.style.display = "none"

        if(!cardFileLabel.current.value)
          cardFileError.current.style.display = "block"
        else
          cardFileError.current.style.display = "none"


        if(card2Error1.current){
          if(!card2label1.current.value)
            card2Error1.current.style.display = "block"
          else
            card2Error1.current.style.display = "none"

          if(!card2label2.current.value)
            card2Error2.current.style.display = "block"
          else
            card2Error2.current.style.display = "none"

          if(!card2label3.current.value)
            card2Error3.current.style.display = "block"
          else
            card2Error3.current.style.display = "none"

          if(!card2FileLabel.current.value)
            card2FileError.current.style.display = "block"
          else
            card2FileError.current.style.display = "none"

        }
      }

      return
    }else if(getCurrentPaymentMethod=='adjustments'){
      //validate
      let validationPassed=false;

      if(adjustmentslabel.current){
        if(adjustmentslabel.current.value===""){
          adjustmentsError.current.style.display = "block"
        }

        if(adjustmentslabel.current.value!==""){
          adjustmentsError.current.style.display = "none"
        }
      }

      if(adjustmentsreasonlabel.current){
        if(adjustmentsreasonlabel.current.value===""){
          adjustmentsReasonError.current.style.display = "block"
        }

        if(adjustmentsreasonlabel.current.value!==""){
          adjustmentsReasonError.current.style.display = "none"
        }
      }

      let orderID=props.order_id;
      let retailerID=orderID.split("-")[0];
      retailerID=retailerID.replace(/\D/g, "");
      if(adjustmentordernumberlabel.current){
        let adjustmentAmount=adjustmentslabel.current.value?Number(adjustmentslabel.current.value):0;
        if(adjustmentAmount>100){
          let adjustmentorderno=adjustmentordernumberlabel.current.value;
          if(adjustmentorderno){

            if(adjustmentorderno.includes("R")){
              let returnOrderRetailerID=adjustmentorderno.split("-")[0];
              if(returnOrderRetailerID==retailerID){
                adjustmentOrderNumberError.current.style.display = "none"
                validationPassed=true;
              }else{
                setAdjustmentOrderNoErrorMsg("Retailer is different")
                adjustmentOrderNumberError.current.style.display = "block"
              }
            }else{
              setAdjustmentOrderNoErrorMsg("Only return orders are allowed")
              adjustmentOrderNumberError.current.style.display = "block"
            }
          }else{
            setAdjustmentOrderNoErrorMsg("Please enter adjustment order number")
            adjustmentOrderNumberError.current.style.display = "block"
          }
        }else{
          if(adjustmentslabel.current.value!=""){
            adjustmentOrderNumberError.current.style.display = "none"
            validationPassed=true;
          }else{
            setAdjustmentOrderNoErrorMsg("Please enter adjustment order number")
            adjustmentOrderNumberError.current.style.display = "block"
          }

        }

      }

      if(validationPassed){
        event.preventDefault();
        event.stopPropagation();
        setCurrentSave(true)
      }else{
        setDidSubmit(false)
        event.preventDefault();
        event.stopPropagation();
        return
      }

    }else if(superKreditCheque2Error1.current){
      let validationPassed=true;

      if(!superKreditCheque2label1.current.value){
        validationPassed=false;
        superKreditCheque2Error1.current.style.display = "block"
      }else{
        superKreditCheque2Error1.current.style.display = "none"
      }


      if(!superKreditCheque2label2.current.value){
        validationPassed=false;
        superKreditCheque2Error2.current.style.display = "block"
      }else{
        superKreditCheque2Error2.current.style.display = "none"
      }

      if(!superKreditCheque2label3.current.value){
        validationPassed=false;
        superKreditCheque2Error3.current.style.display = "block"
      }else{
        superKreditCheque2Error3.current.style.display = "none"
      }

      if(!superKreditCheque2ImgLabel.current.value && !superKreditCheque2File){
        validationPassed=false;
        superKreditCheque2ImgError.current.style.display = "block"
      }else{
        superKreditCheque2ImgError.current.style.display = "none"
      }

      if(!superKreditCheque1ImgLabel.current.value && !superKreditCheque1File){
        validationPassed=false;
        superKreditCheque1ImgError.current.style.display = "block"
      }else{
        superKreditCheque1ImgError.current.style.display = "none"
      }

      if(validationPassed){
        event.preventDefault();
        event.stopPropagation();
        setCurrentSave(true)
        return
      }else{
        setDidSubmit(false)
        event.preventDefault();
        event.stopPropagation();
        return
      }

    }


    event.preventDefault();
    event.stopPropagation();

    //check if submit was called

  setCurrentSave(true)
}


  function renderCash(){

    return ([<Form  noValidate  onSubmit={handleFormSubmit}><Form.Group>
      <Form.Label>Cash Amount</Form.Label>
      <Form.Control type="number" value={getCashAmount} onChange={(e)=>{setCashAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"cash":(Number(e.target.value))});}} required ref={cashlabel}/>
      <label style={{color:'red',display:'none'}} ref={cashError}>Please enter an amount!</label>

      </Form.Group>
      <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
      ])
  }

  const renderFinaleap = () => {

    return ([<Form  noValidate  onSubmit={handleFormSubmit}><Form.Group>
      <Form.Label>Finaleap Credits</Form.Label>
      <Form.Control type="number" value={getFinaleapAmount} onChange={(e)=>{setFinaleapAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"finaleap":(Number(e.target.value))});}} required ref={finaleapLabel}/>
      <label style={{color:'red',display:'none'}} ref={finaleapError}>Please enter an amount!</label>

      </Form.Group>
      <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
      ])
  }

  const renderPendingPayment = () => {
    return ([<Form  noValidate  onSubmit={handleFormSubmit}><Form.Group>
      <Form.Label>Pending Payment</Form.Label>
      <Form.Control type="number" value={getPendingAmount} onChange={(e)=>{setPendingAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"payment_pending":(Number(e.target.value))});}} required ref={pendingPaymentlabel}/>
      <label style={{color:'red',display:'none'}} ref={pendingPaymentError}>Please enter an amount!</label>

      </Form.Group>

      <Form.Group>
        <Form.Label>Reason</Form.Label>
        <Form.Control type="text" value={getPendingReason} onChange={(e)=>{setPendingReason(e.target.value)}} required ref={pendingPaymentReasonlabel} />
        <label style={{color:'red',display:'none'}} ref={pendingReasonError}>Please enter pending amount reason!</label>
      </Form.Group>

      <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
      ])
  }

  const renderSuperKredit = () => {
    return ([<Form  noValidate  onSubmit={handleFormSubmit}><Form.Group>
      <Form.Label>SuperKredit Payment</Form.Label>
      <Form.Control type="number" value={getSuperKreditAmount} onChange={(e)=>{setSuperKreditAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"superkredit":(Number(e.target.value))});}} required ref={superKreditlabel}/>
      <label style={{color:'red',display:'none'}} ref={superKreditAmountError}>Please enter an amount!</label>

      </Form.Group>
      <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
      ])
  }

  const renderNewSuperKredit = () => {
    return ([<Form  noValidate  onSubmit={handleFormSubmit}>
          <Form.Group>
            <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
          </Form.Group>
        </Form>
      ])
  }

  const renderRupifi = () => {
    return ([<Form  noValidate  onSubmit={handleFormSubmit}><Form.Group>
      <Form.Label>Rupifi Amount</Form.Label>
      <Form.Control type="number" value={getRupifiAmount} onChange={(e)=>{setRupifiAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"rupifi":(Number(e.target.value))});}} required ref={rupifilabel}/>
        <label style={{color:'red',display:'none'}} ref={rupifiError}>Please enter an amount!</label>
      </Form.Group>
      <Form.Group>
        <Button style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
    ])
  }

  const updateSuperkreditEffectiveAmount = (sk2Amt) => {
    let finalSuperKreditChequeAmt=Number(getSuperKreditCheque1)+Number(sk2Amt);

    setFinalSuperKreditChequeValue(finalSuperKreditChequeAmt);
  }

  const updateSuperkredit1EffectiveAmt = (sk1Amt) => {
    let finalSuperKreditChequeAmt=Number(sk1Amt);

    if(getSuperKreditCheque2){
      finalSuperKreditChequeAmt=finalSuperKreditChequeAmt+Number(getSuperKreditCheque2);
    }
    setFinalSuperKreditChequeValue(finalSuperKreditChequeAmt);
  }

  const renderInternalSuperKredit2 = (value) => {
    return(
      [
        <Form noValidate onSubmit={handleFormSubmit}>
          <Form.Group>
            <Form.Label>Cheque2 Amount</Form.Label>
            <Form.Control type="number"  value={getSuperKreditCheque2} onChange={(e)=>{setSuperKreditCheque2(e.target.value); updateSuperkreditEffectiveAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"internalsuperkredit2":(Number(e.target.value))}); }} required ref={superKreditCheque2label1}/>
            <label style={{color:'red',display:'none'}} ref={superKreditCheque2Error1}>Please enter an amount!</label>
          </Form.Group>

          <Form.Group>
            <Form.Label>Cheque2 Date</Form.Label>
            <Form.Control type="date" value={getSuperKreditCheque2Date} onChange={(e)=>{setSuperKreditCheque2Date(e.target.value)}} required ref={superKreditCheque2label2}/>
            <label style={{color:'red',display:'none'}} ref={superKreditCheque2Error2}>Please enter a Date!</label>
          </Form.Group>

          <Form.Group>
            <Form.Label>Cheque2 Number</Form.Label>
            <Form.Control type="text" value={getSuperKreditCheque2Number} onChange={(e)=>{setSuperKreditCheque2Number(e.target.value)}} required ref={superKreditCheque2label3}/>
            <label style={{color:'red',display:'none'}} ref={superKreditCheque2Error3}>Please enter a cheque Number!</label>
          </Form.Group>

          <Form.Group>
            <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                  Upload Cheque2</Form.Label><br/>
                  <FileUpload
                    accept="image/*"
                    ref={superKreditCheque2ImgLabel}
                    name="avatar"

                    storageRef={fire.storage().ref(`disha-payments/SuperKredit_Cheque2_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
                    onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
                    onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
                    onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);fire
                      .storage()
                      .ref(`disha-payments/SuperKredit_Cheque2_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
                      .child(fileName)
                      .getDownloadURL()
                      .then(url => {setSuperKreditCheque2File(url);setBackDrop(false);console.log(url);superKreditCheque2ImgRef.current.style.display="block";})}}
                    onProgress={(progress)=>{setProgressFile(progress)}}
                  />
              <label style={{color:'red',display:'none'}} ref={superKreditCheque2ImgError}>Please upload cheque2 image!</label>
          </Form.Group>

          {superKreditCheque2File?(<Form.Group ref={superKreditCheque2ImgRef}>
            <Form.Label>Cheque2 Image URL</Form.Label>
            <Form.Control type="input" value={superKreditCheque2File} readOnly/>
          </Form.Group>):null}

          <Form.Group>
            <Form.Label>Effective Amount </Form.Label>
            <Form.Control type="input" onChange={(e)=>{setFinalSuperKreditChequeValue(e.target.value)}} value={getFinalSuperKreditChequeValue} required ref={superKreditCheque1label4}/>
            <label style={{color:'red',display:'none'}} ref={superKreditCheque1Error4}>Please enter effective amount for this order!</label>
          </Form.Group>

          <Form.Group>
            <Button variant="primary" onClick={(e)=>{e.preventDefault();setSuperKreditCheque2();setSuperKreditCheque2File();setSuperKreditCheque2Date();setSuperKreditCheque2Number(); setFinalSuperKreditChequeValue(getSuperKreditCheque1);;setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"internalsuperkredit2":(0)}); setCurrentPaymentMethod('internalsuperkredit')}}>Remove cheque2</Button>
          </Form.Group>

          <Form.Group>
            <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
          </Form.Group>
    </Form>
      ]
    )
  }

  const renderInternalSuperKredit = (value)=>{

    return(
    [<Form noValidate onSubmit={handleFormSubmit}>
      <Form.Group>
        <Form.Label>Cheque1 Amount</Form.Label>
        <Form.Control type="number"  value={getSuperKreditCheque1} onChange={(e)=>{setSuperKreditCheque1(e.target.value); setSuperKreditChequeValue(e.target.value); updateSuperkredit1EffectiveAmt(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"internalsuperkredit":(Number(e.target.value))});
          }} required ref={superKreditCheque1label1}/>
        <label style={{color:'red',display:'none'}} ref={superKreditCheque1Error1}>Please enter an amount!</label>
      </Form.Group>

      <Form.Group>
        <Form.Label>Cheque1 Date</Form.Label>
        <Form.Control type="date" value={getSuperKreditCheque1Date} onChange={(e)=>{setSuperKreditCheque1Date(e.target.value)}} required ref={superKreditCheque1label2}/>
        <label style={{color:'red',display:'none'}} ref={superKreditCheque1Error2}>Please enter a Date!</label>
      </Form.Group>

      <Form.Group>
        <Form.Label>Cheque1 Number</Form.Label>
        <Form.Control type="text" value={getSuperKreditCheque1Number} onChange={(e)=>{setSuperKreditCheque1Number(e.target.value)}} required ref={superKreditCheque1label3}/>
        <label style={{color:'red',display:'none'}} ref={superKreditCheque1Error3}>Please enter a cheque Number!</label>
      </Form.Group>

      <Form.Group>
        <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
            Upload Cheque1</Form.Label><br/>
            <FileUpload
              required
              accept="image/*"
              ref={superKreditCheque1ImgLabel}
              name="avatar"

              storageRef={fire.storage().ref(`disha-payments/SuperKredit_Cheque1_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
              onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
              onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
              onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);fire
                .storage()
                .ref(`disha-payments/SuperKredit_Cheque1_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
                .child(fileName)
                .getDownloadURL()
                .then(url => {setSuperKreditCheque1File(url);setBackDrop(false);console.log(url)})}}
              onProgress={(progress)=>{setProgressFile(progress)}}
            />
        <label style={{color:'red',display:'none'}} ref={superKreditCheque1ImgError}>Please upload cheque1 image!</label>
      </Form.Group>

      {superKreditCheque1File?(<Form.Group>
        <Form.Label>Cheque1 Image URL</Form.Label>
        <Form.Control type="text" value={superKreditCheque1File} readOnly/>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='internalsuperkredit'?(<Form.Group>
        <Form.Label>Effective Amount </Form.Label>
        <Form.Control type="input" onChange={(e)=>{setSuperKreditChequeValue(e.target.value)}} value={getSuperKreditChequeValue}  required ref={superKreditCheque1label4}/>
        <label style={{color:'red',display:'none'}} ref={superKreditCheque1Error4}>Please enter effective amount for this order!</label>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='internalsuperkredit'?(<Form.Group>
        <Button variant="primary" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('internalsuperkredit2')}}>Add Cheque</Button>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='internalsuperkredit'?(<Form.Group>
        <Button style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group>):null}
      </Form>])
  }

  const renderAdjustment = () => {
    return ([<Form  noValidate  onSubmit={handleFormSubmit}><Form.Group>
      <Form.Label>Adjustments Amount</Form.Label>
      <Form.Control type="number" value={getAdjustmentAmount} onChange={(e)=>{setAdjustmentAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"adjustments":(Number(e.target.value))});}} required ref={adjustmentslabel}/>
      <label style={{color:'red',display:'none'}} ref={adjustmentsError}>Please enter an amount!</label>

      </Form.Group>

      <Form.Group>
        <Form.Label>Reason</Form.Label>
        <Form.Control type="text" value={getAdjustmentReason} onChange={(e)=>{setAdjustmentReason(e.target.value)}} required ref={adjustmentsreasonlabel}/>
        <label style={{color:'red',display:'none'}} ref={adjustmentsReasonError}>Please enter adjustment reason!</label>

      </Form.Group>

      <Form.Group>
        <Form.Label>OrderNumber</Form.Label>
        <Form.Control type="text" value={adjustmentOrderNumber} onChange={(e)=>{setAdjustmentOrderNumber(e.target.value)}} required ref={adjustmentordernumberlabel}/>
        <label style={{color:'red',display:'none'}} ref={adjustmentOrderNumberError}>{getAdjustmentOrderNoErrorMsg?getAdjustmentOrderNoErrorMsg:"Please enter adjustment order number!"}</label>
      </Form.Group>

      <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
      ])
  }

  const updateCardEffectiveAmt = (card2Amt) => {
    let finalEffectiveCardAmt=Number(getCard)+Number(card2Amt);
    setCardTotalAmt(finalEffectiveCardAmt);
  }

  const updateCard1EffectiveAmount = (card1Amt) => {
    let finalEffectiveCardAmt=Number(card1Amt);
    if(getCard2){
      finalEffectiveCardAmt=finalEffectiveCardAmt+Number(getCard2);
    }
    setCardTotalAmt(finalEffectiveCardAmt);
  }

  const renderCard2 = () => {
    return(
      [
        <Form noValidate onSubmit={handleFormSubmit}>
          <Form.Group>
            <Form.Label>Card2 Amount</Form.Label>
            <Form.Control type="number" value={getCard2} onChange={(e)=>{setCard2(e.target.value); updateCardEffectiveAmt(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"card2":(Number(e.target.value))}); }} required ref={card2label1}/>
            <label style={{color:'red',display:'none'}} ref={card2Error1}>Please enter an amount!</label>
          </Form.Group>

          <Form.Group>
            <Form.Label>Card2 Transaction Id</Form.Label>

            <Form.Control type="text" value={getCard2TransactionId} onChange={(e)=>{setCard2TransactionId(e.target.value)}} required ref={card2label2}/>
            <label style={{color:'red',display:'none'}} ref={card2Error2}>Please enter Card Transaction ID!</label>
          </Form.Group>

          <Form.Group>
            <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                    Upload Card File</Form.Label><br/>
                  <FileUpload
                required
                accept="image/*"
                name="avatar"
                ref={card2FileLabel}
                storageRef={fire.storage().ref(`disha-payments/Card2_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
                onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
                onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
                onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);fire
                  .storage()
                  .ref(`disha-payments/Card2_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
                  .child(fileName)
                  .getDownloadURL()
                  .then(url => {setCard2File(url);setBackDrop(false);console.log(url)})}}
                onProgress={(progress)=>{setProgressFile(progress)}}
              />
              <label style={{color:'red',display:'none'}} ref={card2FileError}>Please upload card image!</label>
          </Form.Group>

          {getCard2File?(<Form.Group>
            <Form.Label>Card Image URL</Form.Label>
            <Form.Control type="text" value={getCard2File} readOnly/>
          </Form.Group>):null}


    <Form.Group>
      <Form.Label>Effective Amount </Form.Label>

      <Form.Control type="input" onChange={(e)=>{setCardTotalAmt(e.target.value)}} value={getCardTotalAmt} required ref={card2label3}/>
      <label style={{color:'red',display:'none'}} ref={card2Error3}>Please enter effective amount for this order!</label>

    </Form.Group>

    <Form.Group>
      <Button variant="primary" onClick={(e)=>{e.preventDefault();setCard2();setCard2File();setCard2TransactionId(); setCardTotalAmt(getCard2);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"card2":(0)}); setCurrentPaymentMethod('card')}}>Remove card2</Button>
    </Form.Group>

    <Form.Group>
      <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
    </Form.Group>
    </Form>
      ]
    )
  }

  const renderCard = ()=>{

    return(
    [<Form noValidate onSubmit={handleFormSubmit}>
    <Form.Group>
          <Form.Label>Card Amount</Form.Label>

          <Form.Control type="number"  value={getCard} onChange={(e)=>{setCard(e.target.value);setCardValue(e.target.value);updateCard1EffectiveAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"card":(Number(e.target.value))});}} required ref={cardlabel1}/>
          <label style={{color:'red',display:'none'}} ref={cardError1}>Please enter an amount!</label>

  </Form.Group>

  <Form.Group>
    <Form.Label>Card transaction_id</Form.Label>

    <Form.Control type="text" value={getCardTransactionId} onChange={(e)=>{setCardTransactionId(e.target.value)}} required ref={cardlabel2}/>
    <label style={{color:'red',display:'none'}} ref={cardError2}>Please enter Card Transaction ID!</label>
  </Form.Group>

  <Form.Group>
    <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
              Upload Card File</Form.Label><br/>
            <FileUpload
          required
          accept="image/*"
          name="avatar"
          ref={cardFileLabel}
          storageRef={fire.storage().ref(`disha-payments/Card_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
          onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
          onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
          onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);fire
            .storage()
            .ref(`disha-payments/Card_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
            .child(fileName)
            .getDownloadURL()
            .then(url => {setCardFile(url);setBackDrop(false);console.log(url)})}}
          onProgress={(progress)=>{setProgressFile(progress)}}
        />
        <label style={{color:'red',display:'none'}} ref={cardFileError}>Please upload card image!</label>
    </Form.Group>

    {cardFile?(<Form.Group>
      <Form.Label>Card Image URL</Form.Label>
      <Form.Control type="text" value={cardFile} readOnly/>
    </Form.Group>):null}

    {getCurrentPaymentMethod=='card'?(<Form.Group>
      <Form.Label>Effective Amount </Form.Label>

      <Form.Control type="input" onChange={(e)=>{setCardValue(e.target.value)}} value={cardValue} required ref={cardTotalAmtLabel}/>
      <label style={{color:'red',display:'none'}} ref={cardTotalAmtError}>Please enter effective amount for this order!</label>

    </Form.Group>):null}

      {getCurrentPaymentMethod=='card'?(<Form.Group>
        <Button variant="primary" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('card2')}}>Add Card</Button>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='card'?(<Form.Group>
        <Button style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group>):null}
    </Form>])
  }

  const updateEffectiveAmount = (cheq2Amt) => {
    let finalEffectiveChequeAmt=Number(getCheque1)+Number(cheq2Amt);
    setFinalChequeValue(finalEffectiveChequeAmt);
  }

  const updateCheque1EffectiveAmt = (cheq1Amt) => {
    let finalEffectiveChequeAmt=Number(cheq1Amt);
    if(getCheque2){
      finalEffectiveChequeAmt=finalEffectiveChequeAmt+Number(getCheque2);
    }
    setFinalChequeValue(finalEffectiveChequeAmt);
  }

  const renderCheque2 = (value) => {
    return(
      [
        <Form noValidate onSubmit={handleFormSubmit}>
          <Form.Group>
                  <Form.Label>Cheque2 Amount</Form.Label>

                  <Form.Control type="number"  value={getCheque2} onChange={(e)=>{setCheque2(e.target.value); updateEffectiveAmount(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"cheque2":(Number(e.target.value))}); }} required ref={cheque2label1}/>
                  <label style={{color:'red',display:'none'}} ref={cheque2Error1}>Please enter an amount!</label>
          </Form.Group>

          <Form.Group>
            <Form.Label>Cheque2 Date</Form.Label>

            <Form.Control type="date" value={getCheque2Date} onChange={(e)=>{setCheque2Date(e.target.value)}} required ref={cheque2label2}/>
            <label style={{color:'red',display:'none'}} ref={cheque2Error2}>Please enter a Date!</label>

          </Form.Group>

          <Form.Group>
          <Form.Label>Cheque2 Number</Form.Label>

          <Form.Control type="text" value={getCheque2Number} onChange={(e)=>{setCheque2Number(e.target.value)}} required ref={cheque2label3}/>
          <label style={{color:'red',display:'none'}} ref={cheque2Error3}>Please enter a cheque Number!</label>

          </Form.Group>

    <Form.Group>
      <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
              Upload Cheque2</Form.Label><br/>
            <FileUpload
              accept="image/*"
              ref={cheque2ImgLabel}
              name="avatar"

              storageRef={fire.storage().ref(`disha-payments/Cheque2_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
              onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
              onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
              onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);fire
                .storage()
                .ref(`disha-payments/Cheque2_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
                .child(fileName)
                .getDownloadURL()
                .then(url => {setChequeFile(url);setBackDrop(false);sessionStorage.setItem(`NewFileUpload2`,url);console.log(url);cheque2ImgRef.current.style.display="block";})}}
              onProgress={(progress)=>{setProgressFile(progress)}}
            />
        <label style={{color:'red',display:'none'}} ref={cheque2ImgError}>Please upload cheque2 image!</label>
    </Form.Group>

    {chequeFile?(<Form.Group ref={cheque2ImgRef}>
      <Form.Label>Cheque2 Image URL</Form.Label>
      <Form.Control type="input" value={chequeFile} readOnly/>
    </Form.Group>):null}

    <Form.Group>
      <Form.Label>Effective Amount </Form.Label>

      <Form.Control type="input" onChange={(e)=>{setFinalChequeValue(e.target.value)}} value={getFinalChequeValue} required ref={cheque1label4}/>
      <label style={{color:'red',display:'none'}} ref={cheque1Error4}>Please enter effective amount for this order!</label>

    </Form.Group>

    <Form.Group>
      <Button variant="primary" onClick={(e)=>{e.preventDefault();setCheque2();setChequeFile();setCheque2Date();setCheque2Number(); setFinalChequeValue(getCheque1);;setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"cheque2":(0)}); setCurrentPaymentMethod('cheque')}}>Remove cheque2</Button>
    </Form.Group>

    <Form.Group>
      <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
    </Form.Group>
    </Form>
      ]
    )
  }

  const renderCheque = (value)=>{

    return(
    [<Form noValidate onSubmit={handleFormSubmit}>
      <Form.Group>
        <Form.Label>Cheque1 Amount</Form.Label>
        <Form.Control type="number"  value={getCheque1} onChange={(e)=>{setCheque1(e.target.value); setChequeValue(e.target.value); updateCheque1EffectiveAmt(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"cheque":(Number(e.target.value))});
         }} required ref={cheque1label1}/>
        <label style={{color:'red',display:'none'}} ref={cheque1Error1}>Please enter an amount!</label>
      </Form.Group>

  <Form.Group>
    <Form.Label>Cheque1 Date</Form.Label>

    <Form.Control type="date" value={getCheque1Date} onChange={(e)=>{setCheque1Date(e.target.value)}} required ref={cheque1label2}/>
    <label style={{color:'red',display:'none'}} ref={cheque1Error2}>Please enter a Date!</label>

</Form.Group>

<Form.Group>
  <Form.Label>Cheque1 Number</Form.Label>

  <Form.Control type="text" value={getCheque1Number} onChange={(e)=>{setCheque1Number(e.target.value)}} required ref={cheque1label3}/>
  <label style={{color:'red',display:'none'}} ref={cheque1Error3}>Please enter a cheque Number!</label>

</Form.Group>

<Form.Group>
<Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                Upload Cheque1</Form.Label><br/>
              <FileUpload
            required
            accept="image/*"
            ref={cheque1ImgLabel}
            name="avatar"

            storageRef={fire.storage().ref(`disha-payments/Cheque1_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)}
            onUploadStart={()=>{setIsUploading(true);setProgressFile(0);setBackDrop(true)}}
            onUploadError={(error)=>{setIsUploading(false);console.error(error)}}
            onUploadSuccess={(fileName)=>{setProgressFile(100);setIsUploading(false);fire
              .storage()
              .ref(`disha-payments/Cheque1_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
              .child(fileName)
              .getDownloadURL()
              .then(url => {setNewFile(url);setBackDrop(false);sessionStorage.setItem(`NewFileUpload`,url);console.log(url)})}}
            onProgress={(progress)=>{setProgressFile(progress)}}
          />
          <label style={{color:'red',display:'none'}} ref={cheque1ImgError}>Please upload cheque1 image!</label>

      </Form.Group>

      {newFile?(<Form.Group>
        <Form.Label>Cheque1 Image URL</Form.Label>
        <Form.Control type="text" value={newFile} readOnly/>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='cheque'?(<Form.Group>
        <Form.Label>Effective Amount </Form.Label>
        <Form.Control type="input" onChange={(e)=>{setChequeValue(e.target.value)}} value={getChequeValue}  required ref={cheque1label4}/>
        <label style={{color:'red',display:'none'}} ref={cheque1Error4}>Please enter effective amount for this order!</label>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='cheque'?(<Form.Group>
        <Button variant="primary" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('cheque2')}}>Add Cheque</Button>
      </Form.Group>):null}

      {getCurrentPaymentMethod=='cheque'?(<Form.Group>
        <Button style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group>):null}
      </Form>])
  }

  const handlePaytmFileUpload = async () => {
    setBackDrop(true);
    let fileInput = document.getElementById("paytmFileUploadId");
    console.log(fileInput.files);

    if (!fileInput.files) return

    let base64 = await mergeFile(fileInput.files)

    console.log(base64)
    let paytmFilename=fileInput.files[0].name;

    let storageRef = fire.storage()
    .ref(`disha-payments/Paytm_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
    .child(paytmFilename);


    let task = storageRef.putString(base64, 'data_url', {contentType:"image/jpg"}).then(function(snapshot) {
         console.log('Uploaded a base64 string!');

         storageRef.getDownloadURL().then(function(url) {
             console.log(url);
             setBackDrop(false);
             setPaytmFile(url);
             sessionStorage.setItem(`PaytmUploadFile`,url);
         });
    });
  }

  const renderPaytm = ()=>{
    return([<Form noValidate onSubmit={handleFormSubmit}>
      <Form.Group>
                <Form.Label>Paytm Amount</Form.Label>

                <Form.Control type="number"  value={getpaytm} onChange={(e)=>{setpaytm(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"paytm":(Number(e.target.value))});}} required ref={paytmlabel}/>
                <label style={{color:'red',display:'none'}} ref={paytmAmountError}>Please enter amount!</label>

        </Form.Group>
        <Form.Group>
            <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                Upload Paytm File</Form.Label><br/>

            <input type="file" multiple id="paytmFileUploadId" accept="image/*" required />
          <label style={{color:'red',display:'none'}} ref={paytmFileError}>Please enter a file!</label>
      </Form.Group>
      <Button variant="success" onClick={()=>{handlePaytmFileUpload();}}>Upload file</Button>

      {paytmFile?(<Form.Group>
        <Form.Label>Paytm Image URL</Form.Label>
        <Form.Control type="text" value={paytmFile} readOnly/>
      </Form.Group>):null}

        <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group></Form>
    ])
  }

  const handleRazorpayFileUpload = async () => {
    setBackDrop(true);
    let fileInput = document.getElementById("razorpayFileUploadId");
    console.log(fileInput.files);

    if (!fileInput.files) return

    let base64 = await mergeFile(fileInput.files)

    console.log(base64)

    let razorPayFilename=fileInput.files[0].name;


    let storageRef = fire.storage()
    .ref(`disha-payments/RazorPay_Upload/${deliveryDate.getFullYear()}/${deliveryDate.getMonth()+1}/${deliveryDate.getDate()}-${deliveryDate.getMonth()+1}-${deliveryDate.getFullYear()}`)
    .child(razorPayFilename);


    let task = storageRef.putString(base64, 'data_url', {contentType:"image/jpg"}).then(function(snapshot) {
         console.log('Uploaded a base64 string!');

         storageRef.getDownloadURL().then(function(url) {
             console.log(url);
             setBackDrop(false);
             setRazorpayFile(url);
             sessionStorage.setItem(`RazorPayFile`,url);
         });
    });
  }

  const renderRazorpay = ()=>{
    console.log(deliveryDate)
    return([
      [<Form noValidate onSubmit={handleFormSubmit}><Form.Group>
        <Form.Label>RazorPay Amount</Form.Label>

        <Form.Control type="number"  value={getrazorPay} onChange={(e)=>{setrazorPay(e.target.value);setCurrentPaymentsTotal({...getCurrentPaymentsTotal,"razorpay":(Number(e.target.value))});}} required ref={razorpaylabel}/>
        <label style={{color:'red',display:'none'}} ref={razorpayAmountError}>Please enter an amount!</label>
        </Form.Group>
        <Form.Group>
      <Form.Label style={{backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4}}>
                Upload RazorPay File</Form.Label><br/>

            <input type="file" multiple id="razorpayFileUploadId" accept="image/*" required />

          <label style={{color:'red',display:'none'}} ref={razorpayFileError}>Please enter a file!</label>
      </Form.Group>
      <Button variant="success" onClick={()=>{handleRazorpayFileUpload();}}>Upload file</Button>

      {razorpayFile?(<Form.Group>
        <Form.Label>RazorPay Image URL</Form.Label>
        <Form.Control type="text" value={razorpayFile} readOnly/>
      </Form.Group>):null}

        <Form.Group>
        <Button  style={{float:'right'}} variant="outline-success" type="submit" ref={currentSaveButton} onClick={()=>{setMultiplePayments(true);setIntialValueUpdate(false);}}>Save Current Payment</Button>
      </Form.Group>
      {getBackDrop?(<Backdrop parentLoadStatus={getBackDrop}/>):(null)}
      </Form>
      ]
    ])
  }



   const [getStatus,setStatus] = useState()
   const [getShow,setShow] = useState({
     'cash' : renderCash(),
     'payment_pending' : renderPendingPayment(),
     'adjustments' : renderAdjustment(),
     'card' : renderCard(),
     'cheque' : renderCheque(),
     'paytm' : renderPaytm(),
     'razorpay' : renderRazorpay(),
     'superkredit': renderSuperKredit(),
     'cheque2' : renderCheque2(),
     'card2' : renderCard2(),
     'rupifi' : renderRupifi(),
     'finaleap' : renderFinaleap()
   })
    const [getHistory,setHistory] = useState([])
    const [getCurrentCounter,setCurrentCounter] = useState(0)



const handleMainSubmit = (event)=>{

  const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return
    }

    if(currentSaveButton.current && props.orderPaymentMode!="Credit" && props.orderPaymentMode!="Internal_Credit"){
      setDidSubmit(true)
      currentSaveButton.current.click()
      event.preventDefault();
      event.stopPropagation();
      return
    }

    if(Object.keys(getJSON).length==0){
      if(props.orderPaymentMode=="Credit"){
        let ele={};
        ele['payment_mode']="SuperKredit";
        ele['superkredit_amount']=props.totalSuperKreditAmt;
        ele['total_amount']=props.totalSuperKreditAmt;
        let superKreditPaymentObj={};
        superKreditPaymentObj[getCurrentCounter]=ele;
        console.log(superKreditPaymentObj);

        event.preventDefault();
        event.stopPropagation();

        props.callback(props.driver_id,props.order_id, props.status,{
          'payment_details':{...superKreditPaymentObj,'total_amount_paid' : props.totalSuperKreditAmt}})

        return;
      }else{
        event.preventDefault();
        event.stopPropagation();
        return
      }
    }

    event.preventDefault();
    event.stopPropagation();

    let finalTotalAmt=0;
    Object.keys(getJSON).forEach((key)=>{
      if("total_amount_paid" in getJSON[key]){
        finalTotalAmt=finalTotalAmt+Number(getJSON[key]["total_amount_paid"]);
      }
    });
    console.log(finalTotalAmt);
    console.log(getJSON);

    if(Object.keys(getJSON).length==1){
      event.preventDefault();
      event.stopPropagation();
      let temp = getJSON[0]
      props.callback(props.driver_id,props.order_id, props.status,{
        'payment_details':{...getJSON,'total_amount_paid' : finalTotalAmt.toString()}})

    }else{
      props.callback(props.driver_id,props.order_id, props.status, {
        'payment_details':{...getJSON,'total_amount_paid' : finalTotalAmt.toString()}
      })
    }

    sessionStorage.removeItem(`status_current`)

}

  const getFinalPaymentsAmount = () => {
    let totalAmt=0;
    totalAmt+=(isNaN(getExistingPaymentsTotal)?0:Number(getExistingPaymentsTotal));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.cash)?0:Number(getCurrentPaymentsTotal.cash));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.finaleap)?0:Number(getCurrentPaymentsTotal.finaleap));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.card)?0:Number(getCurrentPaymentsTotal.card));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.card2)?0:Number(getCurrentPaymentsTotal.card2));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.cheque)?0:Number(getCurrentPaymentsTotal.cheque));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.cheque2)?0:Number(getCurrentPaymentsTotal.cheque2));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.paytm)?0:Number(getCurrentPaymentsTotal.paytm));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.razorpay)?0:Number(getCurrentPaymentsTotal.razorpay));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.payment_pending)?0:Number(getCurrentPaymentsTotal.payment_pending));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.adjustments)?0:Number(getCurrentPaymentsTotal.adjustments));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.superkredit)?0:Number(getCurrentPaymentsTotal.superkredit));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.internalsuperkredit)?0:Number(getCurrentPaymentsTotal.internalsuperkredit));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.internalsuperkredit2)?0:Number(getCurrentPaymentsTotal.internalsuperkredit2));
    totalAmt+=(isNaN(getCurrentPaymentsTotal.rupifi)?0:Number(getCurrentPaymentsTotal.rupifi));

    return totalAmt.toFixed(2);
  }

  const submit_ref = createRef()

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Describe Delivery Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form onSubmit={handleMainSubmit}>
                <Form.Group>
                  <Button type="submit" hidden ref={submit_ref}>Submit</Button>
                </Form.Group>
              </Form>
                {(multiplePayments==true)?(renderAccordion()):null}
                {(getJSON && Object.keys(getJSON).length>0)?(<Button variant="outline-danger" style={{margin:10}} onClick={()=>{clearExistingPayments();}} >Clear Existing Payments</Button>):null}
                <Form.Group>
                  <Form.Label>Payment Mode</Form.Label>
                  {props.orderPaymentMode!="Credit"?(<InputGroup>
                    <Form.Control type="input" placeholder={defaultPayment} value={getCurrentPaymentMethod!="cheque2"?getCurrentPaymentMethod!="card2"?(getCurrentPaymentMethod=="internalsuperkredit" || getCurrentPaymentMethod=="internalsuperkredit2")?"SuperKredit":(getCurrentPaymentMethod=="superkredit"?"Rupifi":getCurrentPaymentMethod):"card":"cheque"} readOnly/>
                    <DropdownButton variant="outline-primary">
                      {(paymentHashmap['cash']==true)?(<Dropdown.Item href="#" name="Cash" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('cash')}}>Cash</Dropdown.Item>):null}
                      {(paymentHashmap['card']==true)?(<Dropdown.Item href="#" name="Card" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('card')}}>Card</Dropdown.Item>):null}
                      {paymentHashmap['internalsuperkredit']==true && (props.orderPaymentMode=="Internal_Credit")?null:(paymentHashmap['cheque']==true)?(<Dropdown.Item href="#" name="Cheque" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('cheque')}}>Cheque</Dropdown.Item>):null}
                      {(paymentHashmap['paytm']==true)?(<Dropdown.Item href="#" name="Paytm" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('paytm')}}>Paytm</Dropdown.Item>):null}
                      {(paymentHashmap['razorpay']==true)?(<Dropdown.Item href="#" name="RazorPay" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('razorpay')}}>RazorPay</Dropdown.Item>):null}
                      {(paymentHashmap['payment_pending']==true)?(<Dropdown.Item href="#" name="PendingPayment" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('payment_pending')}}>Pending Payment</Dropdown.Item>):null}
                      {(paymentHashmap['adjustments']==true)?(<Dropdown.Item href="#" name="Adjustments" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('adjustments')}}>Adjustments</Dropdown.Item>):null}
                      {(paymentHashmap['rupifi']==true && props.orderPaymentMode=="Rupifi Credits")?(<Dropdown.Item href="#" name="Rupifi" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('rupifi')}}>Rupifi</Dropdown.Item>):null}
                      {/*(paymentHashmap['superkredit']==true && props.orderPaymentMode!="Internal_Credit" && Number(props.credit_amt)>0)?(<Dropdown.Item href="#" name="Rupifi" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('superkredit')}}>Rupifi</Dropdown.Item>):null*/}
                      {paymentHashmap['internalsuperkredit']==true && (props.orderPaymentMode=="Internal_Credit")?
                      (<Dropdown.Item href="#" name="InternalSuperKredit" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('internalsuperkredit')}}>
                      Internal SuperKredit</Dropdown.Item>):null}
                      {(paymentHashmap['finaleap']==true && props.orderPaymentMode=="Finaleap Credits")?(<Dropdown.Item href="#" name="Finaleap" onClick={(e)=>{e.preventDefault();setCurrentPaymentMethod('finaleap')}}>Finaleap</Dropdown.Item>):null}
                      
                    </DropdownButton>
                    <InputGroup.Append>
                      {getCurrentPaymentMethod != defaultPayment?<Button variant="outline-danger" onClick={()=>{ setCurrentPaymentsTotal({...getCurrentPaymentsTotal,[getCurrentPaymentMethod]:(0)}); setCurrentPaymentMethod(defaultPayment); }} >X</Button>:""}
                    </InputGroup.Append>
                  </InputGroup>):(
                    <Form.Control type="input" placeholder={"Rupifi"} value={"Rupifi"} readOnly/>
                  )}
                </Form.Group>
                {(getCurrentPaymentMethod=='cash')?(renderCash()):null}
                {(getCurrentPaymentMethod=='finaleap' && props.orderPaymentMode=="Finaleap Credits")?(renderFinaleap()):null}
                {(getCurrentPaymentMethod=='card' || getCurrentPaymentMethod=='card2')?(renderCard()):null}
                {(getCurrentPaymentMethod=='card2')?(renderCard2()):null}
                {(getCurrentPaymentMethod=='cheque' || getCurrentPaymentMethod=='cheque2')?(renderCheque()):null}
                {(getCurrentPaymentMethod=='cheque2')?(renderCheque2()):null}
                {(getCurrentPaymentMethod=='paytm')?(renderPaytm()):null}
                {(getCurrentPaymentMethod=='razorpay')?(renderRazorpay()):null}
                {(getCurrentPaymentMethod=='payment_pending')?(renderPendingPayment()):null}
                {(getCurrentPaymentMethod=='adjustments')?(renderAdjustment()):null}
                {(getCurrentPaymentMethod=='superkredit')?(renderSuperKredit()):null}
                {(props.orderPaymentMode=="Credit")?(renderNewSuperKredit()):null}
                {(getCurrentPaymentMethod=='rupifi')?(renderRupifi()):null}
                {paymentHashmap['internalsuperkredit']==true && Object.keys(getJSON).length==0 && getCurrentPaymentMethod=='internalsuperkredit' && (props.orderPaymentMode=="Internal_Credit")?(renderInternalSuperKredit()):null}
                {(getCurrentPaymentMethod=='internalsuperkredit2')?(renderInternalSuperKredit2()):null}

                {(getCurrentPaymentMethod != defaultPayment)?(<InputGroup>
                    <InputGroupAddon addonType="prepend">Total Amount</InputGroupAddon>
                    <Input value={getFinalPaymentsAmount()} readOnly/>
                </InputGroup>):null}

                {(props.orderPaymentMode=="Credit")?(<InputGroup>
                    <InputGroupAddon addonType="prepend">Total Amount</InputGroupAddon>
                    <Input value={props.totalSuperKreditAmt} readOnly/>
                </InputGroup>):null}

          {getBackDrop?(<Backdrop parentLoadStatus={getBackDrop}/>):(null)}
        </Modal.Body>
        <Modal.Footer>
            <Button type="submit" onClick={(e)=>{e.preventDefault();submit_ref.current.click();sessionStorage.removeItem("NewFileUpload2");sessionStorage.removeItem("NewFileUpload2");sessionStorage.removeItem("NewFileUpload");sessionStorage.removeItem("RazorPayFile");sessionStorage.removeItem("PaytmUploadFile")}}>Submit</Button>
          <Button onClick={()=>{setStatus();props.onHide();sessionStorage.removeItem("NewFileUpload2");sessionStorage.removeItem("NewFileUpload2");sessionStorage.removeItem("NewFileUpload");sessionStorage.removeItem("RazorPayFile");sessionStorage.removeItem("PaytmUploadFile")}}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }



  function App(props) {
    const [modalShow, setModalShow] = React.useState(props.ModalValue);

    return (
        <MyVerticallyCenteredModal
          show={props.ModalValue}
          onHide={() => {setModalShow(false);props.parentCallBack(false)}}
          driver_id = {props.driver_id}
          order_id = {props.order_id}
          orderPaymentMode = {props.orderPaymentMode}
          totalSuperKreditAmt = {props.totalSuperKreditAmt}
          paymentDetails = {props.paymentDetails}
          credit_amt = {props.credit_amt}
          status = {sessionStorage.getItem(`status_current`)}
          delDate = {new Date(props.deliveryDate)}
          childfirebaseInsert = {props.childfirebaseInsert}
          callback = {props.callback}
        />
    );
  }

 export default App
