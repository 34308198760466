import React, { useState, useEffect, useRef, useMemo } from "react";
import { service_url, token } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import fire from "../../Configs/firebase";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import moment from "moment";
import { InputGroupAddon } from "reactstrap";
import { Form, InputGroup, Button } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import StockCorrectionCreationModal from "./StockCorrectionCreationModal";
import StockCorrectionApproveModal from "./StockCorrectionApproveModal";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import ExportToExcel from "./Export/ExportToExcel";
import { warehouseNames } from "../../Configs/mysqlconfig";
import { warehouses } from '../../indexedDBUtility.js';
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const getStockStatusTextForQuantity = (quantity) => {
  if (!quantity) {
    return;
  }

  if (quantity > 0) {
    return (
      <p style={{ fontWeight: "bold", color: "green", display: "inline" }}>
        {" "}
        (Gain){" "}
      </p>
    );
  } else {
    return (
      <p style={{ fontWeight: "bold", color: "red", display: "inline" }}>
        {" "}
        (Loss){" "}
      </p>
    );
  }
};

const StockCorrection = ({ history }) => {
  // const warehouseOptions = [
  //   "Bhiwandi",
  //   "Goa",
  //   "Vashi",
  //   "Virar",
  //   "Mira Road",
  //   "Wadala",
  // ];

  // const warehouseOptions = warehouseNames;
  const [warehouseOptions,setWarehouseOptions] = useState(["All"])
  const [warehouseOptionsList, setWarehouseOptionsList] = useState([]);

  useEffect(() => {
    warehouses
      .then(data => {
        console.log("Fetched Warehouses:", data);
        const allWarehouses = [
          { value: "All", label: "All" },
          ...data.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
        ];

        const allWarehousesList = [
         "All",
          ...data.map(obj => (obj.warehouse))
        ];

        setWarehouseOptions(allWarehousesList)
        setWarehouseOptionsList(allWarehouses);
        console.log("All warehouses:", allWarehouses,allWarehousesList);
      })
      .catch(error => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, []);

  const [warehouse, setWarehouse] = useState({});
  const [listingDateType, setListingDateType] = useState({
    label: "Stock date",
    value: "stock_date",
  });
  const [productMasterDataRaw, setProductMasterDataRaw] = useState({});
  const [addTransitItemDropdownList, setAddTransitItemDropdownList] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [stockDate, setStockDate] = useState(new Date());
  const [approvedDate, setApprovedDate] = useState(new Date());
  const [routeData, setRouteData] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [backupRouteData, setBackupRouteData] = useState({});
  const [exportSystemStock, setExportSystemStock] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [typeFilter, setTypeFilter] = useState({ label: "All", value: "All" });

  const [stockCorrectionPopUpShow, setStockCorrectionPopUpShow] =
    useState(false);

  const [
    stockCorrectionApprovalPopupShow,
    setStockCorrectionApprovalPopupShow,
  ] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const userCity = sessionStorage.getItem("userCity") || "";
  const userType = sessionStorage.getItem("userType") || "";

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  useEffect(() => {
    setIsLoading(true);
    generateProductMasterData();

    let _warehouse_options_list = [];
    warehouseOptions.forEach((item, index) => {
      _warehouse_options_list.push({ label: item, value: item });
    });

    if (userCity && userCity !== "" && warehouseOptions.includes(userCity)) {
      setWarehouse({ label: userCity, value: userCity });
    } else {
      setWarehouse(_warehouse_options_list[0]);
    }

    fetchSystemStockForExport();
    setIsLoading(false);
    setWarehouseOptionsList(_warehouse_options_list);
  }, []);

  useEffect(() => {
    if (stockDate && warehouse && warehouse.value) {
      setIsLoading(true);
      fetchStockTransferRecords();
      setExportSystemStock([]);
      fetchSystemStockForExport();
      setIsLoading(false);
    }
  }, [stockDate, warehouse]);

  useEffect(() => {
    if (searchText || typeFilter.value != "All") {
      let searchStr = searchText.toLowerCase();
      let finalRouteData = [];

      backupRouteData.map((item, index) => {
        let category = item.category || "";
        category = category.toLowerCase();
        let sub_category = item.sub_category || "";
        sub_category = sub_category.toLowerCase();

        if (
          (!searchStr ||
            (searchStr &&
              (category.includes(searchStr) ||
                sub_category.includes(searchStr)))) &&
          (typeFilter.value == item.type || typeFilter.value == "All")
        ) {
          finalRouteData.push(item);
        }
      });

      setRouteData(finalRouteData);
    } else {
      setRouteData(backupRouteData);
    }
  }, [searchText, typeFilter.value]);

  const fetchProductDataFromDB = () => {
    setBackDrop(true);
    return new Promise((resolve, reject) => {
      let product_base_ref = fire.database().ref("Product_Master_New/A");
      product_base_ref.once(
        "value",
        (snapshot) => {
          let data = snapshot.val();
          resolve(data);
          setBackDrop(false);
        },
        (err) => {
          reject("error occured while fetching data from firebase");
          setBackDrop(false);
        }
      );
    });
  };

  const fetchStockTransferRecords = () => {
    setBackDrop(true);
    let date = "";
    let url = "";

    date = formatDate(new Date(stockDate));
    url = `${service_url}/api/stock-correction?stock_date=${date}&warehouse=${warehouse.value}&token=${token}`;

    // console.log(url);

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let results = result.data || [];
        let stockTransferData = [];

        results.forEach((item, index) => {
          if (item.type !== "Auto") {
            stockTransferData.push(item);
          }
        });

        let resCount = results.filter(item => item.approved_flag != 'Y')
        stockTransferData.sort(function (a, b) {
          if (a.approved_qty || a.approved_qty === 0) {
            return 1;
          }
          if (b.approved_qty || b.approved_qty === 0) {
            return -1;
          }
          return 0;
        });

        setBackDrop(false);
        setRouteData(stockTransferData);
        setBackupRouteData(stockTransferData);
        if(resCount.length>0) {
          setShowButton(true);
        }
      })
      .catch((err) => {
        toast(`Error in fetching stock correction data : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const fetchSystemStockForExport = () => {
    let url = `${service_url}/api/system-stock?warehouse=${
      warehouse.value
    }&token=${token}&delivery_date=${moment(stockDate).format("YYYY-MM-DD")}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const data = result.data || [];
        const finalExportData = [];
        for (let item of data) {
          item.opening_stock = 0;
          item.shipped_qty = Number(item.item_modified_qty) || 0;
          item.final_received = Number(item.item_qty) || 0;
          item.short_excess = item.shipped_qty - item.final_received;
          item.returned_qty = Number(item.stock_return_qty) || 0;
          item.stock_gain_loss = Number(item.stock_change) || 0;
          finalExportData.push(item);
        }

        setExportSystemStock(finalExportData);
      })
      .catch((err) => {
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  };

  const isSelectWarehouseOptionDisabled = (
    user_type,
    user_city,
    selected_warehouse
  ) => {
    if (
      user_type.toLowerCase() === "admin" ||
      user_type.toLowerCase() === "financehead" ||
      (user_type.toLowerCase() === "operation" && user_city === "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const generateProductMasterData = async () => {
    setBackDrop(true);
    const products = await fetchProductDataFromDB();
    generateTransitItemsList(products);
    setProductMasterDataRaw(products);
    setBackDrop(false);
  };

  const generateTransitItemsList = (product_master) => {
    if (!product_master || Object.keys(product_master).length === 0) {
      return console.log("Function did not receive proper arguements");
    }
    const itemList = [];
    Object.keys(product_master).forEach((key, index) => {
      const product = product_master[key];
      const item_id = product.item_id;
      const item_disc = product.item_description1;
      const brand = product.brand;

      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_disc}`,
      };
      itemList.push(item);
    });

    setAddTransitItemDropdownList(itemList);
  };

  const TransitRoutesTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          {/* <th>Return Date</th> */}
          <th>Item ID</th>
          <th>Description</th>
          <th>Gain/Loss Qty</th>
          <th>Approved Qty</th>
          <th>Inventory</th>
          <th>Inventory After Correction</th>
          {/* <th>Indicator</th> */}
        </tr>
      </thead>
    );
  };

  const TransitRoutesTableBody = () => {
    let rows = routeData.map((item, index) => {
      let row = item || {};
      let gainLossQty = row.quantity; //Math.round(Math.abs(row.quantity) - row.inventory_after_shipped);
      let stock_status = row.quantity > 0 ? "stock_gain" : "stock_loss";
      let inventory_after_correction = Number(row.inventory_after_shipped) + Number(row.approved_qty ? row.approved_qty : row.quantity);
      inventory_after_correction = inventory_after_correction.toFixed(2);
      return (
        <tr key={index}>
          {/* <td>{row.stock_date}</td> */}
          <td>{row.item_id}</td>
          <td>{row.item_disc}</td>
          <td>
            {Math.abs(Number(gainLossQty))}{" "}
            {getStockStatusTextForQuantity(gainLossQty)}
          </td>
          {row.approved_qty || row.approved_qty === 0 ? (
            <td>
              {Math.abs(row.approved_qty)}{" "}
              {getStockStatusTextForQuantity(row.approved_qty)}{" "}
            </td>
          ) : (
            <td style={{ color: "red", fontWeight: "bold" }}>
              Pending Approval
            </td>
          )}
          <td>{row.inventory_after_shipped}</td>
          <td>
            {inventory_after_correction}
          </td>

          {/* <td>{row.indicator}</td> */}
        </tr>
      );
    });

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleReturnDateChange = (selecteddate) => {
    setStockDate(new Date(selecteddate));
  };

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item Name",
        value: "item_disc",
      },

      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Opening stock",
        value: "opening_stock",
      },

      {
        label: "Dispatched by Bhiwandi",
        value: "shipped_qty",
      },
      {
        label: "Short/ Excess in receiving DTD",
        value: "short_excess",
      },
      {
        label: "Final received from Bhiwandi",
        value: "final_received",
      },
      {
        label: "Delivered as per delivery report",
        value: "delivered_qty",
      },
      {
        label: "Transferred to Bhiwandi",
        value: "returned_qty",
      },
      {
        label: "Stock Gain Or Loss",
        value: "stock_gain_loss",
      },
      {
        label: "Remark",
        value: "approved_qty",
      },
    ],
    []
  );

  const handleImport = (event) => {
    const files = event.target.files;
    if (files.length) {
      let fileObj = files[0];

      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, stockCorrectionList) => {
        if (err) {
          console.log(err);
        } else {
          let responseList = stockCorrectionList.rows;
          let itemsList = [];
          for (let i = 1; i < responseList.length; i++) {
            let stockCorrection = responseList[i];
            let itemToSave = {};

            itemToSave.item_id = stockCorrection[0];
            itemToSave.quantity = stockCorrection[1];
            itemToSave.inventory = stockCorrection[2];
            itemToSave.inventory_after_shipped = stockCorrection[3];
            itemToSave.remarks = stockCorrection[4] || "";
            itemsList.push(itemToSave);
          }

          let dataToSend = {
            stock_date: moment(new Date()).format("YYYY-MM-DD"),
            warehouse: warehouse.value || "",
            itemsList,
          };
          console.log(dataToSend);

          let url = `${service_url}/api/stock-correction?token=${token}`;

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": sessionStorage.getItem("x-access-token"),
            },
            body: JSON.stringify(dataToSend),
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.success == 1) {
                toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
                setTimeout(function () {
                  window.location.reload();
                }, 2000);
              } else {
                toast(`${result.message}`, { type: toast.TYPE.ERROR });
              }
            })
            .catch((err) => {
              toast(`Error in creating transit route records: ${err}`, {
                type: toast.TYPE.ERROR,
              });
            });
        }
      });
    }
  };

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  const handleReportsClick = () => {
    history.push("/stock-correction-report");
  }

  return (
    <>
      <NavBar />

      <div className="container col-sm-12" style={{ paddingTop: 100 }}>
        <center>
          <h2 className="h2">Stock Correction</h2>
        </center>
        <Form inline>
          {listingDateType.value === "stock_date" ? (
            <InputGroup size="sm" style={{ margin: "20px" }}>
              <InputGroupAddon addonType="prepend">Stock Date</InputGroupAddon>
              <DatePicker
                selected={stockDate}
                onChange={handleReturnDateChange}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
              />
            </InputGroup>
          ) : (
            <InputGroup size="sm" style={{ margin: "20px" }}>
              <InputGroupAddon addonType="prepend">
                Approved Date
              </InputGroupAddon>
              <DatePicker
                selected={approvedDate}
                onChange={(date) => setApprovedDate(new Date(date))}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
              />
            </InputGroup>
          )}
          <div
            style={{ width: "200px", marginTop: "-25px", marginRight: "30px" }}
          >
            Date type
            <Select
              options={[
                { label: "Stock date", value: "stock_date" },
                { label: "Approved date", value: "approved_date" },
              ]}
              value={listingDateType}
              onChange={(val) => {
                setListingDateType(val);
              }}
            />
          </div>
          <div style={{ width: "200px", marginTop: "-25px" }}>
            Src Warehouse
            <Select
              isDisabled={isSelectWarehouseOptionDisabled(
                userType,
                userCity,
                warehouse
              )}
              options={warehouseOptionsList}
              value={warehouse}
              onChange={(val) => {
                setWarehouse(val);
              }}
            />
          </div>
          <Button
            size="sm"
            style={{ marginLeft: "30px", marginRight: "10px" }}
            onClick={() => {
              setStockCorrectionPopUpShow(true);
            }}
          >
            Create Stock Correction
          </Button>
          {(userType.toLowerCase() === "admin" ||
            userType.toLowerCase() === "financehead" || userType.toLowerCase() === 'dcmanager') &&
            moment(stockDate).diff(moment().subtract(3, "days"), "days") >=
              0 && (
              <Button
                size="sm"
                disabled = {!showButton}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setStockCorrectionApprovalPopupShow(true);
                }}
              >
                Approve Stock Correction
              </Button>
            )}
          <Button
            size="sm"
            color="blue"
            style={{ marginRight: "10px" }}
            onClick={handleReportsClick}
          >
            Reports
          </Button>{" "}
          <ExportToExcel
            sheet1={exportSystemStock}
            filename={"stock_correction_summary"}
            sheet1Headers={excelHeaders}
            // sheet2Headers={excelHeadersLooseItems}
            sheet1Name="Items"
            // sheet2Name="Loose Items"
            buttonText="Export All"
          />
        </Form>

        <div className="row" style={{ paddingTop: 10 }}>
          <div className="col-md-6">
            <div className="input-group">
              <div className="custom-file">
                <Form.Control
                  placeholder="Category / Sub category"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-5" style={{ marginLeft: "60px" }}>
            <div className="input-group">
              <div className="custom-file">
                <input
                  type="file"
                  name="file"
                  className="custom-file-input"
                  id="inputGroupFile"
                  required
                  onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                <label className="custom-file-label" htmlFor="inputGroupFile">
                  Choose file
                </label>
              </div>
            </div>
          </div>
        </div>

        {Object.keys(routeData).length > 0 ? (
          <table
            className="table table-striped"
            style={{ marginTop: 10, textAlign: "center" }}
          >
            <TransitRoutesTableHeader />
            <TransitRoutesTableBody />
          </table>
        ) : (
          <center>
            <div>No stock correction data</div>
          </center>
        )}

        {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}

        {stockCorrectionPopUpShow ? (
          <StockCorrectionCreationModal
            warehouse={warehouse.value || ""}
            show={stockCorrectionPopUpShow}
            stockDate={stockDate}
            addTransitItemDropdownList={addTransitItemDropdownList}
            productMasterDataRaw={productMasterDataRaw}
            onHide={() => setStockCorrectionPopUpShow(false)}
          />
        ) : null}
        {stockCorrectionApprovalPopupShow ? (
          <StockCorrectionApproveModal
            warehouse={warehouse.value || ""}
            show={stockCorrectionApprovalPopupShow}
            stockDate={stockDate}
            addTransitItemDropdownList={addTransitItemDropdownList}
            productMasterDataRaw={productMasterDataRaw}
            onHide={() => setStockCorrectionApprovalPopupShow(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default StockCorrection;
