import React, { useState, useEffect, useRef } from 'react'
import fire from '../../Configs/firebase'
import { Table,Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import szlogo from '../../assests/images/sz_logo.png';
import khetika from "../../assests/images/khetika.png";
import footer from '../../assests/images/footer.png';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import ReactToPrint from 'react-to-print';
import Backdrop from '../backdrop/backdrop';
import { toast } from 'react-toastify';
import superprimelogo from '../../assests/images/superprimelogo.png';
import shippingpriority from '../../assests/images/priority_shipping.png';

import { support_portal_url, orderingApiUrl } from "../../Configs/mysqlconfig";

function formatDate(selecteddate){
  let dt=new Date(selecteddate);
  let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];

    let day = dt.getDate();

    let monthIndex = dt.getMonth();
    let monthName = monthNames[monthIndex];

    let year = dt.getFullYear().toString().substr(2,2);
    let formattedDate = `${day}-${monthName}-${year}`;
    return formattedDate;
}

function getRetailerIdFromOrderNumber(orderNumber) {
  if(!orderNumber) {
    return "";
  }

  if(orderNumber.includes("SC")) {
    let retailerStr = orderNumber.split("/")[0];
    return retailerStr.substr(retailerStr.length - 5);;
  }else if(orderNumber.includes("-")) {
    return orderNumber.split("-")[0];
  }else{
    return orderNumber.split("/")[0];
  }
}



const convertCity = (cityVal, retailerid) => {
  // cityVal = cityVal || "";
  // if(cityVal)
  //   cityVal = cityVal.toLowerCase();
  // if(cityVal=="pune"){
  //   return "Pune";
  // } else if(cityVal=="surat"){
  //   return "Surat";
  // } else if(cityVal=="nashik"){
  //   return "Nashik";
  // } else {
  //   if(Number(retailerid)>100000 || cityVal.includes("goa")) {
  //     return "Goa";
  //   } else {
  //     return "Mumbai";
  //   }
  // }
  return cityVal;
}

function formatPhone(phone,secondary_number){
  let phonestr=""+phone;
  if(secondary_number && secondary_number!="null"){
    phonestr+=","+secondary_number;
  }
  return phonestr;
}

function formatGstNumber(gst_number){
  return `GSTIN : ${gst_number}`;
}

function formatFssai(fssai){
  return `FSSAI : ${fssai}`;
}

function formatChequeDate(deliveredDate){
  let tomorrow = new Date(deliveredDate)
  tomorrow.setDate(tomorrow.getDate() + 12)

  let date=tomorrow.getDate();
  let month=tomorrow.getMonth()+1;
  let year=tomorrow.getFullYear();

  return `${date}/${month}/${year}`;
}

function formatDeliveryDate(dt){
  let dateList=dt.split("-");
  let year=dateList[2];
  let month=''+dateList[1];
  let date=''+dateList[0];

  if(date.length<2){
    date='0'+date;
  }

  if(month.length<2){
    month='0'+month;
  }

  return `${year}-${month}-${date}`;
}
 
const OrdersDetails = () => {
  let deliveryDate=sessionStorage.getItem("getDB")?sessionStorage.getItem("getDB"):"";
 
  let route_Number = sessionStorage.getItem("driverId")?sessionStorage.getItem("driverId"):""
  console.log('drivId')
  console.log(typeof(drivId))
  let deliveryDateList = deliveryDate.split("/");
  let finalDeliveryDate = deliveryDateList[deliveryDateList.length - 1];
  finalDeliveryDate=formatDeliveryDate(finalDeliveryDate);

    const [itemDetails, setItemDetails] = useState()
    const [updateditemDetails, setUpdatedItemDetails] = useState()
    const [orderdetails, setOrderdetails] = useState("")
    const [getBackDrop, setBackDrop] = useState(false)
    const [totalOrderQty, setTotalOrderQty] = useState("")
    const [amtExclGst, setAmtExclGst] = useState("")
    const [CGSTAmt, setCGSTAmt] = useState("")
    const [SGSTAmt, setSGSTAmt] = useState("")
    const [IGSTAmt, setIGSTAmt] = useState("")
    const [amtInclGst, setAmtInclGst] = useState("")
    const [replacedItem,setReplacedItem] = useState(JSON.parse(sessionStorage.getItem("replaced_item_details")));
    const [itemAmountErrorMessage,setItemAmountErrorMessage] = useState([]);
    const [gstChargeErrorMessage, setGstChargeErrorMessage] = useState([]);
    const [orderAmountErrorMessage,setOrderAmountErrorMessage] = useState([]);
    const [getPrimeUser, setPrimeUser] = useState(false);
    const [getPoNumber,setPoNumber] = useState("")
    const [getAmazonItemIdMap,setAmazonItemIdMap] = useState({})
    const [invoiceChequeMap, setInvoiceChequeMap] = useState("");
    const [invoiceSignMap, setInvoiceSignMap] = useState("");

    const delhiRetailerIds = [81286, 81287];
    const dataToPrint = useRef()
    const itemWeightRef = useRef([])


    toast.configure({
        autoClose: 4000,
        draggable: true,
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
    });
    const itemQtyDetails = JSON.parse(sessionStorage.getItem("item_qty_details"));
    let invoiceDate = new Date()
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    invoiceDate = `${invoiceDate.getDate()}-${monthNames[invoiceDate.getMonth()]}-${invoiceDate.getFullYear().toString().substr(2,2)}`

    let invoiceList = JSON.parse(sessionStorage.getItem("invoiceList")) || [];
    console.log(invoiceList);

    useEffect(() => {
      if (!sessionStorage.getItem("orderNumberToDisplay"))
          return;
      const orderNumberSelected = sessionStorage.getItem("orderNumberToDisplay").replace("_","-");
      setBackDrop(true)

      let invoiceObj = {};
      let invoicesignobj = {};
      for(let invoice of invoiceList) {
        let retailer_id = invoice.retailer_id;
        let cheque_allowed = invoice.cheque_allowed==1?"Y":"N";
        let signature_required = invoice.signature_required==1?"Y":"N";

        invoiceObj[retailer_id] = cheque_allowed;
        invoicesignobj[retailer_id] = signature_required;
      }
      

      let returnOrderNum=sessionStorage.getItem("orderNumberToDisplay");
      let orderKey = returnOrderNum.split("/").join("-");

      let driverOrdersData = localStorage.getItem("orderData")?JSON.parse(localStorage.getItem("orderData")):{};

      setBackDrop(true);

      let storeName="";

      Object.keys(driverOrdersData).map((key,index)=>{
        if(key.includes(orderKey)){
          storeName = driverOrdersData[key]['shop_name']?driverOrdersData[key]['shop_name']:"";
        }
      });

      if(storeName.includes("Amazon")) {
        Promise.all([
          fire.database().ref(`Order-PO/${orderKey}`).once("value"),
          fire.database().ref(`Amazon-ItemIDs`).once("value"),
        ]).then(([poSnap, amazonItemSnap]) => {
          let poObj = poSnap.val() || {};
          let amazonItemObj = amazonItemSnap.val() || {};

          let amazonItemMap = {};
          Object.keys(amazonItemObj).map((key,index) => {
            amazonItemMap[key] = amazonItemObj[key].id || "";
          })

          
          
          let returnOrderNum=sessionStorage.getItem("orderNumberToDisplay");
          let orderKey = returnOrderNum.split("/").join("-");

          let finalOrderData = [];
          let driverOrdersData = localStorage.getItem("orderData")?JSON.parse(localStorage.getItem("orderData")):{};
          console.log("driverOrdersData");
          console.log(driverOrdersData);

          let orderNumber = sessionStorage.getItem("orderNumberToDisplay");

          setBackDrop(false);

          let sumOfItems = 0; let sumAmtInclGst = 0; let sumIGSTAmt = 0; let sumSGSTAmt = 0; let sumCGSTAmt = 0;
          let bagStr = "";
          let bagSet = new Set();

          Object.keys(driverOrdersData).map((key,index)=>{
            if(key.includes(orderKey)){
              let ele = driverOrdersData[key];
              let mrp = ele['mrp']?ele['mrp']:"";
              let netOrderAmt=ele['total_amt'];
              ele['net_order_amt']=netOrderAmt?Number(netOrderAmt):0;
              let bagId = ele['bag_id'] || "";
              if(bagId && !bagSet.has(bagId)){
                bagStr += `${bagId},`;
                bagSet.add(bagId);
              }

              ele['total_amt'] = mrp;
              ele['original_total_amt']=mrp;

              let oldGstCharge=ele.gst_charge;
              ele['old_gst_charge']=oldGstCharge;

              let order_qty=ele['order_qty']?Number(ele['order_qty']):0;
              ele['original_order_qty']=order_qty;
              let item_quantity=0;
              if(ele.hasOwnProperty("item_delivered_qty")) {
                item_quantity=Number(ele['item_delivered_qty']);
              } else if(ele.hasOwnProperty("item_modified_qty") && ele['item_modified_qty']!=null) {
                item_quantity=Number(ele['item_modified_qty']);
              } else {
                item_quantity=order_qty;
              }

              ele['item_quantity']=item_quantity;

              let dealer_price = Number(ele.dealer_price) || 0;
              if(poObj.PO) {
                dealer_price = Number(dealer_price).toFixed(1);
                dealer_price = Number(dealer_price);
              }
              let gst_per = Number(ele.gst_per) || 0;
              let gstCharge = (dealer_price * order_qty) * (gst_per/100);
              ele['original_gst_charge']=gstCharge;

              // quantity modified case
              if(order_qty!=item_quantity){
                ele.total_amt = Number(ele.total_amt) * item_quantity / order_qty;
                ele.old_gst_charge = Number(ele.old_gst_charge) * item_quantity / order_qty;
                // ele.original_gst_charge = Number(ele.original_gst_charge) * item_quantity / order_qty;
              }
              let initial_gst_charge = Number(ele.gst_charge) || 0;
              ele.initial_gst_charge = initial_gst_charge;
              ele.gst_charge = ele.hasOwnProperty("gst_per")?((dealer_price * item_quantity) * (gst_per/100)):initial_gst_charge;

              if(isNumber(ele.old_gst_charge) == 0 && getItemsWeight(ele.weight,ele.item_disc,1) <=25 && (ele.category == "FLOURS/SOOJI" || ele.category =="GRAINS/POHA" || ele.category == "RICE")) {
                ele['old_gst_charge'] = ((Number(ele.dealer_price)* item_quantity)+Number(ele.apmc_charge)+Number(ele.transport_charge)) * 5/105
              }

              if(item_quantity != 0){
                sumOfItems += 1

                if(ele.hasOwnProperty("gst_per")) {
                  let calcTotalAmt = (dealer_price * item_quantity) + ele.gst_charge;
                  sumAmtInclGst += Number(calcTotalAmt)
                  sumIGSTAmt += ele.gst_charge?Number(ele.gst_charge):0;
                  sumCGSTAmt += isNumber(ele.gst_charge)/ 2
                  sumSGSTAmt += isNumber(ele.gst_charge)/ 2
                } else {
                  sumAmtInclGst += Number(ele.total_amt)
                  sumIGSTAmt += ele.old_gst_charge?Number(ele.old_gst_charge):0;
                  sumCGSTAmt += isNumber(ele.old_gst_charge)/ 2
                  sumSGSTAmt += isNumber(ele.old_gst_charge)/ 2
                }
              }

              // if(item_quantity>0){
                finalOrderData.push(ele);
              // }
            }
          });
          if(bagStr) {
            bagStr = bagStr.slice(0,-1);
          }

          let net_order_amt=0;
          let order_disc=0;
          let cashback_redeemed=0;
          let sumTotalAmt=0;

          let orderAmountErrors=[];
          let itemAmountErrors=[];
          let gstChargeErrors=[];
          let newFinalOrderData=[];

          for(let i=0;i<finalOrderData.length;i++){
            let orderele=finalOrderData[i];
            orderele['item_mrp_sum']=sumAmtInclGst;
            net_order_amt=finalOrderData[i]['net_order_amt']?Number(finalOrderData[i]['net_order_amt']):0;
            order_disc=finalOrderData[i]['order_disc']?Number(finalOrderData[i]['order_disc']):0;
            cashback_redeemed=finalOrderData[i]['cashback_redeemed']?Number(finalOrderData[i]['cashback_redeemed']):0;

            //item level
            let weight=finalOrderData[i]['weight'];
            let category=finalOrderData[i]['category'];
            let item_disc=finalOrderData[i]['item_disc'];
            let total_amt=finalOrderData[i]['original_total_amt']?Number(finalOrderData[i]['original_total_amt']):0;
            // let total_amt=finalOrderData[i]['total_amt']?Number(finalOrderData[i]['total_amt']):0;
            let dealer_price=finalOrderData[i]['dealer_price']?Number(finalOrderData[i]['dealer_price']):0;
            let order_qty=finalOrderData[i]['original_order_qty']?Number(finalOrderData[i]['original_order_qty']):0;
            let transport_charge=finalOrderData[i]['transport_charge']?Number(finalOrderData[i]['transport_charge']):0;
            let apmc_charge=finalOrderData[i]['apmc_charge']?Number(finalOrderData[i]['apmc_charge']):0;
            let original_gst_charge=finalOrderData[i]['original_gst_charge']?Number(finalOrderData[i]['original_gst_charge']):0;
            let initial_gst_charge = Number(finalOrderData[i]['initial_gst_charge']) || 0;

            if(poObj.PO) {
              dealer_price = Number(dealer_price).toFixed(1);
              dealer_price = Number(dealer_price);
            }

            let calcItemValue=(dealer_price * order_qty) + transport_charge + apmc_charge + initial_gst_charge;
            calcItemValue=calcItemValue.toFixed(2);
            calcItemValue=Number(calcItemValue);

            let itemValueDiff=calcItemValue-total_amt;
            if(calcItemValue!=total_amt && (itemValueDiff>10 || itemValueDiff<-10)){
              itemAmountErrors.push(`${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(2)}`)
            }

            sumTotalAmt=sumTotalAmt+total_amt;



            if(Math.abs(initial_gst_charge-original_gst_charge)>1 && orderele.hasOwnProperty("gst_per")) {
              gstChargeErrors.push(`${item_disc} has gst charge discrepancy with difference of ${(initial_gst_charge-original_gst_charge).toFixed(2)}`);
            }

            if(bagStr) {
              orderele['bagdetails'] = bagStr;
              orderele['numbags'] = bagStr.split(",").length;
            }

            let item_quantity=finalOrderData[i]['item_quantity'];
            // orderele['calc_total_amt'] = (dealer_price * item_quantity) + original_gst_charge;
            orderele['calc_total_amt'] = (dealer_price * item_quantity) + finalOrderData[i]['gst_charge'];
            if(item_quantity>0){
              newFinalOrderData.push(orderele);
            }
          }

          let calcOrderValue=sumTotalAmt-order_disc-cashback_redeemed;
          calcOrderValue=calcOrderValue.toFixed(2);
          calcOrderValue=Number(calcOrderValue);

          let orderValueDiff=calcOrderValue-net_order_amt;
          if(net_order_amt!=calcOrderValue && !orderNumber.includes("R") && (orderValueDiff>10 || orderValueDiff<-10)){
            orderAmountErrors.push(`Order value discrepancy with difference of ${orderValueDiff.toFixed(2)}`);
          }

          setItemAmountErrorMessage(itemAmountErrors);
          setOrderAmountErrorMessage(orderAmountErrors);
          setGstChargeErrorMessage(gstChargeErrors);

          
          setIGSTAmt(sumIGSTAmt)
          setCGSTAmt(sumCGSTAmt)
          setSGSTAmt(sumSGSTAmt)
          
           
        const sortedNewFinalOrderData = newFinalOrderData.sort((a, b) => {
          const itemDiscA = a.item_disc.toUpperCase();
          const itemDiscB = b.item_disc.toUpperCase(); 
        
          if (itemDiscA < itemDiscB) {
            return -1;
          }
          if (itemDiscA > itemDiscB) {
            return 1;
          }
          return 0;
        });


        console.log(newFinalOrderData);
        console.log(sortedNewFinalOrderData);
          setItemDetails(sortedNewFinalOrderData);



          if(poObj.PO) {
            setAmtExclGst((sumAmtInclGst - sumIGSTAmt).toFixed(1))
            setAmtInclGst(sumAmtInclGst.toFixed(1))
          } else {
            setAmtExclGst((sumAmtInclGst - sumIGSTAmt).toFixed(2))
            setAmtInclGst(sumAmtInclGst.toFixed(2))
          }

          setPoNumber(poObj.PO || "");
          setAmazonItemIdMap(amazonItemMap);
          setInvoiceChequeMap(invoiceObj);
          setInvoiceSignMap(invoicesignobj);

          console.log("newFinalOrderData >>>>>>>>>>>>>>");
          console.log(newFinalOrderData);

          if(newFinalOrderData.length>0){
            setOrderdetails(newFinalOrderData[0])
          }
        });
      } else {
        setBackDrop(false);
        setPoNumber("");
        setAmazonItemIdMap({});
        setInvoiceChequeMap(invoiceObj);
        setInvoiceSignMap(invoicesignobj);
      }
    }, [])

    useEffect(() => {
        if (!sessionStorage.getItem("orderNumberToDisplay"))
            return;
        const orderNumberSelected = sessionStorage.getItem("orderNumberToDisplay").replace("_","-");
        setBackDrop(true)

        let returnOrderNum=sessionStorage.getItem("orderNumberToDisplay");
        let orderKey = returnOrderNum.split("/").join("-");

        let finalOrderData = [];
        let driverOrdersData = localStorage.getItem("orderData")?JSON.parse(localStorage.getItem("orderData")):{};
        console.log("driverOrdersData");
        console.log(driverOrdersData);

        let orderNumber = sessionStorage.getItem("orderNumberToDisplay");

        setBackDrop(false);

        let sumOfItems = 0; let sumAmtInclGst = 0; let sumIGSTAmt = 0; let sumSGSTAmt = 0; let sumCGSTAmt = 0;
        let bagStr = "";
        let bagSet = new Set();

        Object.keys(driverOrdersData).map((key,index)=>{
          if(key.includes(orderKey)){
            let ele = driverOrdersData[key];
            let mrp = ele['mrp']?ele['mrp']:"";
            let netOrderAmt=ele['total_amt'];
            ele['net_order_amt']=netOrderAmt?Number(netOrderAmt):0;
            let bagId = ele['bag_id'] || "";
            if(bagId && !bagSet.has(bagId)){
              bagStr += `${bagId},`;
              bagSet.add(bagId);
            }

            ele['total_amt'] = mrp;
            ele['original_total_amt']=mrp;

            let oldGstCharge=ele.gst_charge;
            ele['old_gst_charge']=oldGstCharge;

            let order_qty=ele['order_qty']?Number(ele['order_qty']):0;
            ele['original_order_qty']=order_qty;
            let item_quantity=0;
            if(ele.hasOwnProperty("item_delivered_qty")) {
              item_quantity=Number(ele['item_delivered_qty']);
            } else if(ele.hasOwnProperty("item_modified_qty") && ele['item_modified_qty']!=null) {
              item_quantity=Number(ele['item_modified_qty']);
            } else {
              item_quantity=order_qty;
            }

            ele['item_quantity']=item_quantity;

            let dealer_price = Number(ele.dealer_price) || 0;
            if(getPoNumber) {
              dealer_price = Number(dealer_price).toFixed(1);
              dealer_price = Number(dealer_price);
            }
            let gst_per = Number(ele.gst_per) || 0;
            let gstCharge = (dealer_price * order_qty) * (gst_per/100);
            ele['original_gst_charge']=gstCharge;

            // quantity modified case
            if(order_qty!=item_quantity){
              ele.total_amt = Number(ele.total_amt) * item_quantity / order_qty;
              ele.old_gst_charge = Number(ele.old_gst_charge) * item_quantity / order_qty;
              // ele.original_gst_charge = Number(ele.original_gst_charge) * item_quantity / order_qty;
            }
            let initial_gst_charge = Number(ele.gst_charge) || 0;
            ele.initial_gst_charge = initial_gst_charge;
            ele.gst_charge = ele.hasOwnProperty("gst_per")?((dealer_price * item_quantity) * (gst_per/100)):initial_gst_charge;

            if(isNumber(ele.old_gst_charge) == 0 && getItemsWeight(ele.weight,ele.item_disc,1) <=25 && (ele.category == "FLOURS/SOOJI" || ele.category =="GRAINS/POHA" || ele.category == "RICE")) {
              ele['old_gst_charge'] = ((Number(ele.dealer_price)* item_quantity)+Number(ele.apmc_charge)+Number(ele.transport_charge)) * 5/105
            }

            if(item_quantity != 0){
              sumOfItems += 1

              if(ele.hasOwnProperty("gst_per")) {
                let calcTotalAmt = (dealer_price * item_quantity) + ele.gst_charge;
                sumAmtInclGst += Number(calcTotalAmt)
                sumIGSTAmt += ele.gst_charge?Number(ele.gst_charge):0;
                sumCGSTAmt += isNumber(ele.gst_charge)/ 2
                sumSGSTAmt += isNumber(ele.gst_charge)/ 2
              } else {
                sumAmtInclGst += Number(ele.total_amt)
                sumIGSTAmt += ele.old_gst_charge?Number(ele.old_gst_charge):0;
                sumCGSTAmt += isNumber(ele.old_gst_charge)/ 2
                sumSGSTAmt += isNumber(ele.old_gst_charge)/ 2
              }
            }

            // if(item_quantity>0){
              finalOrderData.push(ele);
            // }
          }
        });
        if(bagStr) {
          bagStr = bagStr.slice(0,-1);
        }

        let net_order_amt=0;
        let order_disc=0;
        let cashback_redeemed=0;
        let sumTotalAmt=0;

        let orderAmountErrors=[];
        let itemAmountErrors=[];
        let gstChargeErrors=[];
        let newFinalOrderData=[];

        for(let i=0;i<finalOrderData.length;i++){
          let orderele=finalOrderData[i];
          orderele['item_mrp_sum']=sumAmtInclGst;
          net_order_amt=finalOrderData[i]['net_order_amt']?Number(finalOrderData[i]['net_order_amt']):0;
          order_disc=finalOrderData[i]['order_disc']?Number(finalOrderData[i]['order_disc']):0;
          cashback_redeemed=finalOrderData[i]['cashback_redeemed']?Number(finalOrderData[i]['cashback_redeemed']):0;

          //item level
          let weight=finalOrderData[i]['weight'];
          let category=finalOrderData[i]['category'];
          let item_disc=finalOrderData[i]['item_disc'];
          let total_amt=finalOrderData[i]['original_total_amt']?Number(finalOrderData[i]['original_total_amt']):0;
          // let total_amt=finalOrderData[i]['total_amt']?Number(finalOrderData[i]['total_amt']):0;
          let dealer_price=finalOrderData[i]['dealer_price']?Number(finalOrderData[i]['dealer_price']):0;
          let order_qty=finalOrderData[i]['original_order_qty']?Number(finalOrderData[i]['original_order_qty']):0;
          let transport_charge=finalOrderData[i]['transport_charge']?Number(finalOrderData[i]['transport_charge']):0;
          let apmc_charge=finalOrderData[i]['apmc_charge']?Number(finalOrderData[i]['apmc_charge']):0;
          let original_gst_charge=finalOrderData[i]['original_gst_charge']?Number(finalOrderData[i]['original_gst_charge']):0;
          let initial_gst_charge = Number(finalOrderData[i]['initial_gst_charge']) || 0;

          if(getPoNumber) {
            dealer_price = Number(dealer_price).toFixed(1);
            dealer_price = Number(dealer_price);
          }

          let calcItemValue=(dealer_price * order_qty) + transport_charge + apmc_charge + initial_gst_charge;
          calcItemValue=calcItemValue.toFixed(2);
          calcItemValue=Number(calcItemValue);

          let itemValueDiff=calcItemValue-total_amt;
          if(calcItemValue!=total_amt && (itemValueDiff>10 || itemValueDiff<-10)){
            itemAmountErrors.push(`${item_disc} has Item Value discrepancy with difference of ${itemValueDiff.toFixed(2)}`)
          }

          sumTotalAmt=sumTotalAmt+total_amt;



          if(Math.abs(initial_gst_charge-original_gst_charge)>1 && orderele.hasOwnProperty("gst_per")) {
            gstChargeErrors.push(`${item_disc} has gst charge discrepancy with difference of ${(initial_gst_charge-original_gst_charge).toFixed(2)}`);
          }

          if(bagStr) {
            orderele['bagdetails'] = bagStr;
            orderele['numbags'] = bagStr.split(",").length;
          }

          let item_quantity=finalOrderData[i]['item_quantity'];
          // orderele['calc_total_amt'] = (dealer_price * item_quantity) + original_gst_charge;
          orderele['calc_total_amt'] = (dealer_price * item_quantity) + finalOrderData[i]['gst_charge'];
          if(item_quantity>0){
            newFinalOrderData.push(orderele);
          }
        }

        let calcOrderValue=sumTotalAmt-order_disc-cashback_redeemed;
        calcOrderValue=calcOrderValue.toFixed(2);
        calcOrderValue=Number(calcOrderValue);

        let orderValueDiff=calcOrderValue-net_order_amt;
        if(net_order_amt!=calcOrderValue && !orderNumber.includes("R") && (orderValueDiff>10 || orderValueDiff<-10)){
          orderAmountErrors.push(`Order value discrepancy with difference of ${orderValueDiff.toFixed(2)}`);
        }

        setItemAmountErrorMessage(itemAmountErrors);
        setOrderAmountErrorMessage(orderAmountErrors);
        setGstChargeErrorMessage(gstChargeErrors);

        
        setIGSTAmt(sumIGSTAmt)
        setCGSTAmt(sumCGSTAmt)
        setSGSTAmt(sumSGSTAmt)
        
        const sortedNewFinalOrderData = newFinalOrderData.sort((a, b) => {
          const itemDiscA = a.item_disc.toUpperCase(); // Convert to uppercase for case-insensitive sorting
          const itemDiscB = b.item_disc.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        
          if (itemDiscA < itemDiscB) {
            return -1;
          }
          if (itemDiscA > itemDiscB) {
            return 1;
          }
          return 0;
        });

        console.log(newFinalOrderData);
        console.log(sortedNewFinalOrderData);
        setItemDetails(sortedNewFinalOrderData);

        if(getPoNumber) {
          setAmtExclGst((sumAmtInclGst - sumIGSTAmt).toFixed(1))
          setAmtInclGst(sumAmtInclGst.toFixed(1))
        } else {
          setAmtExclGst((sumAmtInclGst - sumIGSTAmt).toFixed(2))
          setAmtInclGst(sumAmtInclGst.toFixed(2))
        }

        console.log("newFinalOrderData ============== > ");
        console.log(newFinalOrderData);

        if(newFinalOrderData.length>0){
          setOrderdetails(newFinalOrderData[0])
        }
    }, [])

    const isDelhiWarehouse = (orderdetails) => { 
      return (
        delhiRetailerIds.includes((orderdetails.retailer_id)) ||
        route_Number.startsWith("F4")
      );
    };

    const getItemsWeight = (indItemWeight, itemDesc, quantityOrdered) => {

        if (!indItemWeight)
            return 1
        let itemWeight = Number(indItemWeight.replace(/\D/g, ''));
        if (indItemWeight.includes("gm") || indItemWeight.includes("ml")) {
            itemWeight = itemWeight / 1000;
        }

        if ((indItemWeight.includes("1 pc") || indItemWeight.includes("1 Pc")) && itemDesc.includes("gm")) {
            let itemWtExtract = itemDesc.split("gm")
            let itemWtExtract2 = itemWtExtract[0].split("-")
            itemWeight = parseFloat(itemWtExtract2[itemWtExtract2.length-1].replace(/[^0-9]/g,''))/1000;
        }
        if(itemWeight <=0){
            itemWeight = 1;
        }
        indItemWeight = itemWeight * Number(quantityOrdered)
        return `${indItemWeight.toFixed(2)}`
    }

    const isAlreadyPaid = (payment_mode,credit_amt) => {
      return payment_mode && ((payment_mode.includes("Credit") && Number(credit_amt) > 0 )|| (payment_mode.includes("RazorPay")))
    }

    const isNumber = (item) => {
        return isNaN(Number(item))?"0":Number(item).toFixed(2)
    }

    const isAmazonNumber = (item) => {
        return isNaN(Number(item))?"0":Number(item).toFixed(1)
    }

    const getOrderDiscount = () => {
      return orderdetails["revised_order_disc"]?orderdetails["revised_order_disc"]:orderdetails["order_disc"]?orderdetails["order_disc"]:0;
    }

    const calcSuperKreditAmt = () => {
      let revised_order_amt = orderdetails["item_mrp_sum"]?Number(orderdetails["item_mrp_sum"]):0;
      let revised_order_disc = orderdetails["revised_order_disc"]?Number(orderdetails["revised_order_disc"]):orderdetails["order_disc"]?Number(orderdetails["order_disc"]):0;
      let cashback_redeemed = orderdetails["cashback_redeemed"]?Number(orderdetails["cashback_redeemed"]):0;

      return (revised_order_amt-revised_order_disc-cashback_redeemed).toFixed(2);
    }

    const getSavedAmount = (updateditemList) => {
      let savedAmt = 0;
      updateditemList = updateditemList || [];

      if (updateditemList.length > 0) {
        for (let updateditem of updateditemList) {
          let mrp = Number(updateditem.total_amt) || 0;
          let market_price = Number(updateditem.market_price) || 0;
          let qty = Number(updateditem.order_qty) || 0;

          savedAmt += market_price * qty - mrp;
        }
      }

      return savedAmt.toFixed(2);
    };


    let isIntraStateGST = true;

    if(orderdetails.gst_number){
      if((['Dhansar','Bhiwandi','Wadala','Mira Road'].includes(orderdetails.city) && !orderdetails.gst_number.startsWith('27')) || (['Budhpur','Delhi'].includes(orderdetails.city) && !orderdetails.gst_number.startsWith('07'))){
        isIntraStateGST = false
      }
    }

    const calculateItemTotalAmount = (item, isMHGSTNumber) => {
      const amount = item.hasOwnProperty("gst_per") ? Number(item.dealer_price) * Number(item.item_quantity) : Number(item.total_amt) - Number(item.old_gst_charge);
      const gstCharge = item.hasOwnProperty("gst_per") ? Number(item.gst_charge) : Number(item.old_gst_charge);
      const cgst = isMHGSTNumber ? gstCharge / 2 : 0;
      const sgst = isMHGSTNumber ? gstCharge / 2 : 0;
  
      return (amount + cgst + sgst).toFixed(2);
    };
    
    return (
        <>
            <div style={{ textAlign: "center", width: "1000px", margin: "auto", border: "2px solid black" }}>
                {(itemAmountErrorMessage.length==0 && gstChargeErrorMessage.length==0 && orderAmountErrorMessage.length==0) && 
                  <ReactToPrint
                      trigger={() => <a href="#" style={{ 'float': 'right' }} ><FontAwesomeIcon size="3x" icon={faPrint} /></a>}
                      content={() => { return dataToPrint.current }}
                      pageStyle={{ margin: "50px", border: "2px" }}
                  />}

                {itemAmountErrorMessage.map((itemError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{itemError}</div>
                    )
                })}

                {gstChargeErrorMessage.map((itemError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{itemError}</div>
                    )
                })}

                {orderAmountErrorMessage.map((orderError, key) => {
                    return (
                        <div style={{ display: "flex", backgroundColor: "#FF6347", padding: "10px", margin: "15px", borderRadius: "5px" }}>{orderError}</div>
                    )
                })}

                <div ref={dataToPrint}>
                    <Table><tbody><tr>
                        {(Number(orderdetails.retailer_id) > 0 && !(orderdetails.store_series=="CP"
                         || orderdetails.store_series=="M" || orderdetails.store_series=="F" || orderdetails.store_series=="O"
                        || orderdetails.store_series=="FD" || orderdetails.store_series=="MT")) && <td><img src={szlogo} alt={"superzopLogo"} style={{ width: "250px", height: "80px",margin: "20px"  }} /></td>}

                        {(Number(orderdetails.customer_id) > 0 || orderdetails.store_series=="CP"
                         || orderdetails.store_series=="M" || orderdetails.store_series=="F" || orderdetails.store_series=="O"
                        || orderdetails.store_series=="FD" || orderdetails.store_series=="MT") && <td><img src={khetika} alt={"KhetikaLogo"} style={{ width: "300px", height: "100px",margin: "20px"  }} /></td>}

                        {orderdetails.prime_user=="Y" && <td><img src={superprimelogo} alt={"superprimeLogo"} style={{  width: "150px", height: "70px",margin: "20px"  }} /></td>}
                        {orderdetails.is_priority=="Y" && <td><img src={shippingpriority} alt={"priority shipping"} style={{  width: "200px", height: "80px",margin: "20px" }} /></td>}
                        {orderdetails.city && (
                          <td style={{ width: "400px", textAlign: "left" }}>
                            <b>Retranz Infolabs Private Limited</b>
                            <br />
                            
                             {isDelhiWarehouse(orderdetails) ? (
                              <>
                              Khasra No. 241, Main G.T. Karnal Road, Village Budhpur, Bijapur, Extended Lal Dora, Delhi - 110036
                              </>
                              ) : (
                              <>
                            Warehouse No 7, Santosh Warehousing Complex Village Dhansar, Navi Mumbai – 410210
                              </>
                            )}
                           <br />
                  Phone : 9901044177<br />
                  Email : support@superzop.com<br />
                  GSTIN : {isDelhiWarehouse(orderdetails) ? "07AAHCR2573F1ZC" : "27AAHCR2573F1ZA"} <br />
                  FSSAI : {isDelhiWarehouse(orderdetails) ? "13324998000238" : "11522998000504"}
                  </td>
                        )
                }
                    </tr>
                    </tbody>
                    </Table>
                    <div><div style={{ borderTop: "2px solid black", borderBottom: "2px solid black", textAlign: "center" }}>
                    {(Number(orderdetails.retailer_id) > 0 && !(orderdetails.store_series=="O")) ?  <b style={{ fontSize: "20px" }}>RETAILER TAX INVOICE</b> :
                    <b style={{ fontSize: "20px" }}>TAX INVOICE</b>}
                    </div>
                        <Table>
                          <tbody>
                            <tr>
                                <td style={{ textAlign: "left" }}><div>
                                    <b>{orderdetails.shop_name}</b><br />
                                    {orderdetails.address1}<br />
                                    {orderdetails.address2}<br />
                                    {orderdetails.area}<br />
                                    {orderdetails.city && orderdetails.retailer_id && convertCity(orderdetails.actual_city,orderdetails.retailer_id)} - {orderdetails.pincode}<br />
                                    ✆{formatPhone(orderdetails.phone,orderdetails.secondary_number)}<br />
                                    {orderdetails.gst_number?formatGstNumber(orderdetails.gst_number):null}<br />
                                    {orderdetails.fssai_document_number?formatFssai(orderdetails.fssai_document_number):null}<br />
                                </div></td>
                                <td style={{ width: "400px", textAlign: "left" }}><div><Table borderless style={{ cellSpacing: "0px" }}><tbody>
                                    {getPoNumber &&
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>PO No</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{getPoNumber}</td>
                                    </tr>}
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Retailer ID</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.retailer_id}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px", cellPadding: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Number</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_number}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Invoice Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{formatDate(finalDeliveryDate)}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Order Date</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.order_date?formatDate(orderdetails.order_date):""}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Items</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{itemDetails?itemDetails.length:""}</td>
                                    </tr>
                                    <tr style={{ cellSpacing: "0px" }}>
                                      <td style={{ padding: "0px" }}>No of Pcs</td>
                                      <td style={{ textAlign: "right", padding: "0px" }}>
                                          {itemDetails ? itemDetails.reduce((sum, item) => sum + item.item_quantity, 0) : ""}
                                      </td>
                                    </tr>
                                    {orderdetails.numbags && orderdetails.numbags!=0 &&
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>No of Loose Bags</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.numbags}</td>
                                    </tr>}
                                    {orderdetails.bagdetails && orderdetails.bagdetails!='null' &&
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Loose Bag Id</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.bagdetails && orderdetails.bagdetails!='null'?orderdetails.bagdetails:""}</td>
                                    </tr>}
                                    <tr style={{ cellSpacing: "0px" }}>
                                        <td style={{ padding: "0px" }}>Sales Agent</td>
                                        <td style={{ textAlign: "right", padding: "0px", }}>{orderdetails.agent_id}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                                </div>
                                </td>
                            </tr>
                          </tbody>
                        </Table>
                    </div>
                    <Table>
                        <thead style={{ borderTop: "2px solid black", borderBottom: "2px solid black" }}>
                            <tr>
                                <td style={{ paddingRight: "10px" }}><b>Sl No</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Item description</b></td>
                                {getPoNumber && <td style={{ paddingRight: "10px" }}><b>ASIN</b></td>}
                                <td style={{ paddingRight: "10px" }}><b>HSN Code</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Qty/No</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Total Weight</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Dealer Price (per pack)</b></td>
                                <td style={{ paddingRight: "10px" }}><b>Amount(Rs.)</b></td>

                                {!isIntraStateGST && <td style={{ paddingRight: "10px" }}><b>IGST Amount</b></td>}
                                {isIntraStateGST && <td style={{ paddingRight: "10px" }}><b>CGST Amount</b></td>}
                                {isIntraStateGST && <td style={{ paddingRight: "10px" }}><b>SGST Amount</b></td>}
                                <td style={{ paddingRight: "10px" }}><b>Total Amount</b></td>
                            </tr>
                        </thead>
                        <tbody style={{ padding: "0" }}>
                            {(itemDetails) ? Object.keys(itemDetails).map((items, index) => (
                                <tr>
                                    <td style={{ padding: "2px", border: "none" }}>{index + 1}</td>
                                    <td style={{ width: "500px", padding: "2px", textAlign: "left", border: "none", fontSize: "16px" }}>{itemDetails[items].item_disc}</td>
                                    {getPoNumber && <td style={{ padding: "2px", border: "none" }}>{getAmazonItemIdMap[itemDetails[items].item_id] || ""}</td>}
                                    <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hsn_code || ""}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{Number(itemDetails[items].item_quantity).toFixed(2)}</td>
                                    <td style={{ padding: "2px", border: "none" }} ref={(value => itemWeightRef.current[index] = value)}> {getItemsWeight(itemDetails[items].weight,itemDetails[items].item_disc, itemDetails[items].item_quantity)}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{getPoNumber?(itemDetails[items].hasOwnProperty("gst_per")?isAmazonNumber(Number(itemDetails[items].dealer_price)):isAmazonNumber((Number(itemDetails[items].total_amt) -  Number(itemDetails[items].old_gst_charge))/Number(itemDetails[items].item_quantity))):itemDetails[items].hasOwnProperty("gst_per")?isNumber(Number(itemDetails[items].dealer_price)):isNumber((Number(itemDetails[items].total_amt) -  Number(itemDetails[items].old_gst_charge))/Number(itemDetails[items].item_quantity))}</td>
                                    <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hasOwnProperty("gst_per")?isNumber(Number(itemDetails[items].dealer_price) * Number(itemDetails[items].item_quantity)):isNumber(Number(itemDetails[items].total_amt) - Number(itemDetails[items].old_gst_charge))}</td>

                                    {!isIntraStateGST && <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hasOwnProperty("gst_per")?isNumber(itemDetails[items].gst_charge):isNumber(itemDetails[items].old_gst_charge)}{itemDetails[items].hasOwnProperty("gst_per")&&Number(itemDetails[items]["gst_per"]>0)&&<small>{"["+Number(itemDetails[items].gst_per)+"%]"}</small>}</td>}
                                    {isIntraStateGST && <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hasOwnProperty("gst_per")?isNumber(itemDetails[items].gst_charge / 2):isNumber(itemDetails[items].old_gst_charge / 2)}{itemDetails[items].hasOwnProperty("gst_per")&&Number(itemDetails[items]["gst_per"]>0)&&<small>{"["+Number(itemDetails[items].gst_per)/2+"%]"}</small>}</td>}
                                    {isIntraStateGST && <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hasOwnProperty("gst_per")?isNumber(itemDetails[items].gst_charge / 2):isNumber(itemDetails[items].old_gst_charge / 2)}{itemDetails[items].hasOwnProperty("gst_per")&&Number(itemDetails[items]["gst_per"]>0)&&<small>{"["+Number(itemDetails[items].gst_per)/2+"%]"}</small>}</td>}
                                    <td style={{ padding: "2px", border: "none" }}>{itemDetails[items].hasOwnProperty("gst_per")?isNumber(itemDetails[items].calc_total_amt):isNumber(itemDetails[items].total_amt)}</td>
                                    {/* <td style={{ padding: "2px", border: "none" }}>{calculateItemTotalAmount(itemDetails[items], isMHGSTNumber)}</td> */}
                                </tr>
                            )) : ""}
                        </tbody>
                    </Table>

                    <div style={{ borderTop: "1px solid black" }}>
                        <Table ><tbody><tr>
                            <td style={{ textAlign: "left" }}><div>
                                <br /><br /><br />
                                <div style={{ textAlign: "center" }}><i>I/We hereby certify that the goods mentioned in the invoice are warranted
                                to be of the same nature<br /><br />
                                Certified that the particulars given above are true and correct</i><br /><br />
                                <b><i>Goods Once Sold Will Not Be Taken Back.</i></b><br />
                                Subject to Mumbai Jurisdiction<br />
                                <i><b>E&OE</b></i><br />
                                <h3><Badge pill variant="success" >APMC Paid</Badge></h3>
                                  {Number(orderdetails["retailer_id"]) == 0 && (
                                    <div>
                                      <b>
                                        You have saved Rs{" "}
                                        {getSavedAmount(
                                          itemDetails
                                        )}{" "}
                                        by ordering on Khetika
                                      </b>
                                    </div>
                                  )}
                                  {Number(orderdetails["retailer_id"]) == 0 && (<><br />
                                    <br />
                                    <i>
                                      <b>
                                        *For any queries please contact
                                        support@superzop.com or 8010173173
                                      </b>
                                    </i></>)}
                                </div>
                                <div style={{textAlign:"center"}}>{isAlreadyPaid(orderdetails["order_payment_mode"],orderdetails["credit_amt"]) ? (orderdetails["order_payment_mode"]=='Rupifi Credits')?<h1><Badge pill variant="primary">Paid - Rupifi</Badge></h1>:(orderdetails["order_payment_mode"]=='Finaleap Credits')?<h1><Badge pill variant="primary">Paid - Finaleap</Badge></h1>:(orderdetails["order_payment_mode"]=='RazorPay')?<h1><Badge pill variant="primary">Paid - RazorPay</Badge></h1>:(orderdetails["order_payment_mode"]=='Internal_Credit')?<h1><Badge pill variant="primary">Paid - SuperKredit</Badge></h1>:"":(invoiceChequeMap[orderdetails.retailer_id]=="Y"?<h1><Badge pill variant="primary">Cheque Allowed</Badge></h1>:<div><h1><Badge pill variant="primary">Cash Only</Badge></h1><div style={{ textAlign: "center" }}>We request you to please not handover cash to the sales officers. Please transfer the amount only to authorized online channel communicated to you with the name of the company Retranz Infolabs Private Limited.</div></div>)}</div>
                                <div style={{textAlign:"center"}}>{(orderdetails["order_payment_mode"]=='Internal_Credit' || invoiceChequeMap[orderdetails.retailer_id]=="Y")?<h1><Badge pill variant="primary">Cheque Date - {formatChequeDate(finalDeliveryDate)}</Badge></h1>:null}</div>
                                {invoiceSignMap[orderdetails.retailer_id]=="Y" ? <ul><li>        </li><li>Signature </li></ul>: null}
                            </div></td>
                            <td style={{ width: "400px", textAlign: "right", cellSpacing: "0px" }}><div>
                                <Table bordered style={{ border: "2px solid black" }}>
                                  <tbody>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>Amount (Exl GST)</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{amtExclGst && amtExclGst}</td>
                                    </tr>
                                    {isIntraStateGST && <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>CGST Amount</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{CGSTAmt && CGSTAmt.toFixed(2)}</td>
                                    </tr>}
                                    {isIntraStateGST && <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>SGST Amount</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{SGSTAmt && SGSTAmt.toFixed(2)}</td>
                                    </tr>}
                                    {!isIntraStateGST && <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>IGST Amount</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{IGSTAmt && IGSTAmt.toFixed(2)}</td>
                                    </tr>}
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}><b>Total Amount</b></td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{amtInclGst}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>Discount/Coupon</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{orderdetails["revised_order_disc"]?orderdetails["revised_order_disc"]:orderdetails["order_disc"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>SZ Wallet Paid</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>{orderdetails["cashback_redeemed"]}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>SuperKredit/RazorPay</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>
    {orderdetails["order_payment_mode"]=="Rupifi Credits"?0:((orderdetails["order_payment_mode"]=="Internal_Credit")?
    (orderdetails["status"]=="Delivered"?orderdetails["credit_amt"]:calcSuperKreditAmt()):isAlreadyPaid(orderdetails["order_payment_mode"],orderdetails["credit_amt"])?orderdetails['net_order_amt']:0)}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}>Rupifi/Finaleap</td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}>
    {(orderdetails["order_payment_mode"]=="Rupifi Credits" || orderdetails["order_payment_mode"]=="Finaleap Credits")?
    (orderdetails["status"]=="Delivered"?orderdetails["credit_amt"]:calcSuperKreditAmt()):0}</td>
                                    </tr>
                                    <tr style={{ border: "2px solid black" }}>
                                        <td style={{ border: "2px solid black", textAlign: "left" }}><b>Net Amount Payable</b></td>
                                        <td style={{ border: "2px solid black", textAlign: "right" }}><b>{isAlreadyPaid(orderdetails["order_payment_mode"],orderdetails["credit_amt"])?0:Math.round((Number(amtInclGst) - Number(Number(getOrderDiscount()) + (Number(orderdetails["cashback_redeemed"]) || 0)))).toFixed(2)}</b></td>
                                    </tr>
                                  </tbody>
                                </Table><br /><br />
                                For Retranz Infolabs Private Limited<br /><br />
                                <i>(Authorized Signatory)</i>
                            </div></td>
                        </tr></tbody></Table>
                        {/* Number(orderdetails["retailer_id"]) == 0 && (<Table>
                                                  <tr>
                                                    <td><img src={footer} alt={"Khetika"} style={{  width: "100%", height: "120px" }} /></td>
                                                  </tr>
                                                </Table>) */}
                    </div>
                </div>
            </div>
            {getBackDrop ? (<Backdrop parentLoadStatus={getBackDrop} />) : (null)}
        </>
    )
}

export default OrdersDetails
