import { useState, useEffect } from 'react';
import fire from './Configs/firebase';

const database = fire.database();
const warehouseRef = database.ref("/Warehouses");

const DB_VERSION = 2;
const DB_EXPIRATION_MINUTES = 1440; // 24 * 60 ( 1 day)
const DB_NAME = 'WarehouseDB';
const LOCK_TIMEOUT = 5000; // 5 seconds

// Global connection manager
const DBConnectionManager = {
  db: null,
  connections: 0,
  initPromise: null,

  async connect() {
    this.connections++;
    if (this.db && !this.db.closed) {
      return this.db;
    }
    return null;
  },

  async disconnect() {
    this.connections--;
    if (this.connections <= 0) {
      this.connections = 0;
      if (this.db && !this.db.closed) {
        this.db.close();
        this.db = null;
      }
    }
  },

  setDatabase(db) {
    this.db = db;
  },

  reset() {
    if (this.db && !this.db.closed) {
      this.db.close();
    }
    this.db = null;
    this.connections = 0;
    this.initPromise = null;
  }
};

export function useWarehouseDB() {
  const [db, setDb] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const acquireLock = async (lockKey) => {
    const maxRetries = 50;
    let retries = 0;

    while (retries < maxRetries) {
      const now = Date.now();
      const lockValue = localStorage.getItem(lockKey);
      
      if (lockValue) {
        const lockTime = parseInt(lockValue);
        if (now - lockTime < LOCK_TIMEOUT) {
          await new Promise(resolve => setTimeout(resolve, 100));
          retries++;
          continue;
        }
      }
      
      localStorage.setItem(lockKey, now.toString());
      await new Promise(resolve => setTimeout(resolve, 50));
      
      if (localStorage.getItem(lockKey) === now.toString()) {
        return true;
      }
      
      retries++;
    }
    
    return false;
  };

  const releaseLock = (lockKey) => {
    localStorage.removeItem(lockKey);
  };

  const fetchWarehouseData = async () => {
    try {
      const snapshot = await warehouseRef.once('value');   
      return snapshot.val();
    } catch (error) {
      console.error("Error fetching warehouse data from Firebase:", error);
      throw error;
    }
  };

  const openDatabase = async () => {
    // First try to get existing connection
    const existingDb = await DBConnectionManager.connect();
    if (existingDb) {
      return existingDb;
    }

    const hasLock = await acquireLock('warehouseDB_init_lock');
    if (!hasLock) {
      throw new Error('Could not acquire lock for database initialization');
    }

    try {
      return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = () => {
          DBConnectionManager.reset();
          reject(new Error('Failed to open database'));
        };

        request.onsuccess = async (event) => {
          const database = event.target.result;
          
          database.onversionchange = () => {
            database.close();
            DBConnectionManager.reset();
            setDb(null);
            setIsInitialized(false);
          };

          database.onclose = () => {
            DBConnectionManager.reset();
            setDb(null);
            setIsInitialized(false);
          };

          if (database.refresh_needed) {
            try {
              const newData = await fetchWarehouseData();
              await storeWarehouseData(database, newData);
              delete database.refresh_needed;
            } catch (error) {
              console.error('Error refreshing data after version upgrade:', error);
            }
          }
          
          DBConnectionManager.setDatabase(database);
          resolve(database);
        };

        request.onupgradeneeded = (event) => {
          const database = event.target.result;
          
          if (event.oldVersion < DB_VERSION) {
            database.refresh_needed = true;
          }
          
          if (!database.objectStoreNames.contains('warehouses')) {
            database.createObjectStore('warehouses', { keyPath: 'name' });
          }
          
          if (!database.objectStoreNames.contains('metadata')) {
            const metadataStore = database.createObjectStore('metadata', { keyPath: 'key' });
            metadataStore.add({ key: 'creationDate', value: Date.now() });
          }
        };

        request.onblocked = () => {
          DBConnectionManager.reset();
          reject(new Error('Database blocked'));
        };
      });
    } finally {
      releaseLock('warehouseDB_init_lock');
    }
  };

  const checkDatabaseExpiration = async (database) => {
    if (!database || database.closed) return true;

    try {
      const transaction = database.transaction(['metadata'], 'readonly');
      const metadataStore = transaction.objectStore('metadata');
      const request = metadataStore.get('creationDate');

      return new Promise((resolve) => {
        request.onsuccess = (event) => {
          const creationDate = event.target.result?.value;
          if (creationDate) {
            const expirationDate = new Date(creationDate + (DB_EXPIRATION_MINUTES * 60 * 1000));
            resolve(new Date() > expirationDate);
          } else {
            resolve(true);
          }
        };

        request.onerror = () => resolve(true);
      });
    } catch {
      return true;
    }
  };

  const deleteDatabase = async () => {
    const hasLock = await acquireLock('warehouseDB_delete_lock');
    if (!hasLock) {
      throw new Error('Could not acquire lock for database deletion');
    }

    try {
      DBConnectionManager.reset();

      return new Promise((resolve, reject) => {
        const deleteRequest = indexedDB.deleteDatabase(DB_NAME);

        deleteRequest.onsuccess = () => {
          setDb(null);
          setIsInitialized(false);
          localStorage.setItem('warehouseDB_deleted', Date.now().toString());
          resolve();
        };

        deleteRequest.onerror = () => {
          reject(new Error('Failed to delete database'));
        };

        deleteRequest.onblocked = () => {
          setTimeout(async () => {
            try {
               await deleteDatabase();
              // const newData = await fetchWarehouseData();
              // await storeWarehouseData(database, newData);
              resolve();
            } catch (error) {
              reject(error);
            }
          }, 500);
        };
      });
    } finally {
      releaseLock('warehouseDB_delete_lock');
    }
  };

  const storeWarehouseData = async (database, data) => {
    return new Promise((resolve, reject) => {
      const transaction = database.transaction(['warehouses'], 'readwrite');
      const objectStore = transaction.objectStore('warehouses');

      const clearRequest = objectStore.clear();
      
      clearRequest.onsuccess = () => {
        Object.entries(data).forEach(([name, warehouseData]) => {
          objectStore.add({
            name,
            ...warehouseData,
            timestamp: Date.now()
          });
        });

        transaction.oncomplete = resolve;
        transaction.onerror = reject;
      };

      clearRequest.onerror = reject;
    });
  };

  const initialize = async () => {
    if (DBConnectionManager.initPromise) {
      return DBConnectionManager.initPromise;
    }
  
    if (isInitialized && db && !db.closed) {
      return db;
    }
  
    DBConnectionManager.initPromise = (async () => {
      try {
        let database = await openDatabase();
        const hasExpired = await checkDatabaseExpiration(database);
        
        if (hasExpired) {
          // Create a new transaction and update the database
          try {
            const newData = await fetchWarehouseData();
            
            // Wrap all database operations in a Promise
            await new Promise((resolve, reject) => {
              const transaction = database.transaction(['warehouses', 'metadata'], 'readwrite');
              
              // Add error handling for the transaction
              transaction.onerror = () => {
                reject(new Error('Transaction failed'));
              };
              
              transaction.oncomplete = () => {
                resolve();
              };
  
              const warehousesStore = transaction.objectStore('warehouses');
              const metadataStore = transaction.objectStore('metadata');
  
              // Clear existing data
              const clearRequest = warehousesStore.clear();
              
              clearRequest.onsuccess = () => {
                // Add new warehouse data
                Object.entries(newData).forEach(([name, data]) => {
                  const addRequest = warehousesStore.add({
                    name,
                    ...data,
                    timestamp: Date.now()
                  });
                  
                  addRequest.onerror = () => {
                    reject(new Error('Failed to add warehouse data'));
                  };
                });
  
                // Update metadata
                const metadataRequest = metadataStore.put({
                  key: 'creationDate',
                  value: Date.now()
                });
                
                metadataRequest.onerror = () => {
                  reject(new Error('Failed to update metadata'));
                };
              };
  
              clearRequest.onerror = () => {
                reject(new Error('Failed to clear existing data'));
              };
            });
  
            // Reset connection only after successful transaction
            DBConnectionManager.reset();
            database = await openDatabase();
            
          } catch (error) {
            console.error('Error updating expired database:', error);
            // If update fails, try to recreate the database
            await deleteDatabase();
            database = await openDatabase();
            const newData = await fetchWarehouseData();
            await storeWarehouseData(database, newData);
          }
        }
  
        setDb(database);
        setIsInitialized(true);
        return database;
      } catch (error) {
       // console.error('Failed to initialize database:', error);
        // throw error;
      } finally {
        DBConnectionManager.initPromise = null;
      }
    })();
  
    return DBConnectionManager.initPromise;
  };

  const getAllWarehouses = async () => {
    try {
      const database = await initialize();
      if (!database) {
        throw new Error('No database connection');
      }

      const transaction = database.transaction(['warehouses'], 'readonly');
      const objectStore = transaction.objectStore('warehouses');
      
      return new Promise(async (resolve, reject) => {
        const request = objectStore.getAll();

        request.onsuccess = async (event) => {
          const data = event.target.result;
          if (data.length === 0) {
            try {
              const newData = await fetchWarehouseData();
              await storeWarehouseData(database, newData);
              resolve(Object.entries(newData).map(([name, data]) => ({
                name,
                ...data,
                timestamp: Date.now()
              })));
            } catch (error) {
              reject(error);
            }
          } else {
            resolve(data);
          }
        };

        request.onerror = () => reject(new Error('Failed to fetch warehouses'));
      });
    } catch (error) {
      // console.error('Error in getAllWarehouses:', error);
      // throw error;
    }
  };

  useEffect(() => {
    initialize().catch(console.error);

    const handleStorage = (event) => {
      if (event.key === 'warehouseDB_deleted') {
        DBConnectionManager.reset();
        setDb(null);
        setIsInitialized(false);
        initialize().catch(console.error);
      }
    };

    window.addEventListener('storage', handleStorage);

    // Periodic cleanup with debounce
    // this is for background processing
    // it will be triggered on it's own without any click
    let cleanupTimeout;
    const scheduleCleanup = () => {
      clearTimeout(cleanupTimeout);
      cleanupTimeout = setTimeout(async () => {
        if (db && !db.closed) {
          try {
            const hasExpired = await checkDatabaseExpiration(db);
            if (hasExpired) {
              // await deleteDatabase();
              // await initialize();
              const newData = await fetchWarehouseData();
              await storeWarehouseData(database, newData);
            
            }
          } catch (error) {
            console.error('Error during periodic cleanup:', error);
          }
          scheduleCleanup();
        }
      }, 60000);
    };

    // scheduleCleanup();

    return () => {
      window.removeEventListener('storage', handleStorage);
      // clearTimeout(cleanupTimeout);
      DBConnectionManager.disconnect();
    };
  }, [db]);

  return { getAllWarehouses };
}