import React, { useEffect } from 'react';
import { useWarehouseDB } from './indexedDBWarehouse.js';

const DBInitializer = ({ children }) => {
  const {getAllWarehouses} = useWarehouseDB();

  useEffect(() => {
    const initDB = async () => {
      try {
        await getAllWarehouses();
        console.log('Database initialized successfully');
      } catch (error) {
        console.error('Failed to initialize database:', error);
      }
    };

    initDB();
  }, [getAllWarehouses]);

  return <>{children}</>;
};

export default DBInitializer;