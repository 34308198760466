import React, { useState, useMemo, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { service_url } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import moment from "moment";
import ReactSelect from "react-select";

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import axios from "axios";

import { warehouses } from '../../indexedDBUtility.js';

function WeeklyVariableCost() {
  let csv_data = useRef("");

  const [warehouseOptions,setWarehouseOptions] = useState([])

  useEffect(() => {
    warehouses
      .then(data => {
        console.log("Fetched Warehouses:", data);
        const allWarehouses = [
          { value: "All", label: "All" },
          ...data.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
        ];
        setWarehouseOptions(allWarehouses);
        console.log("All warehouses:", allWarehouses);
      })
      .catch(error => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, []);

  const currentDate = moment();
  const today = currentDate.format("YYYY-MM-DD");
  const daysUntilMonday = (7 + currentDate.day() - 1) % 7;
  const lastMonday = currentDate.subtract(daysUntilMonday, "days").toDate();
  const nextSunday = moment(lastMonday).add(6, "days").toDate();

  const [startDate, setStartDate] = useState(lastMonday);
  const [endDate, setEndDate] = useState(nextSunday);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [TableDataTransporter, setTableDataTransporter] = useState([]);
  const [SumTableData, setSumTableData] = useState([]);

  const [PostData, setPostData] = useState({ date: today.slice(0, 7) });
  const [PostData2, setPostData2] = useState({ date: lastMonday , warehouse: "Bhiwandi" });

  const [LabourCostDate, setLabourCostDate] = useState( today.slice(0, 7))

  const [Reload, setReload] = useState(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    // toast(" showing tost massage please remove this on  handleShow1 fun");
    setPostData({ date: today.slice(0, 7) });
    setShow1(true);
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setPostData2({ date: lastMonday , warehouse: "Bhiwandi" });
    // toast(" showing tost massage please remove this on  handleShow1 fun");
    setShow2(true);
  };

  const isMonday = (date) => {
    return moment(date).day() === 1;
  };

  const filterDate = (date) => {
    return isMonday(date) && moment(date).isSameOrBefore(moment(), "day");
  };

  useEffect(() => {
    let start = new Date(startDate);
    start = moment(startDate).format("YYYY-MM-DD");

    let end = new Date(endDate);
    end = moment(end).format("YYYY-MM-DD");
    const url1 = `${service_url}/api/labour-cost?start_date=${start}&end_date=${end}`;
 
    axios
      .get(url1)
      .then((response) => {
        let warehouse_obj = {};
        for (let itm of response.data.data) {
          
          if (warehouse_obj[itm.warehouse] == undefined) {
            warehouse_obj[itm.warehouse] = {
              cost_per_labour: 0,
              cost_per_housekeeping: 0,
              cost_per_security: 0,
              mathadi_cost: 0,
              depot_rent: 0,
              depot_mgmt_fee: 0,
              no_labour: 0,
              no_supervisor: 0, 
              no_security: 0,

              adhoc_vehicle_rate: 0,
              fixed_vehicle_rate: 0,
              inter_depo_transport_rate: 0,

              fresh_primary_vehicle_rate : 0,
              fresh_LM_vehicle_rate : 0,

              bulk_vehicle_rate_upto_2000: 0,
              bulk_vehicle_rate_upto_3000: 0,
              bulk_vehicle_rate_upto_4000: 0,
              bulk_vehicle_rate_upto_5000: 0,
            };
          }

          let current = warehouse_obj[itm.warehouse];
          let new_obj = {
            cost_per_labour: current.cost_per_labour + itm.cost_per_labour,
            cost_per_housekeeping:
              current.cost_per_housekeeping + itm.cost_per_housekeeping,
            cost_per_security:
              current.cost_per_security + itm.cost_per_security,
            mathadi_cost: current.mathadi_cost + itm.mathadi_cost,
            depot_rent: current.depot_rent + itm.depot_rent,
            depot_mgmt_fee: current.depot_mgmt_fee + itm.depot_mgmt_fee,
            no_of_labour: current.no_labour + itm.no_labour,
            no_of_supervisor: current.no_supervisor + itm.no_supervisor,
            no_of_security: current.no_security + itm.no_security,

            adhoc_vehicle_rate: current.adhoc_vehicle_rate + itm.adhoc_vehicle_rate,
            fixed_vehicle_rate: current.fixed_vehicle_rate + itm.fixed_vehicle_rate,
            inter_depo_transport_rate: current.inter_depo_transport_rate + itm.inter_depo_transport_rate,

            fresh_primary_vehicle_rate : current.fresh_primary_vehicle_rate + itm.fresh_primary_vehicle_rate,
            fresh_LM_vehicle_rate :  current.fresh_LM_vehicle_rate + itm.fresh_LM_vehicle_rate,

            bulk_vehicle_rate_upto_2000: current.bulk_vehicle_rate_upto_2000 + itm.bulk_vehicle_rate_upto_2000,
            bulk_vehicle_rate_upto_3000: current.bulk_vehicle_rate_upto_3000 + itm.bulk_vehicle_rate_upto_3000,
            bulk_vehicle_rate_upto_4000: current.bulk_vehicle_rate_upto_4000 + itm.bulk_vehicle_rate_upto_4000,
            bulk_vehicle_rate_upto_5000: current.bulk_vehicle_rate_upto_5000 + itm.bulk_vehicle_rate_upto_5000,
          };
          warehouse_obj[itm.warehouse] = new_obj;
        }

        let matrix = {
          no_of_labour: "- Total No of Labour Present",
          cost_per_labour: "- Cost Per Labour",
          no_of_supervisor: "- Total No of Housekeeping Present",
          cost_per_housekeeping: "- Cost of Housekeeping",
          no_of_security: "- Total No of Security Person Present",
          cost_per_security: "- Cost Per Security Person",
          mathadi_cost: "- Total Mathadi Cost",

          depot_rent: "- Depot Rent",
          depot_mgmt_fee: "- Depot Management Fee",

          fixed_vehicle_rate: "Fixed Vehicle Rate (Per Month)",
          adhoc_vehicle_rate: "Adhoc Vehicle Rate  (Per Trip)",

          inter_depo_transport_rate: "Inter Depot Transport Rate (Per Kg)",

          fresh_primary_vehicle_rate : "Fresh Primary Vehicle Rate (Per Trip)",
          fresh_LM_vehicle_rate : "Fresh Secondary Vehicle Rate (Per Month)",
        };

        let final = [];
        for (let mx in matrix) {
          
          let obj = { matrix: matrix[mx] };
          let total = 0;
          for (let warehouse in warehouse_obj) {
            if(mx == "no_of_labour"  || mx == "no_of_supervisor"  || mx == "no_of_security" ){

              obj[warehouse] = warehouse_obj[warehouse][mx] || 0;
              total += Number(warehouse_obj[warehouse][mx] || 0);

            }else{
              obj[warehouse] = warehouse_obj[warehouse][mx]?.toFixed(2) || 0;
              total += Number(warehouse_obj[warehouse][mx]?.toFixed(2) || 0);
            }


          }
          if(mx == "no_of_labour"  || mx == "no_of_supervisor"  || mx == "no_of_security" ){
            obj["total"] = total || 0;
            final.push(obj);
          }else{
            obj["total"] = total?.toFixed(2) || 0;
            final.push(obj);
          }
          

        }

        let TotalLabour = { matrix: "Total Labour Cost", total: null };
        let tlc = 0;
        for (let warehouse in warehouse_obj) {
          let {
            cost_per_labour,
            cost_per_security,
            cost_per_housekeeping,
            mathadi_cost,
            no_of_labour,
            no_of_security,
            no_of_supervisor,

            depot_rent,
            depot_mgmt_fee,
          } = warehouse_obj[warehouse];

          let total_warehouse =
            cost_per_labour * no_of_labour +
            cost_per_security * no_of_security +
            cost_per_housekeeping * no_of_supervisor +
            mathadi_cost;
          tlc += total_warehouse;
          TotalLabour[warehouse] = total_warehouse?.toFixed(2);
        }
        TotalLabour["total"] = tlc?.toFixed(2);

        let TotalDepot = { matrix: "Total Depot Cost", total: null };
        let td = 0;

        for (let warehouse in warehouse_obj) {
          let { depot_rent, depot_mgmt_fee } = warehouse_obj[warehouse];
          let total_depot_cost = depot_rent + depot_mgmt_fee;
          td += total_depot_cost;
          TotalDepot[warehouse] = total_depot_cost?.toFixed(2);
        }

        TotalDepot["total"] = td?.toFixed(2);

        let Lebour = final.slice(0, 7);
        let depro = final.slice(7, 9);
        let transport = final.slice(9, 16);

        final = [TotalLabour, ...Lebour, TotalDepot, ...depro];

        setSumTableData(final);
        setTableDataTransporter(transport);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {};
  }, [startDate, Reload]);

  const isStartDateValid = (date) => {
    return isMonday(date) && moment(date).isSameOrBefore(moment(), "day");
  };

  const handleDateChange = (date) => {
    if (isStartDateValid(date)) {
      setStartDate(date);

      setEndDate(moment(date).add(6, "days").toDate());
    }
  };

  function addLabourCost() {
    let loggedInUser = JSON.parse(sessionStorage.getItem(`user`))[0];
    const createdBy = loggedInUser.email;
    PostData["created_by"] = createdBy;

    if (LabourCostDate == null) {
      return toast.error("Date Not Present");
    }
    PostData["date"] = LabourCostDate

    if (PostData.warehouse == null) {
      return toast.error("Warehouse Not Present");
    }
    if (PostData.cost_per_labour == null) {
      PostData["cost_per_labour"] = 0;
      // return toast.error("Cost Per Labour Not Present");
    }
    if (PostData.cost_per_housekeeping == null) {
      PostData["cost_per_housekeeping"] = 0;
      // return toast.error("Cost Per Supervisor Not Present");
    }
    if (PostData.cost_per_security == null) {
      PostData["cost_per_security"] = 0;
      // return toast.error("Cost Per Security Not Present");
    }
    // if (PostData.mathadi_cost == null) {
    //   return toast.error("Mathadi Cost Not Present");
    // }

    const url = `${service_url}/api/labour-cost`;
    let data = { ...PostData, date: PostData.date + "-01" };
    console.log(data);

    axios
      .post(url, data)
      .then((response) => {
        toast.success("Labour Cost Added");
        setShow1(false);
        setReload(!Reload);

        setPostData({ date: today.slice(0, 7) });
        setShow1(false);
      })
      .catch((error) => {
        toast.error("error happened");
        setShow1(false);
        console.error("Error making POST request:", error);
        if (error.response.data.message.match("Duplicate entry")) {
          toast.warning("Data Already Added For this month");
        } else {
          toast.error("error happened");
        }
      });
  }

  function addLabourPresent() {
    console.log(PostData2);
    let loggedInUser = JSON.parse(sessionStorage.getItem(`user`))[0];
    const createdBy = loggedInUser.email;
    PostData2["created_by"] = createdBy;
    if (PostData2.date == null) {
      return toast.error("Date not present");
    }
    if (PostData2.warehouse == null) {
      return toast.error("Warehouse not present");
    }
    if (PostData2.no_labour == null) {
      return toast.error("Number of labour labour not present");
    }
    if (PostData2.no_supervisor == null) {
      return toast.error("Number of Housekeeping not present");
    }
    if (PostData2.no_security == null) {
      return toast.error("Number of Security Security Not Present");
    }

    const url = `${service_url}/api/labour-present`;

    axios
      .post(url, PostData2)
      .then((response) => {
        toast.success("Labour Present Added");
        console.log("POST request successful:", response.data);
        setShow2(false);
        setReload(!Reload);
        setPostData2({ date: lastMonday , warehouse: "Bhiwandi" });
        setShow2(false);
      })
      .catch((error) => {
        toast.error("error happened");
        setShow2(false);
        console.error("Error making POST request:", error);
      });
  }

  function createCsv(data, columns) {
    let final_csv = [];
    for (let row of data) {
      let one_row = [];
      for (let col of columns) {
        one_row.push(row[col.accessor]);
      }
      final_csv.push(one_row);
    }

    let one_row = [];
    for (let col of columns) {
      one_row.push(col.accessor);
    }
    final_csv.unshift(one_row);

    csv_data.current = final_csv;
  }

  function Table1() {
    const data = useMemo(() => SumTableData, []);
    const columns = React.useMemo(
      () => [
        {
          Header: "Matrix",
          accessor: "matrix",
        },
        {
          Header: "Total",
          accessor: "total",
        },
        {
          Header: "Bhiwandi",
          accessor: "Bhiwandi",
        },
        {
          Header: "Mira Road",
          accessor: "Mira Road",
        },
        // {
        //   Header: "Sanpada",
        //   accessor: "Sanpada",
        // },
        {
          Header: "Wadala",
          accessor: "Wadala",
        },
      ],
      []
    );

    createCsv(data, columns);

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="search..."
              value={value || ""}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
            />
          </InputGroup>
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter>
          </Col>
          <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"Weekly_Variable_Cost.csv"}
              data={csv_data.current}
            >
              Export Data
            </CSVLink>
          </Col>
          <Col>
            <Button variant="primary" onClick={handleShow1}>
              Add Labour Cost
            </Button>
          </Col>
          <Col>
            <Button variant="primary" onClick={handleShow2}>
              Add Labour Present
            </Button>
          </Col>
          <Col></Col>
        </Row>
        <br />

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  function Table2() {
    console.log(TableDataTransporter);
    const data = useMemo(() => TableDataTransporter, []);
    const columns = React.useMemo(
      () => [
        {
          Header: "Transporter cost",
          accessor: "matrix",
        },

        {
          Header: "Bhiwandi",
          accessor: "Bhiwandi",
        },
        {
          Header: "Mira Road",
          accessor: "Mira Road",
        },
        // {
        //   Header: "Sanpada",
        //   accessor: "Sanpada",
        // },
        {
          Header: "Wadala",
          accessor: "Wadala",
        },
      ],
      []
    );

    createCsv(data, columns);

    function SelectColumnFilter({
      column: { filterValue, setFilter, preFilteredRows, id },
    }) {
      const options = useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
          options.add(row.values[id]);
        });
        return [...options.values()];
      }, [id, preFilteredRows]);

      return (
        <select
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    const defaultColumn = useMemo(
      () => ({
        Filter: () => null,
      }),
      []
    );

    const GlobalFilter = ({ filter, setFilter }) => {
      const [value, setValue] = useState(filter);
      const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
      }, 1000);
      return (
        <span>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="search..."
              value={value || ""}
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
            />
          </InputGroup>
        </span>
      );
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    const { globalFilter } = state;

    return (
      <div>
        <Row>
          <Col>
            {/* <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
            ></GlobalFilter> */}
          </Col>
          {/* <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"Weekly_Variable_Cost.csv"}
              data={csv_data.current}
            >
              Export Data
            </CSVLink>
          </Col> */}

          <Col></Col>
        </Row>

        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      borderBottom: "solid 3px red",
                      background: "aliceblue",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: "10px",
                          border: "solid 1px gray",
                          background: "papayawhip",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <NavBar />

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div style={{ margin: 20 }}>
        <h2> Weekly Variable Cost </h2>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Row>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1"> Start Date</InputGroup.Text>
              </InputGroup.Prepend>
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                filterDate={filterDate} 
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a date"
                className="form-control"
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">End Date</InputGroup.Text>
              </InputGroup.Prepend>
              <DatePicker
                disabled
                selected={endDate}
                filterDate={filterDate}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select Start date"
                className="form-control"
              />
            </InputGroup>
          </Col>
          <Col></Col>
        </Row>

        <Row>
          <Col>
            <Table1 />
            <br></br>
            <Table2 />
          </Col>
        </Row>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Labour Cost</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Month
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="month"
                id="basic-url"
                aria-describedby="basic-addon3"
                value={LabourCostDate}
                onChange={(e) =>
                  // setPostData({ ...PostData, date: e.target.value }),
                  setLabourCostDate(e.target.value)
                }
              />
            </InputGroup>

            <ReactSelect
               options ={warehouseOptions}
              // options={[
              //   { value: "Bhiwandi", label: "Bhiwandi" },
              //   { value: "Mira Road", label: "Mira Road" },
              //   //-- { value: "Sanpada", label: "Sanpada" },
              //   { value: "Wadala", label: "Wadala" },
              // ]}
              // value={PostData?.warehouse}
              onChange={({ value }) =>
                setPostData({ warehouse: value })
              }
              placeholder="Select a warehouse"
            />

            <br></br>

            {PostData.warehouse != "Mira Road" &&
              PostData.warehouse != "Wadala" && (
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon3"
                      style={{ width: "350px" }}
                    >
                      Cost Per Labour
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="basic-url"
                    type="number"
                    aria-describedby="basic-addon3"
                    value={PostData?.cost_per_labour}
                    onChange={(e) =>
                      setPostData({
                        ...PostData,
                        cost_per_labour: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

            {PostData.warehouse != "Mira Road" &&
              PostData.warehouse != "Wadala" && (
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon3"
                      style={{ width: "350px" }}
                    >
                      Cost Per Housekeeping
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    type="number"
                    value={PostData?.cost_per_housekeeping}
                    onChange={(e) =>
                      setPostData({
                        ...PostData,
                        cost_per_housekeeping: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

            {PostData.warehouse != "Mira Road" &&
              PostData.warehouse != "Wadala" && (
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="basic-addon3"
                      style={{ width: "350px" }}
                    >
                      Cost Per Security
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    id="basic-url"
                    aria-describedby="basic-addon3"
                    value={PostData?.cost_per_security}
                    type="number"
                    onChange={(e) =>
                      setPostData({
                        ...PostData,
                        cost_per_security: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              )}

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Mathadi Cost
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="basic-url"
                aria-describedby="basic-addon3"
                type="number"
                value={PostData?.mathadi_cost}
                onChange={(e) =>
                  setPostData({ ...PostData, mathadi_cost: e.target.value })
                }
              />
            </InputGroup>

            {PostData.warehouse != "Bhiwandi" && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                    Depot Rent
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.depot_rent}
                  onChange={(e) =>
                    setPostData({ ...PostData, depot_rent: e.target.value })
                  }
                />
              </InputGroup>
            )}

            {PostData.warehouse != "Bhiwandi" && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                    Depot Management Fee
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.depot_mgmt_fee}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      depot_mgmt_fee: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}

            {1 && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Fixed Vehicle Rate (Per Month)
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.fixed_vehicle_rate}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      fixed_vehicle_rate: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}

            {1 && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Adhoc Vehicle Rate (Per Trip)	
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.adhoc_vehicle_rate}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      adhoc_vehicle_rate: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}

            {PostData.warehouse != "Mira Road" && 
              PostData.warehouse != "Bhiwandi"  && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Fresh Primary Vehicle Rate (Per Trip)	
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.fresh_primary_vehicle_rate}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      fresh_primary_vehicle_rate: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}


          {1  && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Fresh Secondary Vehicle Rate (Per Month)
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.fresh_LM_vehicle_rate}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      fresh_LM_vehicle_rate: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}

            {/* 
            {PostData.warehouse != "Mira Road" &&
              PostData.warehouse != "Wadala"   && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "250px" }}>
                    Bulk Vehicle Rate Upto 3000
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.bulk_vehicle_rate_upto_3000}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      bulk_vehicle_rate_upto_3000: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}

            { PostData.warehouse != "Mira Road" &&
              PostData.warehouse != "Wadala"  && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "250px" }}>
                    Bulk Vehicle Rate Upto 4000
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.bulk_vehicle_rate_upto_4000}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      bulk_vehicle_rate_upto_4000: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}

            {PostData.warehouse != "Mira Road" &&
              PostData.warehouse != "Wadala"  && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "250px" }}>
                    Bulk Vehicle Rate Upto 5000
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.bulk_vehicle_rate_upto_5000}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      bulk_vehicle_rate_upto_5000: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )} */}

            {PostData.warehouse != "Bhiwandi" && (
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Inter Depot Transport Rate (Per Kg)	
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="basic-url"
                  type="number"
                  aria-describedby="basic-addon3"
                  value={PostData?.inter_depo_transport_rate}
                  onChange={(e) =>
                    setPostData({
                      ...PostData,
                      inter_depo_transport_rate: e.target.value,
                    })
                  }
                />
              </InputGroup>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Close
            </Button>
            <Button variant="primary" onClick={addLabourCost}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* date,warehouse,created_by,no_labour,no_supervisor,no_security */}
        <Modal show={show2} onHide={handleClose2} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Labour Present</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Date
                </InputGroup.Text>
              </InputGroup.Prepend>
 
              <DatePicker
                selected={PostData2?.date}
                onChange={(date) =>
                  setPostData2({ ...PostData2, date: date })
                }
                filterDate={filterDate}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a date"
                className="form-control"
             
              />

              {/* <FormControl
                type="date"
                id="basic-url"
                aria-describedby="basic-addon3"
                value={PostData2?.date}
                onChange={(e) =>
                  setPostData2({ ...PostData2, date: e.target.value })
                }
              /> */}
            </InputGroup>
              

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Warehouse
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="basic-url"
                aria-describedby="basic-addon3"
                value={PostData2?.warehouse}
                disabled
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Number of Labour
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="basic-url"
                aria-describedby="basic-addon3"
                type="number"
                value={PostData2?.no_labour}
                onChange={(e) =>
                  setPostData2({
                    ...PostData2,
                    no_labour: e.target.value,
                  })
                }
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Number of Housekeeping
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="basic-url"
                aria-describedby="basic-addon3"
                type="number"
                value={PostData2?.no_supervisor}
                onChange={(e) =>
                  setPostData2({
                    ...PostData2,
                    no_supervisor: e.target.value,
                  })
                }
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3" style={{ width: "350px" }}>
                  Number of Security
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                id="basic-url"
                aria-describedby="basic-addon3"
                type="number"
                value={PostData2?.no_security}
                onChange={(e) =>
                  setPostData2({
                    ...PostData2,
                    no_security: e.target.value,
                  })
                }
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={addLabourPresent}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default WeeklyVariableCost;
