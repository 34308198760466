import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase";
import fire from "../../Configs/firebase";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from "react-table";

import NavBar from "../Navigation bar/Navbar";
import axios from "axios";
import Select from "react-select";
import { service_url } from "../../Configs/mysqlconfig";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  DropdownButton,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { warehouses } from '../../indexedDBUtility.js';

toast.configure({
  autoClose: 4000,
  draggable: true,
  pauseOnHover: true,
  position: toast.POSITION.TOP_RIGHT,
});
 
function ChequeManagement() {
  const [Reload, setReload] = useState(true)
  let userType = sessionStorage.getItem("userType"); // admin
  let userCity = sessionStorage.getItem("userCity");
  // let userType = "x" // admin

  const [ChequeProcessed, setChequeProcessed] = useState("");
  const [ErrorTypes, setErrorTypes] = useState();
  const [tableEditStatus, setTableEditStatus] = useState({});
  const [chequeTable, setChequeTable] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [uploadPayload, setUploadPayload] = useState({
    order_number: "",
    cheque_amount_change: "",
    cheque_number_change: "",
    physical_cheque_collection: "",
    name_of_user: "",
    cheque_deposit_date: "",
    cheque_bounces_date: "",
    delivery_date: "",
    remark: "",
  });
  const [uploadPayload2, setUploadPayload2] = useState({
    order_number: "",
    delivery_date: "",
    cheque_bounces_date: "",
    cheque_error: "",

  });

  const [tableCity, setTableCity] = useState("");
  const [CityList, setCityList] = useState([
    // "Surat",
    // "Nashik",
    // "Wadala",
    // "Bhiwandi",
    // "Andheri",
    // "Virar",
    // "Mumbai",
    // "South Mumbai",
    // "Vashi",
    // "East Mumbai",
    // "Navi Mumbai",
    // "Mira-Bhayandar",
    // "Central Mumbai",
    // "Vasai-Virar",
  ]);

  useEffect(() => {
    warehouses
      .then(data => {
        console.log("Fetched Warehouses:", data);
        const allWarehouses =  [
          "All",
           ...data.map(obj => (obj.warehouse))
         ];
         
        setCityList(allWarehouses);
        console.log("All warehouses:", allWarehouses);
      })
      .catch(error => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, []);


  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const [tableDate, setTableDate] = useState(today);

  const handelCloseDate = () => {


    if (userType !== "Admin"){
      toast("You are not a admin", {
        type: toast.TYPE.ERROR,
      });
      return
    }

      let answer = window.confirm("Do you want to close editing option for today ?");

      if (answer) {
         const url = `${service_url}/api/superzop/chequemanagement/createChequeManagementClosing?date=${tableDate}`;
          axios({
            method: "POST",
            url: url,
          })
        .then(function (response) {
          toast("Editing option closed For Today for non admin user", {
            type: toast.TYPE.SUCCESS,
          });
          
          setReload(!Reload)
          setShow2(false)
        })
        .catch(function (error) {
          console.log("ERROR while updating the table data : ", error);
        });
      }
    
  
  };


  // for main model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    console.log(val);
    setUploadPayload({
      ...uploadPayload,
      order_number: val.order_number,
      cheque_amount_change: val.cheque_amount_change,
      payment_cheque_number: val.payment_cheque1_number,
      payment_cheque1_number: val.payment_cheque1_number,
      cheque_number_change: val.cheque_number_change,
      physical_cheque_collection: val.physical_cheque_collection,
      name_of_user: val.name_of_user,
      cheque_deposit_date: val.cheque_deposit_date,
      cheque_bounces_date: val.cheque_bounces_date,
      delivery_date: val.delivery_date,
      remark: val.remark,
    });

    setShow(true);
  };
  const handleUpdate = () => {
    const url = `${service_url}/api/superzop/chequemanagement/updateChequeDetails`;

    axios({
      method: "put",
      url: url,
      data: uploadPayload,
    })
      .then(function (response) {
        console.log(response);
        toast("Update successful", {
          type: toast.TYPE.SUCCESS,
        });

        setReload(!Reload)
        setShow(false)
      })
      .catch(function (error) {
        console.log("ERROR while updating the table data : ", error);
        toast("Update Failure", {
          type: toast.TYPE.ERROR,
        });
      });
  };

  // for secondary model
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => {

    setShow2(false);
    
    setErrorTypes("")
    setChequeProcessed("")

    setUploadPayload2({
      ...uploadPayload2,
      order_number: "",
      delivery_date: "",
      cheque_bounces_date: "",
      cheque_error: "",
    });

  };
  const handleShow2 = (val) => {
    console.log(val);

    setUploadPayload2({
      ...uploadPayload2,
      order_number: val.order_number,
      payment_cheque_number: val.payment_cheque1_number,
      delivery_date: val.delivery_date,
      cheque_bounces_date: val.cheque_bounces_date,
      cheque_error: val.cheque_error,
      remark : val.remark,
      payment_amount : val?.payment_amount,
      payment_mode : val?.payment_mode
    });

    if (val.physical_cheque_collection === 1){
      setShow2(true);
    }else{
      toast("Cheque is not physically collected", {
        type: toast.TYPE.ERROR,
      });
    }
  
  };
  const handleUpdate2 = () => {
    const url = `${service_url}/api/superzop/chequemanagement/updateChequeDetailsForClose`;
    console.log(uploadPayload2);


    if (uploadPayload2.cheque_error == "Other_Payments"){

       uploadPayload2.payment_amount = null
       uploadPayload2.payment_mode = null
    }
    if (uploadPayload2.cheque_error == "Cheque_Bounced"){
       uploadPayload2.cheque_bounces_date = null
    }
    if (uploadPayload2.cheque_error == "Cheque_Error"){
         uploadPayload2.cheque_bounces_date = null 
         uploadPayload2.payment_amount = null
         uploadPayload2.payment_mode = null
    }

    if (uploadPayload2.cheque_processed == 1 ){
        uploadPayload2.cheque_bounces_date = null 
        uploadPayload2.payment_amount = null
        uploadPayload2.payment_mode = null
        uploadPayload2.remark = null
        uploadPayload2.cheque_error = null
        
    }
    
    
    axios({
      method: "put",
      url: url,
      data: uploadPayload2,
    })
      .then(function (response) {
        console.log(response);
        toast("update successful", {
          type: toast.TYPE.SUCCESS,
        });

        setReload(!Reload)
        setShow2(false)
      })
      .catch(function (error) {
        toast("Failed to update", {
          type: toast.TYPE.ERROR,
        });
        console.log("ERROR while updating the table data : ", error);
      });
  };

  // sync api call
  const syncDriverOrdersHistory = () => {
    let todaysDate = new Date();
    const todaysDateYear = todaysDate.getFullYear();
    const todaysDateMonth = todaysDate.getMonth() + 1;
    const todaysDateDay = todaysDate.getDate();

    toast("Sync started... ", {
      type: toast.TYPE.SUCCESS,
    });

    const url =
      `${service_url}/api/superzop/delivery/savedriverordershistorytable?day=` +
      todaysDateDay +
      "&month=" +
      todaysDateMonth +
      "&year=" +
      todaysDateYear;

    axios({
      method: "post",
      url: url,
    })
      .then(function (response) {
        console.log(response);
        toast("Successfully sync Driver Orders History ", {
          type: toast.TYPE.SUCCESS,
        });
      })
      .catch(function (error) {
        console.log("ERROR on sync Driver Orders History : ", error);
        toast(" ERROR on sync Driver Orders History ", {
          type: toast.TYPE.ERROR,
        });
      });
  };

  async function callTableDataAndClosing() {
    const url2 = `${service_url}/api/superzop/chequemanagement/getChequeManagementClosing?date=${tableDate}`;

    // let response
    try {
      let response = await axios.get(url2);
      console.log(response.data[0]);
      setTableEditStatus(response.data[0]);
    } catch (error) {
      console.log(error);
    }

    let url;
    if (userCity === undefined || userCity === "" || userCity === "Bhiwandi") {
      url = `${service_url}/api/superzop/chequemanagement/getChequeDetails?date=${tableDate}&city=${tableCity}`; // 2022-03-10  tableDate
    } else {
      url = `${service_url}/api/superzop/chequemanagement/getChequeDetails?date=${tableDate}&city=${userCity}`; // 2022-03-10  tableDate
    }
    // calling the main table

    axios
      .get(url)
      .then(function (response) {
        setChequeTable(response.data);

        // creating csv file data
        let table_date = response.data.map((item) => {
          return [
            item.order_number,
            item.delivery_date,
            item.payment_cheque1_number,
            item.payment_cheque1_amount,
            item.payment_cheque1_date,
            item.route,
            item.city,
            item.retailer_id,
            item.cheque_amount_change,
            item.cheque_number_change,
            item.name_of_user,
            item.cheque_deposit_date,
            item.cheque_bounces_date,
            item.cheque_error,
            item.remark,
            item.physical_cheque_collection,
          ];
        });

        table_date.unshift([
          "Order number",
          "Delivery date",
          "Payment cheque1 number",
          "Payment cheque1 amount",
          "Payment cheque1 date",
          "Route",
          "City",
          "Retailer id",
          "Cheque amount change",
          "Cheque number change",
          "Name of user",
          "Cheque deposit_date",
          "Cheque bounces_date",
          "Cheque error",
          "Remark",
          "Physical cheque collection",
        ]);
        setCsvData(table_date);
      })
      .catch(function (error) {
        console.log("ERROR while fetching the table data : ", error);
      });
  }

  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  }

  function SelectColumnFilterInput({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {


    return (
      <input
        value={filterValue}
        placeholder = "search..."
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      />
    );
  }

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  function dateFormatConverter(dt) {

    if(dt === null){
      return null
    }
    let dateSplit = dt.split("-");
    if (dateSplit[0].length == 4){
      return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`

    }else{
      return dt
    }

  
  }

  useEffect(() => {
    callTableDataAndClosing();

    return () => {};
  }, [tableDate, tableCity,Reload]);

  useEffect(() => {

    fire.database().ref("City_List").once("value").then((snapshot)=>{

      let  list = [];
      for (let itm in snapshot.val() ){
        list.push(itm)
      }

    //  setCityList(list)

    }).catch((error)=>{
      console.log(error);

    })


    return () => {}

  }, [])
  


  function Table1() {

    let chequeTableData = chequeTable.map((item) => {
      return {
        route: item.route,
        city: item.city,
        retailer_id: item.retailer_id,
        order_number: item.order_number,
        delivery_date: item.delivery_date.substring(0, 10),
        payment_cheque1_amount: item.payment_cheque1_amount,
        payment_cheque1_date: item.payment_cheque1_date,
        payment_cheque1_number: item.payment_cheque1_number,
        payment_cheque_number: item.payment_cheque_number,
        cheque_amount_change: item.cheque_amount_change,
        cheque_number_change: item.cheque_number_change,
        physical_cheque_collection: item.physical_cheque_collection,
        name_of_user: item.name_of_user,
        cheque_deposit_date: item.cheque_deposit_date,
        cheque_bounces_date: item.cheque_bounces_date,
        cheque_error: item.cheque_error,
        route_id: item.route_id,
        remark: item.remark,
        payment_amount : item.payment_amount,
        payment_mode : item.payment_mode,
        cheque_processed : item.cheque_processed

      };
    });

    const data = React.useMemo(() => chequeTableData, []);

    const columns = React.useMemo(
      () => [
        {
          Header: "Delivery Date",
          accessor: "delivery_date",
          Cell: ({ row }) => {
            return dateFormatConverter(row.original.delivery_date)
          },
        },
        {
          Header: "City",
          accessor: "city",
        },
        {
          Header: "Route",
          accessor: "route",
          Filter: SelectColumnFilterInput
        },
        {
          Header: "Retailer Id",
          accessor: "retailer_id",
          Filter: SelectColumnFilterInput
        },
        {
          Header: "Order Number",
          accessor: "order_number",
          Filter: SelectColumnFilterInput
        },
        {
          Header: "Payment Cheque Number",
          accessor: "payment_cheque1_number",
        },
        {
          Header: "Payment Cheque Amount",
          accessor: "payment_cheque1_amount",
        },
        {
          Header: "Payment Cheque Date",
          accessor: "payment_cheque1_date",
          Filter: SelectColumnFilter,
          Cell: ({ row }) => {
            return dateFormatConverter(row.original.payment_cheque1_date)
          },
        },
        {
          Header: "Cheque Amount Change",
          accessor: "cheque_amount_change",
        },
        {
          Header: "Cheque Number Change",
          accessor: "cheque_number_change",
        },
        {
          Header: "Deposited by",
          accessor: "name_of_user",
        },
        {
          Header: "Physical Cheque Collection",
          accessor: "physical_cheque_collection",
          Cell: ({ row }) => {
            if (row.original.physical_cheque_collection == 1) {
              return "☑️";
            } else {
              return "⬜";
            }
          },
        },
        {
          Header: "Cheque Deposit Date",
          accessor: "cheque_deposit_date",
          Filter: SelectColumnFilter,
          Cell: ({ row }) => {
            return dateFormatConverter(row.original.cheque_deposit_date)
          },
        },
        {
          Header: "Cheque Processed",
          accessor: "cheque_processed",
          Cell: ({ row }) => {
            if (row.original.cheque_processed == 1){
              return "Yes"
            }else if (row.original.cheque_processed == 0){
              return "No" 
            }else{
              return null
            }
          },
        },
        {
          Header: "Cheque Bounces Date",
          accessor: "cheque_bounces_date",
          Filter: SelectColumnFilter,
          Cell: ({ row }) => {
            return dateFormatConverter(row.original.cheque_bounces_date)
          },
        },
        {
          Header: "Cheque Error",
          accessor: "cheque_error",
        },
        {
          Header: "Payment mode",
          accessor: "payment_mode",
        },
        {
          Header: "Payment amount",
          accessor: "payment_amount",
        },
        
        {
          Header: "Remark",
          accessor: "remark",
        },
        {
          Header: "Cheque details",
          Cell: ({ row }) => {
            if (tableEditStatus?.delivery_date && userType !== "Admin") {
              return (
                <Button
                  variant="secondary"
                  onClick={() => handleShow2(row.original)}
                >
                  Edit
                </Button>
              );
            } else {
              return (
                <Button
                  variant="warning"
                  onClick={() => handleShow(row.original)}
                >
                  Edit
                </Button>
              );
            }
          },
        },
        {
          Header: "Payment confirmation",
          Cell: ({ row }) => {
            return (
              <Button
                variant="secondary"
                onClick={() => handleShow2(row.original)}
              >
                Edit
              </Button>
            );
          },
        },
      ],
      []
    );


    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      state,
      setGlobalFilter,
      preGlobalFilteredRows,
    } = useTable({ columns, data, defaultColumn }, useFilters, useGlobalFilter);

    return (
      <div>
        <table
          {...getTableProps()}
          style={{ border: "solid 1px blue", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{
                      padding: "10px",
                      color: "black",
                      fontWeight: "bold",
                      border: "solid 1px gray",
                    }}
                  >
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              // console.log(row.cells[13]);
   
              if (
                row.cells[11].value !== null && row.cells[11].value !== 0 && 
                row.cells[14].value === null && row.cells[15].value === null  && row.cells[13].value === 1
              ) {  // Physical Cheque Collection_ => 11  Cheque Bounces Date => 14   Cheque Error => 15 Cheque Processed_ => 13
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: "#5cb85c", // green
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              } else if (row.cells[11].value === 0  ||  row.cells[13].value === 0){
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                            background: "#d9534f", // red
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              }else {
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            border: "solid 1px gray",
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <br />

      <div style={{ margin: 20 }}>
        <h4>Cheque Management</h4>
        <Row xs="auto">
          <Col>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="date"
                value={tableDate}
                onChange={(e) => setTableDate(e.target.value)}
              />
            </Form.Group>
          </Col>

          {userCity === undefined || userCity === "" || userCity === "Bhiwandi" ? (
            <DropdownButton
              id="dropdown-basic-button"
              title={tableCity !== "" ? tableCity : "Choose City"}
              onSelect={(e) => setTableCity(e)}
            >
              {CityList &&
                CityList.map((each) => {
                  return (
                    <Dropdown.Item eventKey={each}> {each} </Dropdown.Item>
                  );
                })}
            </DropdownButton>
          ) : (
            ""
          )}

          <Col>
            <CSVLink
              className="btn btn-warning export_btn"
              filename={"cheque_management.csv"}
              data={csvData}
            >
              Export Data
            </CSVLink>
          </Col>

          <Col>
            <Button onClick={syncDriverOrdersHistory}>Sync</Button>
          </Col>

     
          <Col sm={2}>
            {tableEditStatus?.delivery_date ? (
              <Button variant="danger"  >   Edit Status : Closed </Button>
            ) : (
              <Button variant="success"  onClick={handelCloseDate}  > Edit Status : open </Button>
            )}
          </Col>


          {/* <Col sm={2}>
            {tableEditStatus?.delivery_date ? (
              <Button variant="danger">Edit Status : Closed</Button>
            ) : (
              <Button variant="success"> Edit Status : open</Button>
            )}
          </Col> */}

        </Row>
        {chequeTable.length > 0 ? <Table1 /> : ""}
      </div>

      {/* first and main model  */}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title> Cheque Details </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Order Number </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={uploadPayload.order_number}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Delivery Date </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={uploadPayload.delivery_date}
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Payment Cheque Number </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={uploadPayload.payment_cheque1_number}
                disabled
              />
            </Form.Group>

            <Form.Label> Name Of User </Form.Label>
            <Dropdown
              onSelect={(evt, evtKey) => {
                setUploadPayload({ ...uploadPayload, name_of_user: evt });
              }}
            >
              <Dropdown.Toggle id="dropdown-basic">
                {uploadPayload.name_of_user !== ""
                  ? uploadPayload.name_of_user
                  : "Name Of User"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="Govind"> Govind </Dropdown.Item>
                <Dropdown.Item eventKey="Pankaj"> Pankaj </Dropdown.Item>
                <Dropdown.Item eventKey="Viswas"> Viswas </Dropdown.Item>
                <Dropdown.Item eventKey="Omkar"> Omkar  </Dropdown.Item>
                <Dropdown.Item eventKey="Vaibhav"> Vaibhav  </Dropdown.Item>
                <Dropdown.Item eventKey="Tushar"> Tushar  </Dropdown.Item>
                <Dropdown.Item eventKey="Vishal"> Vishal  </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
            <br />
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Cheque amount Change </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={uploadPayload.cheque_amount_change}
                onChange={(e) =>
                  setUploadPayload({
                    ...uploadPayload,
                    cheque_amount_change: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Cheque Number Change </Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                value={uploadPayload.cheque_number_change}
                onChange={(e) =>
                  setUploadPayload({
                    ...uploadPayload,
                    cheque_number_change: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Physical Cheque Collected </Form.Label>

              <Dropdown 
               onSelect={(evt, evtKey) => {
                setUploadPayload({ ...uploadPayload, physical_cheque_collection: evt });
              }}
              >
                <Dropdown.Toggle variant="success"  >
                 { uploadPayload &&  uploadPayload.physical_cheque_collection == 1 ? "Yes" : "No" || "Select"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1">Yes</Dropdown.Item>
                  <Dropdown.Item eventKey="0">No</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {/* <Form.Check
                type="checkbox"
                placeholder=""
                defaultChecked={uploadPayload.physical_cheque_collection}
                onChange={(e) =>
                  setUploadPayload({
                    ...uploadPayload,
                    physical_cheque_collection:
                      e.target.checked == true ? "1" : "0",
                  })
                }
              /> */}
            </Form.Group>

            {uploadPayload.physical_cheque_collection == "1" ? (
              ""
            ) : (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label> Remarks </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={uploadPayload.remark}
                  onChange={(e) =>
                    setUploadPayload({
                      ...uploadPayload,
                      remark: e.target.value,
                    })
                  }
                />
              </Form.Group>
            )}

            {uploadPayload.physical_cheque_collection == "1" ? (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label> Cheque Deposit Date </Form.Label>
                <Form.Control
                  type="date"
                  placeholder=""
                  value={
                    uploadPayload?.cheque_deposit_date == null
                      ? null
                      : uploadPayload?.cheque_deposit_date.substring(0, 10)
                  }
                  onChange={(e) =>
                    setUploadPayload({
                      ...uploadPayload,
                      cheque_deposit_date: e.target.value,
                    })
                  }
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* second model  */}
      <Modal show={show2} onHide={handleClose2} size="xl">
        <Modal.Header closeButton>
          <Modal.Title> Payment Confirmation </Modal.Title>
        </Modal.Header>

        
        <Form style={{ padding: "1rem" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label> Order Number </Form.Label>
            <Form.Control
              type="text"
              value={uploadPayload2.order_number}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label> Delivery Date </Form.Label>
            <Form.Control
              type="text"
              value={uploadPayload2.delivery_date}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label> Cheque Processed </Form.Label>

            <DropdownButton
              id="dropdown-basic-button"
              title= { ChequeProcessed && ((ChequeProcessed == 0) ? "No" : "Yes")  || "Select"}
              onSelect={(e) => {
                if (e == "1"){
                  setErrorTypes("")
                  setUploadPayload2({
                    ...uploadPayload2,
                    order_number: "",
                    delivery_date: "",
                    cheque_bounces_date: "",
                    cheque_error: "",
                  });
                }
                setChequeProcessed(e);  
                setUploadPayload2({ ...uploadPayload2,  cheque_processed : e  }) }}
            >
              <Dropdown.Item eventKey="1"> Yes </Dropdown.Item>
              <Dropdown.Item eventKey="0"> No </Dropdown.Item>
            </DropdownButton>
          </Form.Group>

          {ChequeProcessed == "0" ? (
            <Form.Group className="mb-3" >
              <Form.Label> Reason </Form.Label>

              <DropdownButton
                id="dropdown-basic-button"
                title={ErrorTypes?.replace("_" , " ") || "Select"}
                onSelect={(e) => { 
                  setErrorTypes(e); 
                  setUploadPayload2({ ...uploadPayload2,  cheque_error : e  }) 
                }}
                variant="success"
              >
                <Dropdown.Item eventKey="Cheque_Bounced">
                  {" "}
                  Cheque Bounced{" "}
                </Dropdown.Item>
                <Dropdown.Item eventKey="Other_Payments">
                  {" "}
                  Other Payments{" "}
                </Dropdown.Item>
                <Dropdown.Item eventKey="Cheque_Error">
                  {" "}
                  Cheque Error{" "}
                </Dropdown.Item>
              </DropdownButton>
            </Form.Group>
          ) : (
            ""
          )}

          {ErrorTypes === "Cheque_Bounced" ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Cheque Bounced Date </Form.Label>
              <Form.Control
                type="date"
                value={uploadPayload2?.cheque_bounces_date}
                onChange={(e) =>
                  setUploadPayload2({
                    ...uploadPayload2,
                    cheque_bounces_date: e.target.value, 
                  })
                }
              />
            </Form.Group>
          ) : (
            ""
          )}

          {ErrorTypes === "Other_Payments" ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Payment Mode </Form.Label>

              <DropdownButton
              id="dropdown-basic-button"
              title={ uploadPayload2.payment_mode  ? uploadPayload2.payment_mode  : "Payment Mode" }
              onSelect={(e) => setUploadPayload2({ ...uploadPayload2,  payment_mode : e  }) }
            > 
              <Dropdown.Item eventKey="Cash"> Cash </Dropdown.Item>
              <Dropdown.Item eventKey="Cheque"> Cheque </Dropdown.Item>
              <Dropdown.Item eventKey="Online Payment"> Online Payment </Dropdown.Item>
            </DropdownButton>
            </Form.Group>
          ) : (
            ""
          )}

          {ErrorTypes === "Other_Payments" ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Payment Amount </Form.Label>
              <Form.Control
                type="number"
                value={uploadPayload2?.payment_amount}
                onChange={(e) =>
                  setUploadPayload2({
                    ...uploadPayload2,
                    payment_amount: e.target.value,
                  })
                }
              />
            </Form.Group>
          ) : (
            ""
          )}

          {ErrorTypes === "Cheque_Bounced" ||
          ErrorTypes === "Other_Payments" ||
          ErrorTypes === "Cheque_Error" ? (
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Remarks </Form.Label>
              <Form.Control
                type="text"
                value={uploadPayload2?.remark}
                onChange={(e) =>
                  setUploadPayload2({
                    ...uploadPayload2,
                    remark: e.target.value,
                  })
                }
              />
            </Form.Group>
          ) : (
            ""
          )}
        </Form>

   

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate2}>
            Save Changes
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}

export default ChequeManagement;


