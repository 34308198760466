import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button, Select } from "@mantine/core";

import { InventoryMovementsTable } from "./InventoryMovementsTable";
import NavBar from "../Navigation bar/Navbar";
import moment from "moment";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import {
  fetchInventoryReports,
  fetchPriceDetails,
} from "../../services/InventoryMovements.services";
import ExportToExcel from "../InventoryManagementExport/ExportToExcel";
import { warehouseNames } from "../../Configs/mysqlconfig";
import { warehouses,useWarehouseList } from '../../indexedDBUtility.js';

const Header = styled.div`
  display: flex;
  height: 18vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 1rem;
`;

const PageTitle = styled.div`
  font-weight: bolder;
  font-size: 2rem;
  text-align: center;
`;

const DateTitle = styled.div`
  text-align: center;
`;
const DateSelector = styled(DatePicker)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageContainer = styled.div`
  width: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  padding: 1rem;
  flex: 1;
`;

const WarehouseSelectContainer = styled.div`
  margin: 0 1rem;
  align-self: flex-end;
`;

const FetchDataButton = styled(Button)`
  margin-top: 1rem;
`;

const ButtonStyle = styled(Button)`
  margin-right: 1rem;
`;

const ExportBtnStyles = styled.div`
  margin-top: 1rem;
`;

// const warehouseList = [
//   { value: "All", label: "All" },
//   { value: "Goa", label: "Goa" },
//   { value: "Nashik", label: "Nashik" },
//   { value: "Vashi", label: "Vashi" },
//   { value: "Mira Road", label: "Mira Road" },
//   { value: "Wadala", label: "Wadala" },
//   { value: "Bhiwandi", label: "Bhiwandi" },
// ];

// const mapWarehouseList = (names) => {
//   return names.map(name => ({
//     value: name,
//     label: name
//   }));
// };

// const warehouseList = mapWarehouseList(warehouseNames);


function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (row.values[id] && row.values[id] !== "") options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// function getFooterSum(data, field) {
//   if (!data.rows || data.rows.length === 0 || !field) {
//     return 0;
//   }

//   let footerSum = data.rows.reduce((sum, row) => {
//     return Number(row.values[field]) + sum;
//   }, 0);
//   return footerSum.toFixed(1);
// }

function getFooterSum(data, field) {
  if (!data.rows || data.rows.length === 0 || !field) {
    return 0;
  }

  let footerSum = data.rows.reduce((sum, row) => {
    const value = row.values[field];
    const numericValue = value === "N/A" ? 0 : parseFloat(value);
    return sum + (isNaN(numericValue) ? 0 : numericValue);
  }, 0);
  
  return footerSum.toFixed(1);
}


const InventoryMovementsDetails = ({ history }) => {
  const [tableData, setTableData] = useState([]);
  const [dateRangeStart, setDateRangeStart] = useState(new Date());
  const [dateRangeEnd, setDateRangeEnd] = useState(new Date());

  const [,,warehouseList] = useWarehouseList();

  const [warehouse, setWarehouse] = useState("All");

  const [isLoading, setIsLoading] = useState(false);

  const greenColumnHeaderHighlight = "rgba(0,200,0,.6)";
  const redColumnHeaderHighlight = "rgba(200,0,0,.6)";

  const parentWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        Footer: "Total :",

        label: "Date",
        accessor: "date",
        value: "date",
      },
      {
        Header: "Item Id",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Sub Cat",
        label: "Sub Cat",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },

      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory ",
        label: "Inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },
      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "GRN Qty",
        label: "GRN Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Stock conversion",
        label: "Stock conversion",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_transfer",
        value: "stock_transfer",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Packaged Qty",
        label: "Packaged Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "converted_stock"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "converted_stock",
        value: "converted_stock",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "delivered_qty",
        value: "delivered_qty",
      },
      {
        Header: "Sample Qty",
        label: "Sample Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "sample_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "sample_qty",
        value: "sample_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to depo",
        label: "Shipped to depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_child"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "shipped_to_child",
        value: "shipped_to_child",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Return from depo",
        label: "Return from depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "return_from_child"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "return_from_child",
        value: "return_from_child",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Dumped Qty",
        label: "Dumped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "dumped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "dumped_qty",
        value: "dumped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },

      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () =>  (data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  const childWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        Footer: "Total :",

        label: "Date",
        accessor: "date",
        value: "date",
      },
      {
        Header: "Item Id",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Sub category",
        label: "Sub category",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",

        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },

      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },

      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },

      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to DC",
        label: "Shipped to Bhiwandi",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_parent"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_parent",
        value: "shipped_to_parent",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  const goaWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        Footer: "Total :",

        label: "Date",
        accessor: "date",
        value: "date",
      },
      {
        Header: "Item Id",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Sub category",
        label: "Sub category",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Last day closing inventory ",
        label: "Last day closing inventory ",

        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },

      {
        Header: "Inventory",
        label: "Inventory",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },

      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },

      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },

      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  const allWarehouseColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        Footer: "Total :",

        label: "Date",
        accessor: "date",
        value: "date",
      },
      {
        Header: "Item Id",
        label: "Item Id",
        accessor: "item_id",
        value: "item_id",
        width: 90,
      },
      {
        Header: "Item Description || Brand",
        label: "Item Description || Brand",
        accessor: "desc_brand",
        value: "desc_brand",
        width: 180,
      },
      {
        Header: "Category",
        label: "Category",
        accessor: "category",
        value: "category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },
      {
        Header: "Sub Cat",
        label: "Sub Cat",
        accessor: "sub_category",
        value: "sub_category",
        Filter: SelectColumnFilter,
        filter: "equals",
        width: 160,
      },

      {
        Header: "Last day closing inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "last_day_closing_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        label: "Last day closing inventory ",
        accessor: "last_day_closing_inventory",
        value: "last_day_closing_inventory",
      },
      {
        Header: "Inventory After Shipped",
        label: "Inventory After Shipped",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_after_shipped"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_after_shipped",
        value: "inventory_after_shipped",
      },
      {
        Header: "Inventory ",
        label: "Inventory ",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory",
        value: "resultant_inventory",
      },
      {
        Header: "Inventory in KGs",
        label: "Inventory in KGs",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "resultant_inventory_weight"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "resultant_inventory_weight",
        value: "resultant_inventory_weight",
      },

      {
        Header: "GRN Qty",
        label: "GRN Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "grn_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "grn_qty",
        value: "grn_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Depo Inward",
        label: "Depo Inward",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "child_grn"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "child_grn",
        value: "child_grn",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Stock conversion",
        label: "Stock conversion",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_transfer"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_transfer",
        value: "stock_transfer",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Packaged Qty",
        label: "Packaged Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "converted_stock"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "converted_stock",
        value: "converted_stock",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped Qty",
        label: "Shipped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_qty",
        value: "shipped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Delivered Qty",
        label: "Delivered Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "delivered_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "delivered_qty",
        value: "delivered_qty",
      },
      {
        Header: "Sample Qty",
        label: "Sample Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "sample_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "sample_qty",
        value: "sample_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Returned Qty",
        label: "Returned Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "returned_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "returned_qty",
        value: "returned_qty",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Customer old returns",
        label: "Customer old returns",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "customer_returns"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "customer_returns",
        value: "customer_returns",
        bgColor: greenColumnHeaderHighlight,
      },
      {
        Header: "Shipped to DC",
        label: "Shipped to Bhiwandi",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_parent"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_parent",
        value: "shipped_to_parent",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Shipped to depo",
        label: "Shipped to depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "shipped_to_child"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "shipped_to_child",
        value: "shipped_to_child",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Return from depo",
        label: "Return from depo",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "return_from_child"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "return_from_child",
        value: "return_from_child",
        bgColor: greenColumnHeaderHighlight,
      },

      {
        Header: "Dumped Qty",
        label: "Dumped Qty",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "dumped_qty"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "dumped_qty",
        value: "dumped_qty",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Stock correction",
        label: "Stock correction",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_correction"),
            [data.rows]
          );

          return <>{total}</>;
        },
        accessor: "stock_correction",
        value: "stock_correction",
      },
      {
        Header: "Stock discrepancy",
        label: "Stock discrepancy",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_discrepancy"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_discrepancy",
        value: "stock_discrepancy",
      },
      {
        Header: "Stock movement",
        label: "Stock movement",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "stock_movement"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "stock_movement",
        value: "stock_movement",
        bgColor: redColumnHeaderHighlight,
      },
      {
        Header: "Inventory Value",
        label: "Inventory Value",
        Footer: (data) => {
          const total = React.useMemo(
            () => getFooterSum(data, "inventory_value"),
            [data.rows]
          );

          return <>{total}</>;
        },

        accessor: "inventory_value",
        value: "inventory_value",
      },
    ],
    []
  );

  function getInventoryMovementsTableData() {
    setIsLoading(true);

    Promise.all([
      fetchInventoryReports(
        moment(dateRangeStart).format("YYYY-MM-DD"),
        moment(dateRangeEnd).format("YYYY-MM-DD"),
        warehouse
      ),
      fetchPriceDetails(),
    ])

      .then((result) => {
        const inventoryMovementsData = result[0].data;
        const itemPriceDetails = result[1].data;
        // console.log("inventory details", inventoryMovementsData);
        inventoryMovementsData.forEach((item) => {
          const item_price =
          itemPriceDetails[item.item_id]?.landing_cost || "N/A";
          const converted_stock = parseFloat(item.converted_stock || 0);
          const reverse_stock_transfer = parseFloat(item.reverse_stock_transfer || 0);

          item.shipped_qty = (item.shipped_qty || 0).toFixed(2);
          item.delivered_qty = (item.delivered_qty || 0).toFixed(2);
          item.customer_returns = (item.customer_returns || 0).toFixed(2);
          item.stock_correction = (item.stock_correction || 0).toFixed(2);
          item.shipped_to_parent = (item.shipped_to_parent || 0).toFixed(2);
          item.shipped_to_child = (item.shipped_to_child || 0).toFixed(2);
          item.returned_qty = (item.returned_qty || 0).toFixed(2);
          item.return_from_child = (item.return_from_child || 0).toFixed(2);
          item.dumped_qty = (item.dumped_qty || 0).toFixed(2);
          item.grn_qty = (item.grn_qty || 0).toFixed(2);
          item.stock_take = (item.stock_take || 0).toFixed(2);
          item.bag_weight_loss = (item.bag_weight_loss || 0).toFixed(2);
          item.stock_transfer = parseFloat(item.stock_transfer || 0) !== 0 ? parseFloat(item.stock_transfer).toFixed(2) 
                    :(parseFloat(item.reverse_converted_stock || 0) !== 0 ? parseFloat(item.reverse_converted_stock).toFixed(2) : 0);
          item.converted_stock = (converted_stock !== 0 && reverse_stock_transfer !== 0)
                              ? (converted_stock + reverse_stock_transfer).toFixed(2)
                              : (converted_stock !== 0
                                ? converted_stock.toFixed(2)
                                : (reverse_stock_transfer !== 0
                                  ? reverse_stock_transfer.toFixed(2)
                                  : 0));
          item.wastage = (item.wastage || 0).toFixed(2);
          item.sample_qty = (item.sample_qty || 0).toFixed(2);
          item.inventory_value = "N/A";
          item.last_day_closing_inventory =
            item.last_day_closing_inventory.toFixed(2);
          item.inventory_after_shipped =
            item.inventory_after_shipped.toFixed(2);
          item.resultant_inventory = item.resultant_inventory.toFixed(2);
          item.resultant_inventory_weight = Number(
            item.resultant_inventory_weight
          ).toFixed(2);
          if (item_price !== "N/A") {
            item.inventory_value = Number(
              item.resultant_inventory_weight * item_price
            ).toFixed(2);
          }


          item.desc_brand = `${item.item_disc || ""} -- ${item.brand || ""}`;
        });

        setTableData(inventoryMovementsData);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error : ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  }

  useEffect(() => {
    if (warehouse && dateRangeStart && dateRangeEnd)
      getInventoryMovementsTableData();
  }, [warehouse, dateRangeStart, dateRangeEnd]);

  const [,,,,,,,,,DC_list] = useWarehouseList();

  return (
    <>
      <NavBar />
      <br />
      <br />
      <br />
      <PageContainer>
        <Header>
          <PageTitle> Inventory Reports</PageTitle>
          <DateContainer>
            <DateTitle>Start Date</DateTitle>
            <DateSelector
              selected={dateRangeStart}
              onChange={setDateRangeStart}
              maxDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <DateContainer>
            <DateTitle>End Date</DateTitle>
            <DateSelector
              selected={dateRangeEnd}
              onChange={setDateRangeEnd}
              maxDate={new Date()}
              // dateFormat="dd/MM/yyyy"
            />
          </DateContainer>
          <WarehouseSelectContainer>
            <Select
              label="Warehouse"
              placeholder="Select"
              value={warehouse}
              data={warehouseList}
              onChange={setWarehouse}
            />
          </WarehouseSelectContainer>
          {/* <FetchDataButton
            variant="gradient"
            gradient={{ from: "teal", to: "lime", deg: 105 }}
            onClick={() => getInventoryMovementsTableData()}
          >
            Fetch Records
          </FetchDataButton> */}
          <ExportBtnStyles>
            <ExportToExcel
              sheet1={tableData}
              filename={"Inventory_movements"}
              sheet1Headers={
                DC_list.includes(warehouse)
                  ? parentWarehouseColumns
                  : warehouse === "All"
                  ? allWarehouseColumns
                  : warehouse === "Goa"
                  ? goaWarehouseColumns
                  : childWarehouseColumns
              }
              sheet1Name={warehouse}
            />
          </ExportBtnStyles>
        </Header>

        <TableContainer>
          <InventoryMovementsTable
            tableData={tableData}
            parentWarehouseColumns={parentWarehouseColumns}
            childWarehouseColumns={childWarehouseColumns}
            goaWarehouseColumns={goaWarehouseColumns}
            allWarehouseColumns={allWarehouseColumns}
            warehouse={warehouse}
            history={history}
          />
        </TableContainer>
      </PageContainer>
      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </>
  );
};

export default InventoryMovementsDetails;
