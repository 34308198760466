import React, { useState, useEffect, useRef } from "react";
import { service_url, token } from "../../Configs/mysqlconfig";
import NavBar from "../Navigation bar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import fire from "../../Configs/firebase";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import { InputGroupAddon } from "reactstrap";
import { Form, InputGroup, Button, Table } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { getUserType, validateIsAdminUser } from "../UserCredentials";
import TransitRouteCreationModal from "./TransitRouteCreationModal";
import TransitRouteItemsModal from "./TransitRouteItemsModal";
import DiscrepancyItemsModal from "./DiscrepancyItemsModal";
import ExportToExcel from "../InventoryManagementExport/TransitStockTransferExport/ExportToExcel";
import StockMovementCreationModal from "./StockMovementCreationModal";
import StockMovementItemsModal from "./StockMovementItemsModal";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

const TransitStockTransfer = (props) => {
  const [selectedDataType, setSelectedDataType] = useState("transit");
  const [productMasterDataRaw, setProductMasterDataRaw] = useState({});
  const [productMasterDataRawFresh, setProductMasterDataRawFresh] = useState(
    {}
  );

  const [addTransitItemDropdownList, setAddTransitItemDropdownList] = useState(
    []
  );
  const [transitExportData, setTransitExportData] = useState([]);
  const [transitExportLooseItemsData, setTransitExportLooseItemsData] =
    useState([]);
  const [transitExportHighValueData, setTransitExportHighValueData] = useState([]);

  const [deliveryDate, setDeliveryDate] = useState();
  const [transitDate, setTransitDate] = useState();
  const [bagItemsById, setBagItemsById] = useState({});
  const [receivingDate, setReceivingDate] = useState();
  const [routeData, setRouteData] = useState({});
  const [isFreshItems, setIsFreshItems] = useState(false);
  const [transitID, setTransitID] = useState();
  const [itemsPopupShow, setItemsPopupShow] = useState(false);
  const [getTransitReceivingDate, setTransitReceivingDate] = useState("");
  const [stockMovementItemsPopupShow, setStockMovementItemsPopupShow] =
    useState(false);
  const [transitCreatePopupShow, setTransitCreatePopupShow] = useState(false);
  const [discrepancyPopupShow, setDiscrepancyPopupShow] = useState(false);
  const [stockMovementCreationPopUpShow, setStockMovementCreationPopUpShow] =
    useState(false);
  const [isStockMovementRecord, setIsStockMovementRecord] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const userCity =
    sessionStorage.getItem("userCity") || GLOBAL_PARENT_WAREHOUSE;
  const userType = sessionStorage.getItem("userType") || "";

  const CustomDateInput = ({ value, onClick }) => (
    <input
      value={value}
      onClick={onClick}
      style={{ border: "solid 1px black", height: "30px" }}
    />
  );

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const fetchProductDataFromDB = () => {
    return new Promise((resolve, reject) => {
      let freshData = {};
      let product_base_ref = fire.database().ref("Product_Master_New/F");
      product_base_ref.once(
        "value",
        (snapshot) => {
          let freshData = snapshot.val();
          product_base_ref = fire.database().ref("Product_Master_New/A");
          product_base_ref.once(
            "value",
            (snapshot) => {
              let GTData = snapshot.val();
              resolve([GTData, freshData]);
            },
            (err) => {
              reject("error occured while fetching data from firebase");
            }
          );
        },
        (err) => {
          reject("error occured while fetching data from firebase");
        }
      );
    });
  };

  const fetchStockTransferRecords = () => {
    setBackDrop(true);
    let date = "";
    let url = "";

    if (selectedDataType == "delivery") {
      date = formatDate(new Date(deliveryDate));
      url = `${service_url}/api/transit-stock-transfer?delivery_date=${date}&token=${token}`;
    } else if (selectedDataType == "transit") {
      date = formatDate(new Date(transitDate));
      url = `${service_url}/api/transit-stock-transfer?transit_date=${date}&token=${token}`;
    } else if (selectedDataType == "receiving") {
      date = formatDate(new Date(receivingDate));
      url = `${service_url}/api/transit-stock-transfer?receiving_date=${date}&token=${token}`;
    }

    console.log(url);

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then(async (result) => {
        let stockTransferData = result.data || [];
        // console.log(stockTransferData);

        let stockTransferObj = {};
        for (let stockTransfer of stockTransferData) {
          if (
            userType !== "Admin" &&
            !(userType === "Operation" && userCity === "") &&
            userCity !== GLOBAL_PARENT_WAREHOUSE &&
            stockTransfer.destination !== userCity
          ) {
            //do nothing
          } else {
            let transitId = stockTransfer.id;
            let route = stockTransfer.route;
            let received_qty = stockTransfer.received_qty;

            if (!stockTransferObj[transitId]) {
              stockTransferObj[transitId] = stockTransfer;
              stockTransferObj[transitId].isDeleteAvailable = true;
            } else {
              stockTransferObj[transitId].route += `, ${route}`;

              stockTransferObj[transitId].is_received =
                Number(stockTransferObj[transitId].is_received) >
                Number(stockTransfer.is_received)
                  ? stockTransfer.is_received
                  : stockTransferObj[transitId].is_received;
            }
            if (received_qty) {
              stockTransferObj[transitId].isDeleteAvailable = false;
            }
          }
        }

        const stockMovementObj = await fetchStockMovementRecords();
        const _routeData = { ...stockTransferObj, ...stockMovementObj };
        console.log("check data", _routeData);
        setBackDrop(false);
        setRouteData(_routeData);
      })
      .catch((err) => {
        toast(`Error in fetching transit routes: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const fetchStockMovementRecords = async () => {
    const date = formatDate(new Date(transitDate));
    const url = `${service_url}/api/transit-stock-movements?transit_date=${date}&token=${token}`;
    return fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let stockMovementData = result.data || [];
        console.log(stockMovementData);

        // console.log("check stock movement data", stockMovementData);
        let stockMovementObj = {};
        for (let stockTransfer of stockMovementData) {
          if (
            userType !== "Admin" &&
            !(userType === "Operation" && userCity === "") &&
            userCity !== GLOBAL_PARENT_WAREHOUSE &&
            stockTransfer.destination !== userCity
          ) {
            //do nothing
          } else {
            let transitId = stockTransfer.id;
            let route = stockTransfer.route;
            let received_qty = stockTransfer.received_qty;

            if (!stockMovementObj[transitId]) {
              stockMovementObj[transitId] = stockTransfer;
              stockMovementObj[transitId].isDeleteAvailable = true;
            } else {
              stockMovementObj[transitId].route += `, ${route}`;
            }
            if (received_qty) {
              stockMovementObj[transitId].isDeleteAvailable = false;
            }

            stockMovementObj[transitId].isStockMovement = true;
          }
        }

        setBackDrop(false);
        // console.log("new route data", stockMovementObj);
        return stockMovementObj;
      })
      .catch((err) => {
        toast(`Error in fetching transit routes: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const fetchTransitDataForExport = () => {
    const transit_id_list = Object.keys(routeData).join(",");
    const url = `${service_url}/api/planned-transit-items?&transit_id_list=${transit_id_list}&token=${token}`;
    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        const transitData = result.data;
        const transitLooseData = result.bag_data;
        const transitHighValueData = result.data;
        const _bagItemsById = {};

        for (let item of transitLooseData) {
          item.receivedToggleVal =
            item.received_qty === null
              ? "none"
              : Number(item.received_qty) === 0
              ? "N"
              : Number(item.received_qty) > 0
              ? "Y"
              : "none";
          item.approvedToggleVal =
            item.approved_qty === null
              ? "none"
              : Number(item.approved_qty) === 0
              ? "N"
              : Number(item.approved_qty) > 0
              ? "Y"
              : "none";
          const bag_id = item.bag_id;

          if (bag_id) {
            if (!_bagItemsById[bag_id]) {
              _bagItemsById[bag_id] = [];
            }
            _bagItemsById[bag_id].push(item);
          }
        }

        let filterTransitData=transitHighValueData.filter((item)=>item.variety!=="HIGH VALUE");
        console.log(filterTransitData);        

        let filterTransitHighValueData=transitHighValueData.filter((item)=>item.variety=="HIGH VALUE");
        console.log(filterTransitHighValueData);

        setTransitExportData(filterTransitData);
        setBagItemsById(_bagItemsById);
        setTransitExportLooseItemsData(transitLooseData);
        setTransitExportHighValueData(filterTransitHighValueData);
      })
      .catch((err) => {
        toast(`Error in fetching data for export: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  useEffect(() => {
    generateProductMasterData();
    setTransitDate(new Date());
  }, []);

  useEffect(() => {
    if (Object.keys(routeData).length > 0) fetchTransitDataForExport();
  }, [routeData]);

  useEffect(() => {
    if (deliveryDate || transitDate || receivingDate) {
      fetchStockTransferRecords();
    }
  }, [deliveryDate, transitDate, receivingDate]);

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },

      {
        label: "Destination",
        value: "destination",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Shipped Qty",
        value: "item_modified_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );

  const excelHeadersLooseItems = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Order No",
        value: "order_number",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },
      {
        label: "Description",
        value: "item_disc",
      },

      {
        label: "Destination",
        value: "destination",
      },
      {
        label: "Bag Id",
        value: "bag_id",
      },
      {
        label: "Quantity",
        value: "item_modified_qty",
      },
      {
        label: "Received",
        value: "received",
      },
      {
        label: "Approved",
        value: "approved",
      },
    ],
    []
  );

  const excelHeadersHighValue = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },

      {
        label: "Destination",
        value: "destination",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Shipped Qty",
        value: "item_modified_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
      {
        label:"Crate Id",
        value:"crate_id",
      }
    ],
    []
  );

  const refetchData = () => {
    fetchStockTransferRecords();
  };

  const generateProductMasterData = async () => {
    const products = await fetchProductDataFromDB();
    generateTransitItemsList(products[0]);
    setProductMasterDataRaw(products[0]);
    setProductMasterDataRawFresh(products[1]);
  };

  const generateTransitItemsList = (product_master) => {
    if (!product_master || Object.keys(product_master).length === 0) {
      return console.log("Function did not receive proper arguements");
    }
    const itemList = [];
    Object.keys(product_master).forEach((key, index) => {
      const product = product_master[key];
      const item_id = product.item_id;
      const item_description = product.item_description1 || "";
      const brand = product.brand || "";
      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_description}`,
      };
      itemList.push(item);
    });

    setAddTransitItemDropdownList(itemList);
  };

  const deleteTransitRoute = (transit_id) => {
    console.log(transit_id);
    let url = `${service_url}/api/transit-stock-transfer?token=${token}`;

    let details = {
      transit_id: transit_id,
    };

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(details),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });

          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in deleting transit route: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };
  const deleteStockMovement = (transit_id) => {
    console.log(transit_id);
    let url = `${service_url}/api/transit-stock-movements?token=${token}`;

    let details = {
      transit_id: transit_id,
    };

    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(details),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });

          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        toast(`Error in deleting stock movement: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const TransitRoutesTableHeader = () => {
    return (
      <thead className="thead-light">
        <tr>
          <th>Transit Date</th>
          <th>Delivery Date</th>
          <th>Receiving Date</th>
          <th>Parent</th>
          <th>Child</th>
          <th>Type</th>
          <th>Route</th>
          <th>Items</th>
          <th>Delete</th>
          <th>Print</th>
        </tr>
      </thead>
    );
  };

  const TransitRoutesTableBody = () => {
    let rows = Object.keys(routeData).map((key, index) => {
      let row = routeData[key] || {};
      return (
        <tr
          style={{
            backgroundColor: row.is_received == -1 ? "#f79a97" : "#96f2a7",
          }}
          key={index}
        >
          <td>{formatDate(row.transit_date)}</td>
          <td>
            {row.delivery_date
              ? formatDate(row.delivery_date)
              : row.isStockMovement
              ? "N/A"
              : ""}
          </td>
          <td>{formatDate(row.receiving_date)}</td>
          <td>{row.src}</td>
          <td>{row.destination}</td>
          <td>{row.type || " "}</td>
          <td>
            {row.route ? (
              row.route
            ) : row.isStockMovement ? (
              <p style={{ color: "blue" }}>Stock movement</p>
            ) : (
              ""
            )}
          </td>
          <td>
            <Button
              onClick={() => {
                setTransitID(row.id);
                setWarehouse(row.destination);
                setIsFreshItems(row.route === "FRESH");
                setTransitReceivingDate(row.receiving_date);
                if (row.isStockMovement) {
                  setStockMovementItemsPopupShow(true);
                  setIsStockMovementRecord(true);
                } else {
                  setItemsPopupShow(true);
                  setIsStockMovementRecord(false);
                }
              }}
            >
              Items
            </Button>
          </td>
          <td>
            {formatDate(transitDate) == formatDate(new Date()) ? (
              row.isDeleteAvailable ? (
                userType.toLowerCase() === "admin" ||
                userType.toLowerCase() === "systems" ? (
                  <Button
                    onClick={() => {
                      if (row.isStockMovement) {
                        deleteStockMovement(row.id);
                      } else {
                        deleteTransitRoute(row.id);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null
              ) : null
            ) : null}
          </td>
          {row.isStockMovement ? (
            userType === "Admin" ? (
              <td>
                <Button
                  onClick={() => {
                    props.history.push(
                      `/stock-movement-print?transitId=${row.id}`
                    );
                  }}
                >
                  Print
                </Button>
              </td>
            ) : null
          ) : null}
        </tr>
      );
    });

    return (
      <>
        <tbody>{rows}</tbody>
      </>
    );
  };

  const handleDeliveryDateChange = (selecteddate) => {
    setDeliveryDate(new Date(selecteddate));
  };

  const handleReceivingDateChange = (selecteddate) => {
    setReceivingDate(new Date(selecteddate));
  };

  const handleTransitDateChange = (selecteddate) => {
    setTransitDate(new Date(selecteddate));
  };

  return (
    <>
      <NavBar />

      <div className="container col-sm-12" style={{ paddingTop: 100 }}>
        <center>
          <h2 className="h2">Transit Stock Transfer</h2>
        </center>
        <Form inline>
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">Date Type</InputGroupAddon>
            <div style={{ width: "200px" }}>
              <Select
                size="sm"
                options={[
                  { value: "delivery", label: "Delivery Date" },
                  { value: "transit", label: "Transit Date" },
                  { value: "receiving", label: "Receiving Date" },
                ]}
                placeholder={selectedDataType}
                onChange={(val) => {
                  setSelectedDataType(val.value);
                }}
              />
            </div>
          </InputGroup>

          {selectedDataType == "transit" && (
            <InputGroup size="sm" style={{ margin: "20px" }}>
              <InputGroupAddon addonType="prepend">
                Transit Date
              </InputGroupAddon>
              <DatePicker
                selected={transitDate}
                onChange={handleTransitDateChange}
                minDate={new Date("2017/01/01")}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
              />
            </InputGroup>
          )}

          {selectedDataType == "delivery" && (
            <InputGroup size="sm" style={{ margin: "20px" }}>
              <InputGroupAddon addonType="prepend">
                Delivery Date
              </InputGroupAddon>
              <DatePicker
                selected={deliveryDate}
                onChange={handleDeliveryDateChange}
                minDate={new Date("2017/01/01")}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
              />
            </InputGroup>
          )}
          {selectedDataType == "receiving" && (
            <InputGroup size="sm" style={{ margin: "20px" }}>
              <InputGroupAddon addonType="prepend">
                Receiving Date
              </InputGroupAddon>
              <DatePicker
                selected={receivingDate}
                onChange={handleReceivingDateChange}
                minDate={new Date("2017/01/01")}
                aria-describedby="basic-addon1"
                dateFormat="dd/MM/yyyy"
                customInput={<CustomDateInput />}
              />
            </InputGroup>
          )}

          {!userCity ||
          userCity === "" ||
          userCity === GLOBAL_PARENT_WAREHOUSE ? (
            <Button
              size="sm"
              style={{ marginLeft: "300px", marginRight: "10px" }}
              onClick={() => {
                setTransitCreatePopupShow(true);
              }}
            >
              Create Transit Record
            </Button>
          ) : null}

          <Button
            size="sm"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setStockMovementCreationPopUpShow(true);
            }}
          >
            Create Stock Movement
          </Button>

          <ExportToExcel
            sheet1={transitExportData}
            sheet2={transitExportLooseItemsData}
            sheet3={transitExportHighValueData}
            filename={"transit_transfer_complete_summary"}
            sheet1Headers={excelHeaders}
            sheet2Headers={excelHeadersLooseItems}
            sheet3Headers={excelHeadersHighValue }
            bagItemsById={bagItemsById}
            sheet1Name="Items"
            sheet2Name="Loose Items"
            sheet3Name="High Value"
            buttonText="Export All"
          />

          {/* <Button
            size="sm"
            style={{ marginRight: "10px" }}
            onClick={() => {
              setDiscrepancyPopupShow(true);
            }}
          >
            Show Discrepancies
          </Button> */}
        </Form>

        {Object.keys(routeData).length > 0 ? (
          <Table
            striped
            bordered
            hover
            style={{ marginTop: 10, textAlign: "center" }}
          >
            <TransitRoutesTableHeader />
            <TransitRoutesTableBody />
          </Table>
        ) : (
          <center>
            <div>No transit data</div>
          </center>
        )}

        {backDrop ? <Backdrop parentLoadStatus={backDrop} /> : null}

        {transitCreatePopupShow == true ? (
          <TransitRouteCreationModal
            show={transitCreatePopupShow}
            onHide={() => setTransitCreatePopupShow(false)}
          />
        ) : null}

        {itemsPopupShow == true ? (
          <TransitRouteItemsModal
            dateType={selectedDataType}
            delivery_date={deliveryDate}
            transit_date={transitDate}
            show={itemsPopupShow}
            transitID={transitID}
            getTransitReceivingDate={getTransitReceivingDate}
            warehouse={warehouse}
            productMasterDataRaw={
              isFreshItems ? productMasterDataRawFresh : productMasterDataRaw
            }
            refetchData={refetchData}
            onHide={() => setItemsPopupShow(false)}
          />
        ) : null}
        {stockMovementItemsPopupShow == true ? (
          <StockMovementItemsModal
            dateType={selectedDataType}
            delivery_date={deliveryDate}
            transit_date={transitDate}
            show={stockMovementItemsPopupShow}
            transitID={transitID}
            warehouse={warehouse}
            addTransitItemDropdownList={addTransitItemDropdownList}
            productMasterDataRaw={productMasterDataRaw}
            refetchData={refetchData}
            onHide={() => setStockMovementItemsPopupShow(false)}
          />
        ) : null}
        {discrepancyPopupShow == true ? (
          <DiscrepancyItemsModal
            dateType={selectedDataType}
            delivery_date={deliveryDate}
            transit_date={transitDate}
            show={discrepancyPopupShow}
            transitID={transitID}
            warehouse={warehouse}
            addTransitItemDropdownList={addTransitItemDropdownList}
            productMasterDataRaw={productMasterDataRaw}
            refetchData={refetchData}
            onHide={() => setDiscrepancyPopupShow(false)}
          />
        ) : null}
        {stockMovementCreationPopUpShow == true ? (
          <StockMovementCreationModal
            warehouse={warehouse.value || ""}
            show={stockMovementCreationPopUpShow}
            onHide={() => setStockMovementCreationPopUpShow(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default TransitStockTransfer;
