import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Table, Form, Alert, Button, Modal, Row, Col } from "react-bootstrap";
import moment from "moment";
import { service_url, token } from "../../Configs/mysqlconfig";
import { toast } from "react-toastify";
import Select from "react-select";
import TransitItemsTable from "./Tables/TransitItemsTable";
import TransitHighValueTable from "./Tables/TransitHighValueTable";
import {
  redHighlightColor,
  greenHighlightColor,
  orangeHighlightColor,
  sortByTypeCategoryItemId,
} from "../Inventory/common/constants";

import { Button as MantineButton } from "@mantine/core";
import Backdrop from "../backdrop/backdrop";
import ExportToExcel from "../InventoryManagementExport/TransitStockTransferExport/ExportToExcel";
import ShowDiscrepancyCheckbox from "../Inventory/common/ShowDiscrepancyCheckbox";
import TransitLooseItemsTable from "./Tables/TransitLooseItemsTable";
import { icons } from "react-icons";
import { ContactSupportOutlined, Refresh } from "@material-ui/icons";
const GLOBAL_PARENT_WAREHOUSE = "Bhiwandi";
const userCity = sessionStorage.getItem("userCity") || "";
const userType = sessionStorage.getItem("userType") || "";

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function TransitRouteItemsModal({
  transitID,
  getTransitReceivingDate,
  ...props
}) {
  const [routeDropDownValues, setRouteDropDownValues] = useState([]);
  const [currentSelectedRoute, setCurrentSelectedRoute] = useState("");
  const [highValueTableData, setHighValueTableData] = useState({});
  const [highValueTableDataCopy, setHighValueTableDataCopy] = useState({});
  const [loosePackingTableDataCopy, setLoosePackingTableDataCopy] = useState(
    {}
  );
  const [loosePackingTableData, setLoosePackingTableData] = useState({});
  const [bagItemsById, setBagItemsById] = useState({});
  const [overallRouteSummaryDetailsCopy, setOverallRouteSummaryDetailsCopy] =
    useState({});
  const [overallRouteSummaryDetails, setOverallRouteSummaryDetails] = useState(
    {}
  );
  const [isApprovedQtyFieldsShown, setIsApprovedQtyFieldsShown] =
    useState(false);
  const [isApprovedFieldsShownLoose, setIsApprovedFieldsShownLoose] =
    useState(false);
    const [isApprovedFieldsShownHigh, setIsApprovedFieldsShownHigh] =
    useState(false);
  const [isApprovedQtyFieldsEditable, setIsApprovedQtyFieldsEditable] =
    useState(false);
    const [isApprovedQtyFieldsEditableHigh, setIsApprovedQtyFieldsEditableHigh] =
    useState(false);
  const [isApprovedFieldsEditableLoose, setIsApprovedFieldsEditableLoose] =
    useState(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDiscrepancyItemsChecked, setShowDiscrepancyItemsChecked] =
    useState(false);

  const productMasterDataRaw = props.productMasterDataRaw;
  const addTransitItemDropdownList = Object.keys(productMasterDataRaw).map(
    (key, index) => {
      const product = productMasterDataRaw[key];
      const item_id = product.item_id;
      const item_description = product.item_description1 || "";
      const brand = product.brand || "";
      const item = {
        value: item_id,
        label: `${item_id}-${brand}-${item_description}`,
      };
      return item;
    }
  );

  const refetchData = props.refetchData;
  const warehouse = props.warehouse;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_LEFT,
  });

  const setApprovedFieldsStatus = (_route, _overallRouteSummaryDetails) => {
    let _isApprovedFieldsShown = false;
    let _isApprovedFieldsEditable = true;

    for (let routeItems of _overallRouteSummaryDetails[_route]) {
      if (routeItems.received_qty != null && routeItems.received_qty >= 0) {
        _isApprovedFieldsShown = true;
      }
      if (routeItems.approved_qty != null && routeItems.approved_qty >= 0) {
        _isApprovedFieldsEditable = false;
      }
    }
    setIsApprovedQtyFieldsShown(_isApprovedFieldsShown);
    setIsApprovedQtyFieldsEditable(_isApprovedFieldsEditable);
  };

  const setHighApprovedFieldsStatus = (_route, _highValueTableData) => {
    let _isApprovedFieldsShownHigh = false;
    let _isApprovedFieldsEditableHigh = true;

    for (let routeItems of _highValueTableData[_route]) {
      if (routeItems.received_qty != null && routeItems.received_qty >= 0) {
        _isApprovedFieldsShownHigh = true;
      }
      if (routeItems.approved_qty != null && routeItems.approved_qty >= 0) {
        _isApprovedFieldsEditableHigh = false;
      }
    }


    setIsApprovedFieldsShownHigh(_isApprovedFieldsShownHigh);
    setIsApprovedQtyFieldsEditableHigh(_isApprovedFieldsEditableHigh);
  };

  const setLooseApprovedFieldsStatus = (_route, _loosePackingTableData) => {
    let _isApprovedFieldsShownLoose = false;
    let _isApprovedFieldsEditableLoose = true;

    if (!_loosePackingTableData[_route]) {
      return;
    }

    for (let routeItems of _loosePackingTableData[_route]) {
      if (
        routeItems.receivedToggleVal &&
        (routeItems.receivedToggleVal === "Y" ||
          routeItems.receivedToggleVal === "N")
      ) {
        _isApprovedFieldsShownLoose = true;
      }

      if (
        routeItems.approvedToggleVal &&
        (routeItems.approvedToggleVal === "Y" ||
          routeItems.approvedToggleVal === "N")
      ) {
        _isApprovedFieldsEditableLoose = false;
      }
    }

    setIsApprovedFieldsShownLoose(_isApprovedFieldsShownLoose);

    setIsApprovedFieldsEditableLoose(_isApprovedFieldsEditableLoose);
  };

  const getApprovedFieldsStatus = (_route, _overallRouteSummaryDetails) => {
    let _isApprovedFieldsShown = false;
    let _isApprovedFieldsEditable = true;

    for (let routeItems of _overallRouteSummaryDetails[_route]) {
      if (routeItems.received_qty != null && routeItems.received_qty >= 0) {
        _isApprovedFieldsShown = true;
      }
      if (routeItems.approved_qty != null && routeItems.approved_qty >= 0) {
        _isApprovedFieldsEditable = false;
      }
    }

    // if (!_isApprovedFieldsEditable && userType === "Admin") {
    //   _isApprovedFieldsEditable = true;
    // }

    return [_isApprovedFieldsShown, _isApprovedFieldsEditable];
  };

  const getApprovedFieldsStatusHigh = (_route, _highValueTableData) => {
    let _isApprovedFieldsShownHigh = false;
    let _isApprovedFieldsEditableHigh = true;

    for (let routeItems of _highValueTableData[_route]) {
      if (routeItems.received_qty != null && routeItems.received_qty >= 0) {
        _isApprovedFieldsShownHigh = true;
      }
      if (routeItems.approved_qty != null && routeItems.approved_qty >= 0) {
        _isApprovedFieldsEditableHigh = false;
      }
    }

    return [_isApprovedFieldsShownHigh, _isApprovedFieldsEditableHigh];
  };

  const isBhiwandiUser = () => {
    if (
      (userType === "Operation" && userCity === "") ||
      (userType === "Operation" && userCity === "Bhiwandi") ||
      userCity === "Bhiwandi"
    ) {
      return true;
    }
    return false;
  };

  const itemAlreadyInList = (item_id) => {
    const existingItems = overallRouteSummaryDetails[currentSelectedRoute];
    let itemAlreadyPresent = false;
    existingItems.forEach((item, index) => {
      if (Number(item_id) === Number(item.item_id)) {
        itemAlreadyPresent = true;
      }
    });

    return itemAlreadyPresent;
  };

  const init = () => {
    setIsLoading(true);
    let url = `${service_url}/api/planned-transit-items?transit_id=${transitID}&token=${token}`;

    fetch(url, {
      headers: { "x-access-token": sessionStorage.getItem("x-access-token") },
    })
      .then((res) => res.json())
      .then((result) => {
        let itemsData = result.data || [];
        // let type = result.type || "";
        let bagData = result.bag_data || [];
        let unique_bag_id = {};
        let _overallRouteSummDetails = {};
        const _bagItemsById = {};
        let _bagDataByRoute = {};
        let _highValueDataByRoute = {};
        _highValueDataByRoute["All"] = [];
        _bagDataByRoute["All"] = [];
        _overallRouteSummDetails["All"] = [];

        let _routeDropdownValues = [];
        let highValueItems = itemsData.filter(item => item.variety == "HIGH VALUE")
      
      const _highValueByRoute = highValueItems.reduce((acc, routeItems) => {
      const route = routeItems.route || "N/A";

       routeItems.rec_remarks = routeItems.received_remarks;
      routeItems.app_remarks = routeItems.approved_remarks;

    if (Number(routeItems.item_modified_qty) === 0) {
        return acc; // Skip this item
    }

    if (
        routeItems.received_qty != null &&
        routeItems.item_modified_qty &&
        routeItems.received_qty != routeItems.item_modified_qty
    ) {
        routeItems.showApprovedQtyField = true;
    } else {
        routeItems.showApprovedQtyField = false;
    }

    if (!acc[route]) {
        acc[route] = [];
    }
    // if (!acc["All"]) {
    //     acc["All"] = [];
    // }

    acc[route].push(routeItems);
    // acc["All"].push(routeItems);

    return acc;
}, {});

      // _highValueByRoute['ALL'] = highValueItems  // for all case in dd filtER
        for (let routeItems of itemsData) {
          let route = routeItems.route || "N/A";
          routeItems.rec_remarks = routeItems.received_remarks;
          routeItems.app_remarks = routeItems.approved_remarks;

          if (Number(routeItems.item_modified_qty) === 0) {
            continue;
          }

          if (
            routeItems.received_qty != null &&
            routeItems.item_modified_qty &&
            routeItems.received_qty != routeItems.item_modified_qty
          ) {
            routeItems.showApprovedQtyField = true;
          } else {
            routeItems.showApprovedQtyField = false;
          }

          if (!_overallRouteSummDetails[route]) {
            _overallRouteSummDetails[route] = [];
          }
         

          _overallRouteSummDetails["All"].push(routeItems);
          _overallRouteSummDetails[route].push(routeItems);
        
        }

        for (let item of bagData) {
          let route = item.route || "N/A";
          item.rec_remarks = item.received_remarks;
          item.app_remarks = item.approved_remarks;

          item.receivedToggleVal =
            item.received_qty === null
              ? "none"
              : Number(item.received_qty) === 0
              ? "N"
              : Number(item.received_qty) > 0
              ? "Y"
              : "none";
          item.approvedToggleVal =
            item.approved_qty === null
              ? "none"
              : Number(item.approved_qty) === 0
              ? "N"
              : Number(item.approved_qty) > 0
              ? "Y"
              : "none";

          const bag_id = item.bag_id;
          if (!_bagDataByRoute[route]) {
            _bagDataByRoute[route] = [];
          }

          if (bag_id) {
            if (!_bagItemsById[bag_id]) {
              _bagItemsById[bag_id] = [];
            }
            _bagItemsById[bag_id].push(item);
          }
          if (!(bag_id in unique_bag_id)) {
            _bagDataByRoute[route].push(item);
            _bagDataByRoute["All"].push(item);
            unique_bag_id[bag_id] = bag_id;
          }
        }


        Object.keys(_overallRouteSummDetails).forEach((key, index) => {
          _overallRouteSummDetails[key] = sortByTypeCategoryItemId(
            _overallRouteSummDetails[key]
          );
        });


        _routeDropdownValues =
          Object.keys({ ..._overallRouteSummDetails, ..._bagDataByRoute, }) ||
          [];
          
        setIsLoading(false);

        let nonHighValueData = Object.fromEntries(
          Object.entries(_overallRouteSummDetails).map(([key, items]) => [
            key, 
            items.filter(item => item.variety !== "HIGH VALUE")
          ])
        );
        

        setOverallRouteSummaryDetails(nonHighValueData);
        setOverallRouteSummaryDetailsCopy(
          JSON.parse(JSON.stringify(nonHighValueData))
        );
        setLoosePackingTableData(_bagDataByRoute);
        setLoosePackingTableDataCopy(
          JSON.parse(JSON.stringify(_bagDataByRoute))
        );
        setBagItemsById(_bagItemsById);
        setRouteDropDownValues(
          _routeDropdownValues.sort((a, b) => {
            if (b === "All") {
              return 1;
            } else {
              return -1;
            }
          })
        );

        setHighValueTableData(_highValueByRoute);
        setHighValueTableDataCopy(
          JSON.parse(JSON.stringify(_highValueByRoute))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in fetching items: ${err}`, { type: toast.TYPE.ERROR });
      });
  };

  useEffect(() => {
    console.log('effect run for transit items')
    init();
  }, []);

  const autoFillTableData = () => {
    setIsLoading(true);
    const _overallRouteSummaryDetails = { ...overallRouteSummaryDetails };
    _overallRouteSummaryDetails[currentSelectedRoute].forEach((item) => {
      if (item.item_modified_qty) {
        item.received_qty = item.received_qty ?? item.item_modified_qty;
      }
    });
  

    setIsLoading(false);
    setOverallRouteSummaryDetails(_overallRouteSummaryDetails);
    // setLoosePackingTableData(_overallLooseSummaryDetails);
    toast(`Auto fill successful !`, { type: toast.TYPE.SUCCESS });
  };

  //might need to add high value changes here too,check later
  useEffect(() => {
    if (
      currentSelectedRoute &&
      overallRouteSummaryDetailsCopy &&
      overallRouteSummaryDetailsCopy[currentSelectedRoute]
    ) {
      setApprovedFieldsStatus(
        currentSelectedRoute,
        overallRouteSummaryDetailsCopy
      );
    }

    if (currentSelectedRoute && loosePackingTableDataCopy) {
      setLooseApprovedFieldsStatus(
        currentSelectedRoute,
        loosePackingTableDataCopy
      );
    }

    if(currentSelectedRoute && highValueTableDataCopy && highValueTableDataCopy[currentSelectedRoute]){
      setHighApprovedFieldsStatus(
        currentSelectedRoute,
        highValueTableDataCopy
      )
    }
  }, [
    currentSelectedRoute,
    overallRouteSummaryDetailsCopy,
    loosePackingTableDataCopy,
    highValueTableDataCopy
  ]);


  const addItemToSummaryDetails = (item_id, route) => {
    if (!item_id || !route) {
      return console.log(" Error:  Missing arguements ");
    }

    if (itemAlreadyInList(item_id)) {
      toast(`This item is already present in the list`, {
        type: toast.TYPE.WARNING,
      });
      return;
    }
    const item = productMasterDataRaw[item_id];

    if (!item) {
      return console.log("Error : Unable to retrieve product info");
    }
    const category = item.category || "";
    const brand = item.brand || "";
    const variety = item.variety || "";
    const sub_category = item.sub_category;
    const item_disc = item.description || item.item_description1 || "";
    const approved_qty = 1;

    const itemToAdd = {
      transit_id: transitID,
      item_id,
      category,
      variety,
      brand,
      sub_category,
      item_disc,
      route,
      warehouse: userCity || "",
      approved_qty,
      bgColor: greenHighlightColor,
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };
    console.log("ready to add data", itemToAdd);

    toast(`Successfully added new item`, {
      type: toast.TYPE.SUCCESS,
    });
    const existingRouteSummaryDetails = { ...overallRouteSummaryDetails };
    existingRouteSummaryDetails[route].push(itemToAdd);
    setOverallRouteSummaryDetails(existingRouteSummaryDetails);
    setSelectedItemId({});
  };

  const removeItemFromSummaryDetails = (item_id, route, transit_id) => {
    return new Promise((resolve, reject) => {
      let url = `${service_url}/api/transit-route-item?token=${token}`;
      const data = { item_id, route, transit_id };
      console.log("check data", data);

      fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": sessionStorage.getItem("x-access-token"),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          toast(`Successfully removed item`, {
            type: toast.TYPE.SUCCESS,
          });

          resolve();
        })
        .catch((err) => {
          toast(`Unable to remove item`, {
            type: toast.TYPE.ERROR,
          });
          reject(err);
        });
    });
  };

  const updateReceivedDate = () => {
    let url = `${service_url}/api/received-date?token=${token}`;

    let data = {
      approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      id: transitID,
      isStockTransfer: "Y",
      route_id: currentSelectedRoute,
      warehouse: warehouse || "",
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);

        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          refetchData();
          props.onHide();
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const updateItemsQty = () => {
    let itemsList = [];
    const looseItemsList = [];

    if (overallRouteSummaryDetails[currentSelectedRoute]) {
      for (let routeItem of overallRouteSummaryDetails[currentSelectedRoute]) {
        let dataToPush = {};
        let item_id = routeItem.item_id;
        let received_qty = routeItem.received_qty;
        const received_remarks = routeItem.rec_remarks;
        const approved_remarks = routeItem.app_remarks;
        const shipped_qty = routeItem.item_modified_qty;

        if (!received_qty && received_qty !== 0) {
          return toast(`Please fill the quantity for all rows`, {
            type: toast.TYPE.WARNING,
          });
        } else if (received_qty < 0) {
          return toast(`Received quantity cannot be negative`, {
            type: toast.TYPE.ERROR,
          });
        }

        dataToPush = {
          item_id,
          received_qty,
          received_remarks,
          shipped_qty,
        };
        if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
          if (
            routeItem.showApprovedQtyField &&
            !routeItem.approved_qty &&
            routeItem.approved_qty !== 0
          ) {
            return toast(`No changes to submit`, {
              type: toast.TYPE.WARNING,
            });
            return toast(`Please fill the quantity for rows with discrepancy`, {
              type: toast.TYPE.WARNING,
            });
          } else if (
            routeItem.showApprovedQtyField &&
            routeItem.approved_qty < 0
          ) {
            return toast(`Approved quantity cannot be negative`, {
              type: toast.TYPE.ERROR,
            });
          } else if (routeItem.approved_qty || routeItem.approved_qty === 0) {
            dataToPush["approved_qty"] = routeItem.approved_qty;
            dataToPush["approved_remarks"] = approved_remarks;
            itemsList.push(dataToPush);
          }
        } else {
          itemsList.push(dataToPush);
        }
      }
    }

    if(highValueTableData[currentSelectedRoute]){
      for (let routeItem of highValueTableData[currentSelectedRoute]) {
        let dataToPush = {};
        let item_id = routeItem.item_id;
        let received_qty = routeItem.received_qty;
        let received_crates = routeItem.received_qty;
        const received_remarks = routeItem.rec_remarks;
        const approved_remarks = routeItem.app_remarks;
        const shipped_qty = routeItem.item_modified_qty;

        if (!received_qty && received_qty !== 0) {
          return toast(`Please fill the quantity for all rows`, {
            type: toast.TYPE.WARNING,
          });
        } else if (received_qty < 0) {
          return toast(`Received quantity cannot be negative`, {
            type: toast.TYPE.ERROR,
          });
        }

        // recieved qty, received crates add both

        dataToPush = {
          item_id,
          received_qty,
          received_remarks,
          shipped_qty,
          received_crates
        };
        if (isApprovedFieldsShownHigh && isApprovedQtyFieldsEditableHigh) {
          if (
            routeItem.showApprovedQtyField &&
            !routeItem.approved_qty &&
            routeItem.approved_qty !== 0
          ) {
            return toast(`No changes to submit`, {
              type: toast.TYPE.WARNING,
            });
            return toast(`Please fill the quantity for rows with discrepancy`, {
              type: toast.TYPE.WARNING,
            });
          } else if (
            routeItem.showApprovedQtyField &&
            routeItem.approved_qty < 0
          ) {
            return toast(`Approved quantity cannot be negative`, {
              type: toast.TYPE.ERROR,
            });
          } else if (routeItem.approved_qty || routeItem.approved_qty === 0 || routeItem.approved_qty == null ) {
            dataToPush["approved_qty"] = routeItem.approved_qty;
            dataToPush["approved_remarks"] = approved_remarks;
            itemsList.push(dataToPush);
          }
        } else {
          itemsList.push(dataToPush);
        }
      }
    }

    //Loop over loose packing table data
    if (loosePackingTableData[currentSelectedRoute]) {
      for (let loosePackItems of loosePackingTableData[currentSelectedRoute]) {
        let dataToPush = {};
        const order_number = loosePackItems.order_number;
        const bag_id = loosePackItems.bag_id;
        const received = loosePackItems.receivedToggleVal;
        const approved = loosePackItems.approvedToggleVal;
        const received_remarks = loosePackItems.rec_remarks;
        const approved_remarks = loosePackItems.app_remarks;
        dataToPush = { order_number, bag_id };

        if (received === "none" || !received) {
          return toast(
            `Please select the received status for all loose pack items`,
            {
              type: toast.TYPE.WARNING,
            }
          );
        }

        console.log("check approved", approved);
        if (
          isApprovedFieldsShownLoose &&
          isApprovedFieldsEditableLoose &&
          (approved === "none" || !approved) &&
          received == "N"
        ) {
          return toast(
            `Please select the approved status for all loose pack items`,
            {
              type: toast.TYPE.WARNING,
            }
          );
        } else {
          if (!isApprovedFieldsShownLoose) {
            dataToPush.received = received;
            dataToPush["received_remarks"] = received_remarks;
          } else if (isApprovedFieldsShownLoose) {
            dataToPush.approved = approved;
            dataToPush["approved_remarks"] = approved_remarks;
          }

          looseItemsList.push(dataToPush);
        }
      }
    }

    let url = `${service_url}/api/approved-item-qty?token=${token}`;

    let data = {
      approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      received_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      received_by: JSON.parse(sessionStorage.getItem(`user`))[0].email,
      updatedItems: itemsList,  
      bag_data: looseItemsList,
      transit_id: transitID,
      route_id: currentSelectedRoute,
      warehouse: warehouse || "",
      delivery_date:
        props.dateType === "transit"
          ? moment(props.transit_date).format("YYYY-MM-DD")
          : "",
      transit_date:
        props.dateType === "delivery"
          ? moment(props.delivery_date).format("YYYY-MM-DD")
          : "",
    };
    if (isApprovedQtyFieldsShown && isApprovedQtyFieldsEditable) {
      data["approved_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["approved_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    } else {
      data["received_by"] = JSON.parse(sessionStorage.getItem(`user`))[0].email;
      data["received_at"] = new Date().toJSON().slice(0, 19).replace("T", " ");
    }

    console.log(data);
    setIsLoading(true);

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        setIsLoading(false);

        if (result.success == 1) {
          toast(`${result.message}`, { type: toast.TYPE.SUCCESS });
          refetchData();
          props.onHide();
        } else {
          toast(`${result.message}`, { type: toast.TYPE.ERROR });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        toast(`Error in creating transit route records: ${err}`, {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const excelHeaders = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Shipped Qty",
        value: "item_modified_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
    ],
    []
  );
  const excelHeadersLooseItems = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Order No",
        value: "order_number",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },
      {
        label: "Description",
        value: "item_disc",
      },

      {
        label: "Bag Id",
        value: "bag_id",
      },
      {
        label: "Quantity",
        value: "item_modified_qty",
      },
      {
        label: "Received",
        value: "received",
      },
      {
        label: "Approved",
        value: "approved",
      },
    ],
    []
  );
  const excelHeadersHighValue = React.useMemo(
    () => [
      {
        label: "Item ID",
        value: "item_id",
      },
      {
        label: "Route",
        value: "route",
      },
      {
        label: "Type",
        value: "variety",
      },
      {
        label: "Category",
        value: "category",
      },
      {
        label: "Brand",
        value: "brand",
      },
      {
        label: "Sub Category",
        value: "sub_category",
      },

      {
        label: "Description",
        value: "item_disc",
      },
      {
        label: "Shipped Qty",
        value: "item_modified_qty",
      },
      {
        label: "Received Qty",
        value: "received_qty",
      },
      {
        label: "Approved Qty",
        value: "approved_qty",
      },
      {
        label: "Crate Id",
        value: "crate_id",
      },
    ],
    []
  );

  return (
    <div>
      <Modal
        {...props}
        id="transitStockModal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Transit Items List
          </Modal.Title>

          <label style={{ marginLeft: "40px", margin: "7px" }}>Route:</label>
          <select
            style={{ marginLeft: "5px", marginTop: "7px" }}
            value={currentSelectedRoute}
            disabled = {!(userType == 'Admin')}
            onChange={(event) => {
              setCurrentSelectedRoute(event.currentTarget.value);
            }}
          >
            {routeDropDownValues.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>

          {!isApprovedQtyFieldsShown && currentSelectedRoute !== "All" ? (
            <MantineButton
              style={{ marginLeft: "1rem", alignSelf: "flex-end" }}
              compact
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
              color="yellow"
              onClick={() => {
                autoFillTableData();
              }}
            >
              Auto fill received qty
            </MantineButton>
          ) : null}
        </Modal.Header>

        <Modal.Body>
          {(overallRouteSummaryDetails[currentSelectedRoute] &&
            overallRouteSummaryDetails[currentSelectedRoute].length > 0) ||
          (loosePackingTableData[currentSelectedRoute] &&
            loosePackingTableData[currentSelectedRoute].length > 0) ||
            (highValueTableData[currentSelectedRoute] &&
              highValueTableData[currentSelectedRoute].length > 0) ? (
            <>
              <div>
                <div
                  className="clearFix"
                  style={{
                    float: "right",
                    marginBottom: "20px",
                    marginLeft: "30px",
                  }}
                >
                  <ExportToExcel
                    sheet1={overallRouteSummaryDetails[currentSelectedRoute]}
                    sheet2={loosePackingTableData[currentSelectedRoute]}
                    sheet3={highValueTableData[currentSelectedRoute]}
                    bagItemsById={bagItemsById}
                    filename={"transit_stock_transfer_summary"}
                    sheet1Headers={excelHeaders}
                    sheet2Headers={excelHeadersLooseItems}
                    sheet3Headers={excelHeadersHighValue}
                    sheet1Name={warehouse}
                    sheet2Name="Loose Items"
                    sheet3Name="High Value"
                  />
                </div>
                <div
                  className="clearFix"
                  style={{ float: "right", marginBottom: "20px" }}
                >
                  <ShowDiscrepancyCheckbox
                    showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                    setShowDiscrepancyItemsChecked={
                      setShowDiscrepancyItemsChecked
                    }
                  />
                </div>

                <RenderTransitRouteTable
                  overallRouteSummaryDetails={overallRouteSummaryDetails}
                  routeDropDownValues={routeDropDownValues}
                  currentSelectedRoute={currentSelectedRoute}
                  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                  setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
                  getApprovedFieldsStatus={getApprovedFieldsStatus}
                  getApprovedFieldsStatusHigh={getApprovedFieldsStatusHigh}
                  showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                  init={init}
                  isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
                  isApprovedFieldsShownHigh = {isApprovedFieldsShownHigh}
                  isApprovedFieldsShownLoose={isApprovedFieldsShownLoose}
                  isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
                  isApprovedQtyFieldsEditableHigh = {isApprovedQtyFieldsEditableHigh}
                  isApprovedFieldsEditableLoose={isApprovedFieldsEditableLoose}
                  loosePackingTableData={loosePackingTableData}
                  loosePackingTableDataCopy={loosePackingTableDataCopy}
                  setLoosePackingTableData={setLoosePackingTableData}
                  bagItemsById={bagItemsById}

                  highValueTableItemsData = {highValueTableData}
                  highValueTableItemsDataCopy = {highValueTableDataCopy}
                  setHighValueTableData = {setHighValueTableData} // check later use case ,simialr like loose pasck , overall summary
                />
              </div>
            </>
          ) : (
            ""
          )}

          {!isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) &&
          !isApprovedQtyFieldsShown &&
          currentSelectedRoute !== "All" ? (
            <>
              <Row style={{ width: "100%" }}>
                <Col style={{ width: "150%" }}>
                  <Select
                    options={addTransitItemDropdownList}
                    value={selectedItemId}
                    onChange={(val) => {
                      console.log("selected value", val);
                      setSelectedItemId(val);
                    }}
                    isClearable={true}
                  />
                </Col>

                <Col style={{ width: "10%" }}>
                  <Button
                    onClick={() => {
                      if (selectedItemId && selectedItemId.value) {
                        addItemToSummaryDetails(
                          selectedItemId.value,
                          currentSelectedRoute
                        );
                      } else {
                        toast(`Please select a valid item id from the list`, {
                          type: toast.TYPE.WARNING,
                        });
                      }
                    }}
                  >
                    Add item
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Modal.Body>

        <Modal.Footer>
          {
          !isUpdateBtnDisabled(
            userType,
            userCity,
            isApprovedQtyFieldsShown,
            isApprovedQtyFieldsEditable
          ) &&
          currentSelectedRoute !== "All" &&
          formatDate(new Date()) == formatDate(getTransitReceivingDate) ? 
          (
            <Button
              disabled={isLoading}
              onClick={() => {
                updateItemsQty();
              }}
            >
              Update
            </Button>
          ) : null}

          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? <Backdrop parentLoadStatus={isLoading} /> : null}
    </div>
  );
}

const submitNewItemToAPI = (data) => {
  return new Promise((resolve, reject) => {
    if (!data) {
      console.log("Did not receive required arguements");
      reject();
      return;
    }

    let url = `${service_url}/api/transit-route-item?token=${token}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("x-access-token"),
      },
      body: JSON.stringify(data),
    }).then((res) => {
      console.log("check response", res.body);
      resolve();
    });
  });
};

//can add high value is approved qty high, editable high fields over here, check its functionality later
const isUpdateBtnDisabled = (
  user_type,
  user_city,
  is_approved_qty_fields_shown,
  is_approved_qty_fields_editable
) => {
  if (user_type === "Admin") {
    return false;
  } else if (is_approved_qty_fields_shown && !is_approved_qty_fields_editable) {
    return true;
  } else if (!is_approved_qty_fields_shown) {
    //Received Qty Field
    if (user_city === GLOBAL_PARENT_WAREHOUSE) {
      return true;
    } else if (user_type === "Operation" && user_city === "") {
      return true;
    } else {
      return false;
    }
  } else if (is_approved_qty_fields_shown && is_approved_qty_fields_editable) {
    if (
      user_city === GLOBAL_PARENT_WAREHOUSE ||
      (user_type === "Operation" && user_city === "")
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};



const RenderTransitRouteTable = (props) => {
  const {
    overallRouteSummaryDetails,
    currentSelectedRoute,
    removeItemFromSummaryDetails,
    setOverallRouteSummaryDetails,
    init,
    routeDropDownValues,
    isApprovedQtyFieldsShown,
    isApprovedFieldsShownHigh,
    isApprovedFieldsShownLoose,
    isApprovedQtyFieldsEditable,
    isApprovedQtyFieldsEditableHigh,
    isApprovedFieldsEditableLoose,
    getApprovedFieldsStatus,
    getApprovedFieldsStatusHigh,
    loosePackingTableData,
    setLoosePackingTableData,
    loosePackingTableDataCopy,
    highValueTableItemsData,
    setHighValueTableData,
    showDiscrepancyItemsChecked,
  } = props;

  const bagItemsById = props.bagItemsById;

  if (currentSelectedRoute !== "All") {
    let routeDataToShow =
      currentSelectedRoute && overallRouteSummaryDetails[currentSelectedRoute]
        ? overallRouteSummaryDetails[currentSelectedRoute]
        : [];

    const bagDataToShow = loosePackingTableData[currentSelectedRoute] || [];
    const bagDataInit = loosePackingTableDataCopy[currentSelectedRoute] || [];
    // high value
    const highValueDataToShow = highValueTableItemsData[currentSelectedRoute] || [];
       
    

    return (
      <div key={currentSelectedRoute} style={{ pageBreakBefore: "always" }}>
        {routeDataToShow.length > 0 && (
          <>
            <center>
              <h4>Route - {currentSelectedRoute}</h4>
            </center>

            <TransitItemsTable
              routeProductData={routeDataToShow}
              routeDropDownValues={routeDropDownValues}
              currentSelectedRoute={currentSelectedRoute}
              currentRoute={currentSelectedRoute}
              overallRouteSummaryDetails={overallRouteSummaryDetails}
              removeItemFromSummaryDetails={removeItemFromSummaryDetails}
              setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
              init={init}
              isApprovedQtyFieldsShown={isApprovedQtyFieldsShown}
              isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditable}
              showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
            />
          </>
        )}

        {bagDataToShow.length > 0 && (
          <>
            {currentSelectedRoute !== "FRESH" ? (
              <>
                {" "}
                <center>
                  <h4>Route - {currentSelectedRoute} - Loose Items</h4>
                </center>
                <TransitLooseItemsTable
                  bagDataToShow={bagDataToShow}
                  loosePackingTableData={loosePackingTableData}
                  isApprovedFieldsShown={isApprovedFieldsShownLoose}
                  currentSelectedRoute={currentSelectedRoute}
                  isApprovedFieldsEditable={isApprovedFieldsEditableLoose}
                  bagDataInit={bagDataInit}
                  setLoosePackingTableData={setLoosePackingTableData}
                  bagItemsById={bagItemsById}
                  showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                />
              </>
            ) : null}
          </>
        )}

        {highValueDataToShow.length > 0 && (
           <>
           <center>
             <h4>Route - {currentSelectedRoute} - HIGH VALUE</h4>
           </center>

           <TransitHighValueTable
             routeProductData={highValueDataToShow}
             routeDropDownValues={routeDropDownValues}
             currentSelectedRoute={currentSelectedRoute}
             currentRoute={currentSelectedRoute}
             overallRouteSummaryDetailsHigh={highValueTableItemsData}
            //  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
             setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
             setHighValueTableData={setHighValueTableData}
             init={init}
             isApprovedQtyFieldsShown={isApprovedFieldsShownHigh}
             isApprovedQtyFieldsEditable={isApprovedQtyFieldsEditableHigh}
             showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
           />
         </>
        )}
      </div>
    );
  } else {
    return routeDropDownValues.map((item, index) => {
      if (item === "All") {
        return null;
      }

      let routeDataToShow = overallRouteSummaryDetails[item]
        ? overallRouteSummaryDetails[item]
        : [];
      const bagDataToShow = loosePackingTableData[item] || [];
      const bagDataInit = loosePackingTableDataCopy[item] || [];

      // high value
    const highValueDataToShow = highValueTableItemsData[item] || [];

      const _routeDataForStatus =
        routeDataToShow.length > 0
          ? overallRouteSummaryDetails
          : loosePackingTableData;

          const _routeDataForStatusHigh =
          routeDataToShow.length > 0
            ? overallRouteSummaryDetails
            : highValueTableItemsData;

      const [isAppQtyFieldsShown, isAppQtyFieldsEditable] =
        getApprovedFieldsStatus(item, _routeDataForStatus);

        const [isAppQtyFieldsShownHigh, isAppQtyFieldsEditableHigh] =
        getApprovedFieldsStatusHigh(item, _routeDataForStatusHigh);

      return (
        <div key={item} style={{ pageBreakBefore: "always" }}>
          {routeDataToShow.length > 0 && (
            <>
              <center>
                <h4>Route - {item}</h4>
              </center>
              <TransitItemsTable
                routeProductData={routeDataToShow}
                routeDropDownValues={routeDropDownValues}
                currentRoute={item}
                currentSelectedRoute={currentSelectedRoute}
                overallRouteSummaryDetails={overallRouteSummaryDetails}
                removeItemFromSummaryDetails={removeItemFromSummaryDetails}
                setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
                init={init}
                isApprovedQtyFieldsShown={isAppQtyFieldsShown}
                isApprovedQtyFieldsEditable={isAppQtyFieldsEditable}
                showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
              />
            </>
          )}

          {bagDataToShow.length > 0 && (
            <>
              {item !== "FRESH" ? (
                <>
                  {" "}
                  <center>
                    <h4>Route - {item} - Loose Items</h4>
                  </center>
                  <TransitLooseItemsTable
                    bagDataToShow={bagDataToShow}
                    loosePackingTableData={loosePackingTableData}
                    isApprovedFieldsShown={isApprovedFieldsShownLoose}
                    currentSelectedRoute={currentSelectedRoute}
                    isApprovedFieldsEditable={isApprovedFieldsEditableLoose}
                    bagDataInit={bagDataInit}
                    setLoosePackingTableData={setLoosePackingTableData}
                    bagItemsById={bagItemsById}
                    showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
                  />{" "}
                </>
              ) : null}
            </>
          )}

          {highValueDataToShow.length > 0 && (
           <>
           <center>
             <h4>Route - {item} - HIGH VALUE</h4>
           </center>

           <TransitHighValueTable
             routeProductData={highValueDataToShow}
             routeDropDownValues={routeDropDownValues}
             currentRoute={item}
             currentSelectedRoute={currentSelectedRoute}
             overallRouteSummaryDetailsHigh={highValueTableItemsData}
            //  removeItemFromSummaryDetails={removeItemFromSummaryDetails}
             setOverallRouteSummaryDetails={setOverallRouteSummaryDetails}
             setHighValueTableData={setHighValueTableData}
             init={init}
             isApprovedQtyFieldsShown={isAppQtyFieldsShownHigh}
             isApprovedQtyFieldsEditable={isAppQtyFieldsEditableHigh}
             showDiscrepancyItemsChecked={showDiscrepancyItemsChecked}
           />
         </>
        )}


        </div>
      );
    });
  }
};

export default TransitRouteItemsModal;
