
import { useState, useEffect } from 'react';

export const useWarehouseList = () => {

  const [warehouseObj, setWarehouseObj] = useState([]);
  const [warehouseList,setWarehouseList] = useState([])
  const [warehouseListWithAll,setWarehouseListWithAll] = useState([])

  const [warehouseList_keyValue, setWarehouseList_keyValue] = useState([]);
  const [warehouseListWithAll_keyValue, setWarehouseListWithAll_keyValue] = useState([]);

  const [depoWarehouseList, setDepoWarehouseList] = useState([]);
  const [depoWarehouseListWithAll, setDepoWarehouseListWithAll] = useState([]);

  const [dcWarehouseList_keyValue, setDCWarehouseList_keyValue] = useState([]);
  const [dcWarehouseListWithAll_keyValue, setDCWarehouseListWithAll_keyValue] = useState([]);

  const [dcWarehouseList, setDCWarehouseList] = useState([]);
  const [dcWarehouseListWithAll, setDCWarehouseListWithAll] = useState([]);

  const [depoWarehouseList_keyValue, setDepoWarehouseList_keyValue] = useState([]);
  const [depoWarehouseListWithAll_keyValue, setDepoWarehouseListWithAll_keyValue] = useState([]);
 

  const fetchWarehousesLogic = async () => {
    try {
      const data = await fetchWarehouses();
      console.log("Fetched Warehouses:", data);

      const allWarehousesList = [
        "All",
         ...data.map(obj => (obj.warehouse))
       ];

       setWarehouseObj(data);
       setWarehouseList([ ...data.map(obj => (obj.warehouse)) ])
       setWarehouseListWithAll(allWarehousesList)


       const dcWarehouses = data.filter(obj => obj.type === "dc");
      
       const allDcWarehouses = [
           { value: "All", label: "All" },
           ...dcWarehouses.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
         ];
         
         setDCWarehouseList_keyValue([...dcWarehouses.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))])
         setDCWarehouseListWithAll_keyValue(allDcWarehouses)
        

         const allDcWarehousesList = [
          "All",
         ...dcWarehouses.map(obj => (obj.warehouse))
        ];
        
        setDCWarehouseList([ ...dcWarehouses.map(obj => (obj.warehouse))])
        setDCWarehouseListWithAll(allDcWarehousesList)


      const depoWarehouses = data.filter(obj => obj.type === "depot");
      
      const allDepoWarehouses = [
          { value: "All", label: "All" },
          ...depoWarehouses.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
        ];

      setDepoWarehouseList_keyValue([...depoWarehouses.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))])
      setDepoWarehouseListWithAll_keyValue(allDepoWarehouses)
      
      

      const allDepoList = [
        "All",
         ...depoWarehouses.map(obj => (obj.warehouse))
       ];


      setDepoWarehouseList( [...depoWarehouses.map(obj => (obj.warehouse))])
      setDepoWarehouseListWithAll(allDepoList)


      const allWarehouses = [
        { value: "All", label: "All" },
        ...data.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
      ];

      setWarehouseListWithAll_keyValue(allWarehouses);
      setWarehouseList_keyValue([...data.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))])

      console.log("All warehouses:", allWarehouses);
    } catch (error) {
      console.error("Failed to fetch warehouses:", error);
    }
  };
  

  useEffect(() => {
    fetchWarehousesLogic();
  }, []);

  return [warehouseListWithAll, warehouseList, warehouseListWithAll_keyValue, warehouseList_keyValue, depoWarehouseListWithAll_keyValue, depoWarehouseList_keyValue, depoWarehouseListWithAll, depoWarehouseList,dcWarehouseListWithAll,dcWarehouseList, warehouseObj];
};



export const fetchWarehouses = async () => {
  try {
    const dbName = "WarehouseDB";
    const storeName = "warehouses";
    const db = await new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);
      request.onerror = () => reject(new Error(`Failed to open database: ${dbName}`));
      request.onsuccess = (event) => resolve(event.target.result);
    });
    if (!db.objectStoreNames.contains(storeName)) {
      throw new Error(`Store "${storeName}" not found in database`);
    }
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const result = await new Promise((resolve, reject) => {
      const request = store.getAll();
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
    return result;
  } catch (err) {
    console.error("Error in fetchWarehouses:", err);
    throw err;
  }
};
// Directly exporting the promise to fetch warehouses
export const warehouses = fetchWarehouses();