import React, { useState, useRef, useEffect } from "react";
import {
  Button as MantineButton,
  Text,
  Space,
  Select,
  Image,
} from "@mantine/core";
import DatePicker from "react-datepicker";
import NavBar from "../Navigation bar/Navbar";
import FooterComponent from "../Footer/footer";
import { warehouses } from '../../indexedDBUtility.js';

import {
  Dropdown,
  DropdownButton,
  Spinner,
  Table,
  InputGroup,
  Navbar,
  FormControl,
  Form,
  Button,
  Row,
  Col,
  Toast,
} from "react-bootstrap";

import { InputGroupAddon } from "reactstrap";
import fire from "../../Configs/firebase";
import Loader from "react-loader";
import "react-datepicker/dist/react-datepicker.css";
import Backdrop from "../backdrop/backdrop";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// CSS Modules, react-datepicker-cssmodules.css
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import TimePicker from "react-time-picker";
import Creatable from "react-select/creatable";
import VehicleDetailsDrawer from "./vehicle_details_drawer";
import DriverSummaryModal from "./driver_summary_modal";
import correct from "../../assests/images/correct.png";
import question from "../../assests/images/question.png";
import pencil from "../../assests/images/pencil.png";
import list from "../../assests/images/list.png";

const DriverDetails = () => {
  const [getDate, setDate] = useState(new Date());

  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const [detailsDrawerRouteId, setDetailsDrawerRouteId] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [detailsDrawerData, setDetailsDrawerData] = useState({});

  // !Newly created states
  const [driverDropdownList, setDriverDropdownList] = useState([]);
  const [driverSummaryModalOrders, setDriverSummaryModalOrders] = useState([]);
  const [driverSummaryModalAllocation, setDriverSummaryModalAllocation] =
    useState([]);

  const [driverListRaw, setDriverListRaw] = useState({});
  const [ordersHistoryRaw, setOrdersHistoryRaw] = useState([]);
  const [allocationHistoryRaw, setAllocationHistoryRaw] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [driverCompanyList, setDriverCompanyList] = useState([]);
  const [selectedDriverCompany, setSelectedDriverCompany] = useState("All");
  const [cityListRaw, setCityListRaw] = useState({});
  const [regionListRaw, setRegionListRaw] = useState({});
  const [selectedCity, setSelectedCity] = useState();
  const [showLoading, setShowLoading] = useState(false);
  const [preAllocatedRouteDrivers, setPreAllocatedRouteDrivers] = useState([]);
  const [editMoreDataModalPhNo, setEditMoreDataModalPhNo] = useState("");
  const [unAllocRoutesCount, setUnAllocRoutesCount] = useState(0);

  const loggedInUserType = sessionStorage.getItem("userType");
  const loggedInUserCity = sessionStorage.getItem("userCity") || "";

  useEffect(() => {
    warehouses
      .then(data => {
        console.log("Fetched Warehouses:", data);
        const allWarehouses = [
          { value: "All", label: "All" },
          ...data.map(obj => ({ value: obj.warehouse, label: obj.warehouse }))
        ];
        setCityList(allWarehouses);
        console.log("All warehouses:", allWarehouses);
      })
      .catch(error => {
        console.error("Failed to fetch warehouses:", error);
      });
  }, []);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const _ignoredRoutes = ["8888", "9998", "9999"];

  const vehicleLoadersList = [
    "Vikram",
    "Prasad",
    "Bhagirath",
    "Dalip",
    "Abhijeet",
    "Raju",
    "Vishal",
    "Vipul",
    "Nilesh",
    "Manoj",
  ];

  vehicleLoadersList.sort();
  let vehicleLoadersDropdownList = [];

  for (let i = 0; i < vehicleLoadersList.length; i++) {
    let loaderName = vehicleLoadersList[i];
    vehicleLoadersDropdownList.push({ value: loaderName, label: loaderName });
  }
  // console.log("DEBUG", vehicleLoadersDropdownList);
  const vehicleLoadedByList = vehicleLoadersDropdownList;

  const looseAllocatedByList = vehicleLoadedByList;

  toast.configure({
    autoClose: 4000,
    draggable: true,
    pauseOnHover: true,
    position: toast.POSITION.TOP_RIGHT,
  });

  useEffect(() => {
    // generateRouteDropdownAndData();
    fetchCityList().then(() => {
      fetchRouteAndDriverData();
    });
  }, []);

  useEffect(() => {
    if (getDate) {
      // console.log("changed date ..");
      // generateRouteDropdownAndData();
      fetchRouteAndDriverData();
    } else {
      return toast("Please select date", { type: toast.TYPE.ERROR });
    }
  }, [selectedCity, selectedDriverCompany, getDate]);

  const fetchCityList = () => {
    return new Promise((resolve, reject) => {
      fire
        .database()
        .ref("Region_List")
        .once("value")
        .then((snapshot) => {
          let cities = [];
          if (loggedInUserType === "Admin" || loggedInUserCity === "") {
            cities.push({ label: "All", value: "All" });
          }
          let citiesRaw = {};
          const data = snapshot.val();
          Object.keys(data).forEach((key, index) => {
            if (
              loggedInUserCity === "" ||
              data[key].city === loggedInUserCity ||
              loggedInUserType === "Admin"
            ) {
              if (data[key].city in citiesRaw) {
                citiesRaw[data[key].city].push(data[key].id);
              } else {
                cities.push({
                  label: `${data[key].city}`,
                  value: `${data[key].city}`,
                });
                citiesRaw[data[key].city] = [data[key].id];
              }
            }
          });
          // setCityList(cities);
          setCityListRaw(citiesRaw);
          setRegionListRaw(data);
          // console.log("setting selected city to : ", cities[0].value);
          setSelectedCity(cities[0].value);
          resolve();
        })
        .catch((err) => {
          console.log(
            "An error occured while attempting to fetch the city list",
            err
          );
        });
    });
  };

  const fetchRouteAndDriverData = () => {
    if (!selectedCity) {
      console.log("no city selected");
      return;
    }

    setShowLoading(true);
    let allocationHistory = fire
      .database()
      .ref(
        `Driver_Allocation_History/${getDate.getFullYear()}/${
          getDate.getMonth() + 1
        }/${getDate.getDate()}-${
          getDate.getMonth() + 1
        }-${getDate.getFullYear()}`
      );

    let ordersHistory = fire
      .database()
      .ref(
        `Driver_Orders_History/${getDate.getFullYear()}/${
          getDate.getMonth() + 1
        }/${getDate.getDate()}-${
          getDate.getMonth() + 1
        }-${getDate.getFullYear()}`
      );

    Promise.all([
      ordersHistory.once("value"),
      allocationHistory.once("value"),
      fire.database().ref("Driver_Details").once("value"),
    ])
      .then(
        ([
          orders_history_snapshot,
          allocation_history_snapshot,
          driver_details_snapshot,
        ]) => {
          // console.log("orders_history_snapshot", orders_history_snapshot);
          // console.log("driver_details_snapshot", driver_details_snapshot.val());

          const allocation_history = allocation_history_snapshot.val() || {};
          const driver_details = driver_details_snapshot.val();
          const orders_history = orders_history_snapshot.val();
          // console.log("orders_history_snapshot", orders_history);

          const routes = [];

          if (selectedCity === "All") {
            Object.keys(orders_history).forEach((key, index) => {
              if (!_ignoredRoutes.includes(key)) {
                routes.push(key);
              }
            });
          } else {
            Object.keys(orders_history).forEach((key, index) => {
              if (!_ignoredRoutes.includes(key)) {
                let regionsForSelectedCity = cityListRaw[selectedCity];

                if (regionsForSelectedCity.includes(key.slice(0, 2))) {
                  routes.push(key);
                }
              }
            });
          }

          const allocated_routes = {};

          Object.keys(allocation_history).forEach((key, index) => {
            if (routes.includes(allocation_history[key].current_Route_Id)) {
              allocated_routes[allocation_history[key].current_Route_Id] = key;
            }
          });

          let routesFilteredByCompanyType = [];
          let unallocatedRoutesCount = 0;

          if (selectedDriverCompany !== "All") {
            routes.forEach((item, index) => {
              if (
                driver_details[allocated_routes[item]] &&
                driver_details[allocated_routes[item]].company_type ===
                  selectedDriverCompany
              ) {
                routesFilteredByCompanyType.push(item);
              } else if (!(item in allocated_routes)) {
                routesFilteredByCompanyType.push(item);
                unallocatedRoutesCount++;
              }
            });
          } else {
            unallocatedRoutesCount =
              routes.length - Object.keys(allocated_routes).length;
            routesFilteredByCompanyType = routes;
          }

          // console.log("allocated_routes", allocated_routes);

          const { drivers, driver_companies } = getDriversListAndCompaniesList(
            driver_details,
            allocation_history,
            selectedDriverCompany,
            selectedCity
          );

          drivers.sort((element1, element2) => {
            if (element1.disabled) {
              return 1;
            } else {
              return -1;
            }
          });

          setDriverDropdownList(drivers);
          setDriverListRaw(driver_details);
          setAllocationHistoryRaw(allocation_history);
          // console.log("allocation_history", allocation_history);
          setPreAllocatedRouteDrivers(allocated_routes);
          // console.log("DEBUG routes", routes);
          setRouteList(routesFilteredByCompanyType);
          setDriverCompanyList(driver_companies);
          setOrdersHistoryRaw(orders_history);
          setUnAllocRoutesCount(unallocatedRoutesCount);
          setShowLoading(false);
        }
      )
      .catch((error) => {
        console.log("error occured while attempting to fetch data", error);
        setDriverDropdownList([]);
        setDriverListRaw({});
        setPreAllocatedRouteDrivers([]);
        setRouteList([]);
        setOrdersHistoryRaw({});
        setAllocationHistoryRaw({});
        setShowLoading(false);
        setDriverCompanyList([{ value: "All", label: "All" }]);
        setSelectedDriverCompany("All");
      });
  };

  const getDriversListAndCompaniesList = (
    driver_details,
    allocation_history,
    selDriverCompany,
    selCity
  ) => {
    const drivers = [];
    const driver_companies_unique = [];
    const driver_companies = [{ value: "All", label: "All" }];

    for (const key in driver_details) {
      if (
        !driver_details[key].type ||
        !driver_details[key].driver_name ||
        !driver_details[key].mobile_number ||
        driver_details[key].type !== "driver"
      ) {
        continue;
      }

      if (
        selCity === "All" &&
        driver_details[key].company_type &&
        !driver_companies_unique.includes(driver_details[key].company_type)
      ) {
        driver_companies_unique.push(driver_details[key].company_type);
        driver_companies.push({
          value: driver_details[key].company_type,
          label: driver_details[key].company_type,
        });
      } else if (
        driver_details[key].city === selCity &&
        driver_details[key].company_type &&
        !driver_companies_unique.includes(driver_details[key].company_type)
      ) {
        driver_companies_unique.push(driver_details[key].company_type);
        driver_companies.push({
          value: driver_details[key].company_type,
          label: driver_details[key].company_type,
        });
      }

      if (
        (selCity !== "All" && driver_details[key].city !== selCity) ||
        (selDriverCompany !== "All" &&
          driver_details[key].company_type !== selDriverCompany)
      ) {
        if (allocation_history[driver_details[key].mobile_number]) {
          drivers.push({
            value: driver_details[key].mobile_number,
            label:
              driver_details[key].driver_name +
              "-" +
              driver_details[key].mobile_number,
            disabled: true,
          });
        }
        continue;
      }

      if (!allocation_history[key]) {
        drivers.push({
          value: driver_details[key].mobile_number,
          label:
            driver_details[key].driver_name +
            "-" +
            driver_details[key].mobile_number,
        });
      } else {
        drivers.push({
          value: driver_details[key].mobile_number,
          label:
            driver_details[key].driver_name +
            "-" +
            driver_details[key].mobile_number,
          disabled: true,
        });
      }
    }
    return { drivers, driver_companies };
  };

  const editMoreDataClicked = (routeId, driver_phno) => {
    if (!routeId) {
      console.log("error did not receive routeId");
    }

    // console.log("routeId clicked", driver_phno);
    setDetailsDrawerRouteId(routeId);
    setEditMoreDataModalPhNo(driver_phno);
    setDetailsDrawerData(allocationHistoryRaw[driver_phno]);
    setShowDetailsDrawer(true);
  };

  const editMoreDataSubmit = (dataToSave) => {
    fire
      .database()
      .ref(
        `Driver_Allocation_History/${getDate.getFullYear()}/${
          getDate.getMonth() + 1
        }/${getDate.getDate()}-${
          getDate.getMonth() + 1
        }-${getDate.getFullYear()}/${editMoreDataModalPhNo}`
      )
      .update(dataToSave)
      .then(() => {
        setShowDetailsDrawer(false);

        toast("Successfully saved the data", { type: toast.TYPE.SUCCESS });
        fetchRouteAndDriverData();
      })
      .catch((err) => {
        toast("An error occured while saving the data: ", {
          type: toast.TYPE.ERROR,
        });
      });
  };

  const summaryClicked = (route_no, driver_phone) => {
    if (!route_no) {
      console.log("Did not receive route_no");
    }
    setShowSummaryModal(true);
    setDetailsDrawerRouteId(route_no);
    setDriverSummaryModalOrders(ordersHistoryRaw[route_no]);
    setDriverSummaryModalAllocation(allocationHistoryRaw[driver_phone]);
  };

  const driverOnChange = (selectedDriver, route_no) => {
    //Change this to allocation_Time
    setShowLoading(true);
    if (!selectedDriver) {
      return console.log("Did not receive any selected driver");
    }

    const userLoginEmail = JSON.parse(sessionStorage.getItem("user"))[0].email;
    const currDate = new Date();
    const allocationTime = Date.parse(currDate).toString();

    let dataToSave = {
      actual_Driver_Name: driverListRaw[selectedDriver].driver_name,
      company_type: driverListRaw[selectedDriver].company_type || "",
      current_Route_Id: route_no,
      date: `${currDate.getDate()}-${
        currDate.getMonth() + 1
      }-${currDate.getFullYear()}`,
      driver_Phone_Number: selectedDriver,
      allocated_By: userLoginEmail,
      allocation_Time: allocationTime,
      city: driverListRaw[selectedDriver].city || null,
      vehicle_number: driverListRaw[selectedDriver].vehicle_number || null,
    };

    // console.log("dataToSave in firebase", dataToSave);

    //! Save the new selected driver to DB
    fire
      .database()
      .ref(
        `Driver_Allocation_History/${getDate.getFullYear()}/${
          getDate.getMonth() + 1
        }/${getDate.getDate()}-${
          getDate.getMonth() + 1
        }-${getDate.getFullYear()}/${selectedDriver}`
      )
      .set(dataToSave)
      .then(() => {
        toast("Successfully saved allocated driver", {
          type: toast.TYPE.SUCCESS,
        });
        fetchRouteAndDriverData();
      });
    setShowLoading(false);
  };

  const deleteExistingDriverAllocation = (driver_phno) => {
    // console.log("driver_phno", driver_phno);
    return new Promise((resolve, reject) => {
      fire
        .database()
        .ref(
          `Driver_Allocation_History/${getDate.getFullYear()}/${
            getDate.getMonth() + 1
          }/${getDate.getDate()}-${
            getDate.getMonth() + 1
          }-${getDate.getFullYear()}/${driver_phno}`
        )
        .remove()
        .then(() => {
          resolve();
        })
        .catch((err) => {
          console.log("error occured while removing data");
          reject();
        });
    });
  };

  return (
    <div style={{ textAlign: "center", paddingBottom: "50px" }}>
      {showDetailsDrawer ? (
        <VehicleDetailsDrawer
          routeId={detailsDrawerRouteId}
          opened={showDetailsDrawer}
          companyList={driverCompanyList}
          setOpened={setShowDetailsDrawer}
          vehicleLoadedByOptions={vehicleLoadersList}
          looseAllocatedByOptions={looseAllocatedByList}
          onSubmit={editMoreDataSubmit}
          existingData={detailsDrawerData}
        />
      ) : (
        ""
      )}

      {showSummaryModal ? (
        <DriverSummaryModal
          opened={showSummaryModal}
          setOpened={setShowSummaryModal}
          orders={driverSummaryModalOrders}
          allocation={driverSummaryModalAllocation}
          routeNo={detailsDrawerRouteId}
        />
      ) : (
        ""
      )}

      <NavBar />
      <br />
      <br />
      <br />
      <br />
      <Navbar
        className="bg-light mr-auto justify-content-between"
        style={{ paddingTop: 10 }}
      >
        <Form inline>
          <InputGroup size="sm" style={{ marginLeft: 10, marginRight: 100 }}>
            <InputGroupAddon addonType="prepend">Delivery Date</InputGroupAddon>
            <DatePicker
              selected={getDate}
              onChange={(val) => {
                setDate(val);
              }}
              minDate={loggedInUserType === "Admin" ? "" : today}
              maxDate={loggedInUserType === "Admin" ? "" : tomorrow}
              aria-describedby="basic-addon1"
              dateFormat="dd/MM/yyyy"
            />
          </InputGroup>
          <Select
            label="Select WareHouse"
            data={cityList}
            value={selectedCity}
            onChange={(value) => {
              setSelectedCity(value);
              setSelectedDriverCompany("All");
            }}
          />
          <Select
            style={{ marginLeft: "20px" }}
            label="Select Company"
            data={driverCompanyList}
            defaultValue={"All"}
            value={selectedDriverCompany}
            onChange={setSelectedDriverCompany}
          />
          <div style={{ marginLeft: "20px", paddingTop: "10px" }}>
            <b>
              Unallocated routes :{" "}
              {`${unAllocRoutesCount} / ${routeList.length}`}
            </b>
          </div>
        </Form>
      </Navbar>
      <br />
      <br />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Region</th>
            <th>Drivers</th>
            <th>Allocation Details</th>
            <th>Loading details</th>
            <th>Delivery summary</th>
          </tr>
        </thead>
        <tbody>
          {routeList.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ paddingBottom: "5px" }}>
                  <Space h="md" />
                  <Text size="md">
                    <span style={{ fontWeight: "bold" }}>{item}</span>
                    <span style={{ fontStyle: "italic" }}>
                      {" "}
                      {regionListRaw[item.slice(0, 2)]
                        ? regionListRaw[item.slice(0, 2)].region
                        : ""}
                    </span>
                  </Text>
                </td>

                <td style={{ display: "flex", justifyContent: "center" }}>
                  {preAllocatedRouteDrivers[item] ? (
                    <Image
                      style={{ marginTop: "10px", flexGrow: 1 }}
                      width={30}
                      height={30}
                      src={correct}
                      alt="Allocated symbol"
                    />
                  ) : (
                    <Image
                      style={{ marginTop: "10px", flexGrow: 1 }}
                      width={30}
                      height={30}
                      src={question}
                      alt="Unallocated symbol"
                    />
                  )}
                  <Select
                    style={{ paddingTop: "10px", flexGrow: 7 }}
                    placeholder="Select driver"
                    data={driverDropdownList}
                    value={preAllocatedRouteDrivers[item] || ""}
                    searchable
                    nothingFound="No options"
                    onChange={(selectedDriver) => {
                      if (loggedInUserType !== "Admin") {
                        if (
                          getDate.getDate() === today.getDate() &&
                          today.getHours() >= 12
                        ) {
                          toast("Cannot modify driver after 12pm", {
                            type: toast.TYPE.INFO,
                          });
                          return;
                        }
                        if (preAllocatedRouteDrivers[item]) {
                          toast("Cannot modify driver once allocated", {
                            type: toast.TYPE.INFO,
                          });
                          return;
                        } else {
                          driverOnChange(selectedDriver, item);
                        }
                      } else {
                        if (preAllocatedRouteDrivers[item]) {
                          // console.log("should delete record");
                          deleteExistingDriverAllocation(
                            preAllocatedRouteDrivers[item]
                          )
                            .then(() => {
                              driverOnChange(selectedDriver, item);
                            })
                            .catch((err) => {
                              toast("Could not modify driver allocation", {
                                type: toast.TYPE.ERROR,
                              });
                            });
                        } else {
                          driverOnChange(selectedDriver, item);
                        }
                      }
                    }}
                  />
                </td>
                <td>
                  {preAllocatedRouteDrivers[item] ? (
                    <>
                      {" "}
                      <Text size="sm">
                        {allocationHistoryRaw[preAllocatedRouteDrivers[item]]
                          ?.vehicle_number || ""}
                      </Text>
                      <Text size="sm">
                        {allocationHistoryRaw[preAllocatedRouteDrivers[item]]
                          ?.company_type || ""}
                      </Text>
                    </>
                  ) : (
                    ""
                  )}
                </td>

                <td>
                  <Space h="md" />

                  <MantineButton
                    style={{ paddingLeft: "10px" }}
                    onClick={(e) => {
                      editMoreDataClicked(item, preAllocatedRouteDrivers[item]);
                    }}
                    disabled={!preAllocatedRouteDrivers[item]}
                  >
                    <Image
                      style={{ marginRight: "5px" }}
                      width={20}
                      height={20}
                      src={pencil}
                    />
                    Edit details
                  </MantineButton>
                </td>
                <td>
                  <Space h="md" />
                  <MantineButton
                    style={{ paddingLeft: "10px" }}
                    onClick={() => {
                      summaryClicked(item, preAllocatedRouteDrivers[item]);
                    }}
                    disabled={!preAllocatedRouteDrivers[item]}
                  >
                    <Image
                      style={{ marginRight: "5px" }}
                      width={20}
                      height={20}
                      src={list}
                    />
                    Summary
                  </MantineButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {showLoading ? <Backdrop parentLoadStatus={showLoading} /> : null}

      <FooterComponent />
    </div>
  );
};

export default DriverDetails;
